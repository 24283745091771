<template>
    <div class="rows" style="gap: 20px;">
        <div class="cols" style="gap: 5px;">
            <button class="button-small" @click="close()">Zavřít</button>

            <button v-if="!isAdmin && support.stav == 2" class="button-small" @click="sendToApproveAdmin()" style="margin-left: 20px;">Předat ke schválení</button>
            <button v-if="isAdmin && support.stav == 3" class="button-small" @click="approve()" style="margin-left: 20px;">Schválit</button>

            <button v-if="!isAdmin && support.stav == 2" class="button-small" @click="returnToTeacher()">Vrátit učiteli</button>
            <button v-if="isAdmin && support.stav == 3" class="button-small" @click="returnToCoordinator()">Vrátit koordinátorovi</button>
        </div>

        <div class="cols" style="gap: 5px;">
            <button :class="'button-small' + (!this.showDefault ? ' button-inverse' : '')" @click="changeTabPage(0)">Základní údaje</button>
            <button :class="'button-small' + (!this.showCourses ? ' button-inverse' : '')" @click="changeTabPage(1)">Kurzy</button>
            <button :class="'button-small' + (!this.showAttachements ? ' button-inverse' : '')" @click="changeTabPage(2)">Přílohy</button>
            <button v-if="isAdmin" :class="'button-small' + (!this.showEvidence ? ' button-inverse' : '')" @click="changeTabPage(3)">Evidence (výkaz)</button>
        </div>

        <div v-if="showDefault">
            <table class="table-border-none">
                <tr>
                    <td style="padding-left: 0;">Název:</td>
                    <td style="padding-right: 5px;">{{ this.support.nazev }}</td>
                </tr>
            </table>
        </div>

        <div v-if="showCourses">
            <table>
                <tr>
                    <th>Pracovník</th>
                    <th>Nepedagog</th>
                    <th>Dat. nar.</th>
                    <th>Název kurzu</th>
                    <th>Navazující<br />kurz</th>
                    <th>Realizace<br />od - do</th>
                    <th>Počet hodin</th>
                    <th>On-line</th>
                    <th>Forma</th>
                    <th>Téma</th>
                    <th>Společné<br />přílohy</th>
                    <th>Počet<br />příloh</th>
                    <th>Přílohy</th>
                    <th>Dat. kontroly</th>
                </tr>
                <tr v-for="(k, i) in this.support.data.kurzy" :key="i" :class="k.nepedagog ? 'background-silver' : ''">
                    <td style="vertical-align: top;">{{ !k.nepedagog ? k.prijmeni + ' ' + k.jmeno : k.nepedagogJmeno }}</td>
                    <td style="vertical-align: top;">{{ k.nepedagog ? 'ano' : 'ne' }}</td>
                    <td style="vertical-align: top;">{{ this.formatDate(k.datumNarozeni) }}</td>
                    <td style="vertical-align: top;">{{ k.nazev }}</td>
                    <td style="vertical-align: top;">{{ k.navazujiciKurz ? 'ano' : 'ne' }}</td>
                    <td style="vertical-align: top;">{{ this.formatDate(k.realizaceOd) + ' - ' + this.formatDate(k.realizaceDo) }}</td>
                    <td style="vertical-align: top;">{{ k.pocetHodin }}</td>
                    <td style="vertical-align: top;">{{ k.online ? 'ano' : 'ne' }}</td>
                    <td style="vertical-align: top;">{{ k.forma }}</td>
                    <td style="vertical-align: top;">{{ k.tema }}</td>
                    <td style="vertical-align: top;">{{ k.spolecnePrilohy ? 'ano' : 'ne' }}</td>
                    <td style="vertical-align: top;">{{ this.attachements.filter(f => f.nazev.startsWith(this.getFilePrefix(i))).length }}</td>
                    <td style="vertical-align: top;">{{ getAttachementsText(i) }}</td>
                    <td style="vertical-align: top;">{{ this.formatDate(k.datumKontroly) }}</td>
                </tr>
            </table>
        </div>

        <div v-if="showAttachements">
            <FilesComponent :path="support.idSkola + '/sablony/1/' + selectedSupport" :prefix="getFilePrefix" />
        </div>

        <div v-if="showEvidence">
            <table>
                <tr>
                    <th>Příjmení</th>
                    <th>Jméno</th>
                    <th>Datum narození</th>
                    <th>Šablona</th>
                    <th>Název kurzu</th>
                    <th>Počet hodin podpory</th>
                    <th>Forma</th>
                    <th>Téma</th>
                </tr>
                <tr v-for="(k, i) in this.support.data.kurzy" :key="i">
                    <td style="vertical-align: top;">{{ !k.nepedagog ? k.prijmeni : k.nepedagogJmeno.split(' ')[0] }}</td>
                    <td style="vertical-align: top;">{{ !k.nepedagog ? k.jmeno : k.nepedagogJmeno.split(' ')[1] }}</td>
                    <td style="vertical-align: top;">{{ this.formatDate(k.datumNarozeni) }}</td>
                    <td style="vertical-align: top;">vzdělávání_ZUŠ_1_VII_1&nbsp;</td>
                    <td style="vertical-align: top;">{{ k.nazev }}</td>
                    <td style="vertical-align: top;">{{ k.pocetHodin }}</td>
                    <td style="vertical-align: top;">{{ k.forma }}</td>
                    <td style="vertical-align: top;">{{ k.tema }}</td>
                </tr>
            </table>
        </div>
    </div>
                
    <ConfirmBoxComponent v-if="showConfirmBox" :message="confirmMessage" :dialogType="confirmType" @close-event="closeConfirmBox" />
    <ConfirmTextBoxComponent v-if="showConfirmTextBox" :message="confirmMessage" :dialogType="confirmType" :inputText="confirmText" @close-event="closeConfirmTextBox" />
</template>

<script>
    import api from '@/api/api.js'
    import dayjs from 'dayjs'
    import utc from 'dayjs/plugin/utc'
    dayjs.extend(utc)
    
    import ConfirmBoxComponent from '../dialogs/ConfirmBoxComponent.vue'
    import ConfirmTextBoxComponent from '../dialogs/ConfirmTextBoxComponent.vue'
    import FilesComponent from '../files/FilesComponent.vue'

    export default {
        name: 'SupportReadonlyComponent',
        emits: ['close-card'],
        props: ['selectedSupport', 'isAdmin'],
        components: {
            ConfirmBoxComponent,
            ConfirmTextBoxComponent,
            FilesComponent
        },
        data() {
            return {
                showDefault: true,
                showCourses: false,
                showAttachements: false,
                showEvidence: false,
                pracovnici: [],
                support: {
                    id: -1,
                    nazev: '',
                    idSkola: -1,
                    idUcitel: -1,
                    upravil: '',
                    stav: 0,
                    data: {
                        nazev: '',
                        kurzy: []
                    }
                },
                showConfirmBox: false,
                showConfirmTextBox: false,
                confirmMessage: "",
                confirmText: "",
                confirmType: -1,
                attachements: []
            }
        },
        computed: {
            getFilePrefix() {
                return (id) => {
                    let nazev = ''
                    let jmeno = ''
                    let forma = ''

                    if (id != -1) {
                        nazev = this.support.data.kurzy[id].nazev.replace(/ /g, '_')
                        nazev = nazev.length > 15 ? nazev.slice(0, 12) + "…" : nazev

                        if (!this.support.data.kurzy[id].spolecnePrilohy) {
                            if (!this.support.data.kurzy[id].nepedagog) {
                                if (this.support.data.kurzy[id].idUcitel != -1) {
                                    let p = this.pracovnici.filter(a => a.id == this.support.data.kurzy[id].idUcitel).slice()[0]
                                    jmeno = '_' + p.prijmeni + '_' + p.jmeno
                                }
                            }
                            else {
                                jmeno = '_' + this.support.data.kurzy[id].nepedagogJmeno.replace(/ /g, '_')
                            }
                        }
                        else {
                            jmeno = '_společná_příloha'
                        }

                        switch (this.support.data.kurzy[id].forma) {
                            case 'akreditovaný kurz průběžné DVPP':
                            case 'akreditovaný kurz jiný':
                                forma = '_akredit'
                                break;
                            case 'neakreditovaný kurz':
                                forma = '_neakredit'
                                break;
                            case 'kvalifikační_studium_DVPP':
                                forma = '_studium'
                                break;
                            case 'stáž':
                                forma = '_stáž'
                                break;
                            case 'mentoring':
                                forma = '_mentoring'
                                break;
                            case 'supevize':
                                forma = '_supervize'
                                break;
                            case 'koučink':
                                forma = '_koučink'
                                break;
                        }
                    }

                    return '1_' + (id != -1 ? (nazev + forma + jmeno + '_') : '')
                }
            }
        },
        methods: {
            async load() {
                this.support.id = this.selectedSupport

                try {
                    let response = await api.getSupport(this.selectedSupport)
                    if (response.status == 200) {
                        this.support = response.data
                        
                        await this.loadAttachements()
                    }

                    response = await api.getTeacher(this.support.idSkola)
                    if (response.status == 200)
                        this.pracovnici = response.data
                }
                catch (error) {
                    console.log("Error: ", error)
                }
            },
            async loadAttachements() {
                try {
                    let response = await api.getFiles(this.support.idSkola + '/sablony/1/' + this.support.id)
                    this.attachements = response.data
                }
                catch (error) {
                    console.log("Error: ", error)
                }
            },
            formatDate(date) {
                return date !== null ? dayjs(date).utcOffset(0).format('D.M.YYYY') : ''
            },
            formatText(text, trail) {
                return text + trail
            },
            getPracovnik(id) {
                if (this.pracovnici.length === 0)
                    return ''
                let p = this.pracovnici.filter(a => a.id == id).slice()[0]
                return p != null ? p.jmeno + ' ' + p.prijmeni : ''
            },
            close() {
                this.$emit('close-card')
            },
            async sendToApproveAdmin() {
                this.confirm(1, 'Šablona bude odeslána ke schválení administrátorovi. Pokračovat?')
            },
            async approve() {
                this.confirm(2, 'Šablona bude schválena a uzamčena. Pokračovat?')
            },
            async returnToTeacher() {
                this.callConfirmText(3, 'Šablona bude vrácena učiteli k doplnění. Pokračovat?', this.support.duvodVraceni)
            },
            async returnToCoordinator() {
                this.callConfirmText(4, 'Šablona bude vrácena koordinátorovi k doplnění. Pokračovat?', this.support.duvodVraceni)
            },
            getAttachementsText(index) {
                let text = 'Seznam příloh: Osvědčení/potvrzení o účasti'

                if (this.support.data.kurzy[index].online)
                    text += ', Printscreen'
                
                switch (this.support.data.kurzy[index].forma) {
                    case 'neakreditovaný kurz':
                        text += ', Faktura'
                        break
                    case 'stáž':
                    case 'mentoring':
                    case 'supevize':
                    case 'koučink':
                        text = 'Přílohy konzultujte s administrátorem'
                        break
                }

                return text
            },
            changeTabPage(page) {
                this.showDefault = false
                this.showCourses = false
                this.showAttachements = false
                this.showEvidence = false

                switch (page) {
                    case 1:
                        this.showCourses = true
                        break
                    case 2:
                        this.showAttachements = true
                        break
                    case 3:
                        this.showEvidence = true
                        break
                    default:
                        this.showDefault = true
                        break
                }
            },
            confirm(type, message) {                
                this.confirmType = type
                this.confirmMessage = message
                this.showConfirmBox = true
            },
            callConfirmText(type, message, text) {                
                this.confirmType = type
                this.confirmMessage = message
                this.confirmText = text
                this.showConfirmTextBox = true
            },
            async closeConfirmBox(dialogResult, dialogType) {
                this.showConfirmBox = false
                this.confirmMessage = ''
                this.confirmType = -1

                if (dialogResult == 1) {
                    switch (dialogType) {
                        case 1:
                            await api.sendSupportToApproveByAdmin(this.support.id)
                            this.close()
                            break
                            
                        case 2:
                            await api.approveSupport(this.support.id)
                            this.close()
                            break
                    }
                }
            },
            async closeConfirmTextBox(dialogResult, dialogType, text) {
                this.showConfirmTextBox = false
                this.confirmType = -1
                this.confirmMessage = ''
                this.confirmText = ''

                if (dialogResult == 1) {
                    switch (dialogType) {
                        case 3:
                            await api.returnSupportToTeacher(this.support.id, text)
                            this.close()
                            break

                        case 4:
                            await api.returnSupportToCoordinator(this.support.id, text)
                            this.close()
                            break
                    }
                }
            }
        },
        async created() {
            await this.load()
        }
    }
</script>