<template>
    <div>
    </div>
</template>

<script>
    export default {
        name: 'BudgetAdminComponent',
        props: [],
        data() {
            return {
            }
        },
        methods: {
        }
    }
</script>