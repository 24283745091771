<template>
    <div class="rows" style="gap: 20px;">
        <div class="cols" style="gap: 5px;">
            <button class="button-small" @click="close()">Zavřít</button>

            <button v-if="!isAdmin && cooperation.stav == 2" class="button-small" @click="sendToApproveAdmin()" style="margin-left: 20px;">Předat ke schválení</button>
            <button v-if="isAdmin && cooperation.stav == 3" class="button-small" @click="approve()" style="margin-left: 20px;">Schválit</button>

            <button v-if="!isAdmin && cooperation.stav == 2" class="button-small" @click="returnToTeacher()">Vrátit učiteli</button>
            <button v-if="isAdmin && cooperation.stav == 3" class="button-small" @click="returnToCoordinator()">Vrátit koordinátorovi</button>
        </div>

        <div class="cols" style="gap: 5px;">
            <button :class="'button-small' + (!this.showDefault ? ' button-inverse' : '')" @click="changeTabPage(0)">Základní údaje</button>
            <button :class="'button-small' + (!this.showActivity ? ' button-inverse' : '')" @click="changeTabPage(1)">Aktivity</button>
            <button :class="'button-small' + (!this.showAttachements ? ' button-inverse' : '')" @click="changeTabPage(2)">Přílohy</button>
            <button v-if="isAdmin" :class="'button-small' + (!this.showEvidence ? ' button-inverse' : '')" @click="changeTabPage(3)">Evidence (výkaz)</button>
        </div>

        <div v-if="showDefault">
            <table class="table-border-none">
                <tr>
                    <td style="padding-left: 0;">Název:</td>
                    <td style="padding-right: 5px;">{{ this.cooperation.nazev }}</td>
                </tr>
            </table>
        </div>

        <div v-if="showActivity">
            <input type="file" class="hidden-input" multiple name="fileInputInline" id="fileInputInline" @change="onChangeFileInputInline" ref="fileInputInline" accept=".pdf" />

            <table>
                <tr>
                    <th>&nbsp;</th>
                    <th>Lektor</th>
                    <th>Pracovníci</th>
                    <th>Příprava</th>
                    <th>Realizace</th>
                    <th>Jiná škola</th>
                    <th>Reflexe</th>
                    <th>Souhrn</th>
                    <th>&nbsp;</th>
                </tr>
                <tr v-for="(a, i) in this.cooperation.data.aktivity" :key="i">
                    <td style="vertical-align: top;"><span>{{ i + 1 }}</span></td>
                    <td style="vertical-align: top;">{{ !a.lektor.externi ? a.lektor.prijmeni + ' ' + a.lektor.jmeno : a.lektor.jmenoExterni }}</td>
                    <td style="vertical-align: top;">
                        <div v-for="(p, j) in a.pracovnici" :key="j" style="padding-bottom: 10px;">
                            <span v-if="p.typ == 0">{{ p.prijmeni + ' ' + p.jmeno }} (P)</span>
                            <span v-if="p.typ == 1">{{ p.jmenoExterni }} (S)</span>
                        </div>
                    </td>
                    <td style="vertical-align: top;">{{ this.formatDateTime(a.priprava.datum, a.priprava.casOd, a.priprava.casDo) }}</td>
                    <td style="vertical-align: top;">
                        <div v-for="(r, k) in a.realizace" :key="k" style="padding-bottom: 10px;">
                            <span v-if="r.datum != null">
                                {{ this.formatDateTime(r.datum, r.casOd, r.casDo) }}<br />
                                Forma: {{ r.forma }}<br />
                                Téma: {{ r.tema }}
                            </span>
                        </div>
                    </td>
                    <td style="vertical-align: top; width: 200px;">
                        <span v-if="a.realizace.filter(r => r.forma == 'návštěva v jiné škole/školském zařízení').slice().length > 0">
                            IČ: {{ a.jinaSkolaICO }}
                            <br />
                            Název: {{ a.jinaSkolaNazev }}
                        </span>
                    </td>
                    <td style="vertical-align: top;">{{ this.formatDateTime(a.reflexe.datum, a.reflexe.casOd, a.reflexe.casDo) }}</td>
                    <td style="vertical-align: top;">
                        Příprava:&nbsp;{{ getHours(a, 0) }}&nbsp;h<br />
                        Realizace:&nbsp;{{ getHours(a, 1) }}&nbsp;h<br />
                        Reflexe:&nbsp;{{ getHours(a, 2) }}&nbsp;h<br /><br />
                        <span :class="getTotalHours(a) == 8 ? 'color-green' : 'color-red'">Celkem:&nbsp;{{ getTotalHours(a) }}&nbsp;h</span>
                    </td>
                    <td style="vertical-align: top; padding-top: 10px; padding-bottom: 10px;">
                        <button class="button-small button-inverse" @click="generateDocument(i)" style="width: 170px;">Vygenerovat dokument</button>
                        <br />
                        <button class="button-small button-inverse" @click="uploadDocument(i)" style="width: 170px;">Uložit dokument</button>
                        <br /><br />

                        <div v-for="(at, m) in this.attachements.filter(f => f.nazev.startsWith('2.' + this.cooperation.nazev.slice(0, 1) + '_spoluprace ' + (i+1)))" :key="m">
                            <span class="file-span" v-html="getFile(at.cesta, at.nazev)"></span>
                        </div>
                    </td>
                </tr>
            </table>
        </div>

        <div v-if="showAttachements">
            <FilesComponent :path="this.cooperation.idSkola + '/sablony/2/' + this.cooperation.id" :prefix="getFilePrefix" />
        </div>

        <div v-if="showEvidence">
            <table>
                <tr>
                    <th>Příjmení</th>
                    <th>Jméno</th>
                    <th>Datum narození</th>
                    <th>Šablona</th>
                    <th>Název kurzu</th>
                    <th>Počet hodin podpory</th>
                    <th>Forma</th>
                    <th>Téma</th>
                </tr>
                <template v-for="(a, i) in this.cooperation.data.aktivity" :key="i">
                    <template v-for="(p, j) in a.pracovnici" :key="j">
                        <tr v-if="p.typ == 0">
                            <td style="vertical-align: top;">{{ p.prijmeni }}</td>
                            <td style="vertical-align: top;">{{ p.jmeno }}</td>
                            <td style="vertical-align: top;">{{ formatDate(p.datumNarozeni) }}</td>
                            <td style="vertical-align: top;">spolupráce_ZUŠ_1_VII_2&nbsp;</td>
                            <td style="vertical-align: top;"></td>
                            <td style="vertical-align: top;">{{ getTotalHours(a) }}</td>
                            <td style="vertical-align: top;">{{ getForma(a.realizace[0].forma) }}</td>
                            <td style="vertical-align: top;">{{ a.realizace[0].tema }}</td>
                        </tr>
                    </template>
                </template>
            </table>
        </div>
    </div>
                
    <ConfirmBoxComponent v-if="showConfirmBox" :message="confirmMessage" :dialogType="confirmType" @close-event="closeConfirmBox" />
    <ConfirmTextBoxComponent v-if="showConfirmTextBox" :message="confirmMessage" :dialogType="confirmType" :inputText="confirmText" @close-event="closeConfirmTextBox" />
</template>

<script>
    import api from '@/api/api.js'
    import dayjs from 'dayjs'
    import utc from 'dayjs/plugin/utc'
    dayjs.extend(utc)
    
    import ConfirmBoxComponent from '../dialogs/ConfirmBoxComponent.vue'
    import ConfirmTextBoxComponent from '../dialogs/ConfirmTextBoxComponent.vue'
    import FilesComponent from '../files/FilesComponent.vue'

    export default {
        name: 'CooperationReadonlyComponent',
        emits: ['close-card', 'show-document-generation-message'],
        props: ['selectedCooperation', 'isAdmin'],
        components: {
            ConfirmBoxComponent,
            ConfirmTextBoxComponent,
            FilesComponent
        },
        data() {
            return {
                showDefault: true,
                showActivity: false,
                showAttachements: false,
                showEvidence: false,
                cooperation: {
                    id: -1,
                    nazev: '',
                    idSkola: -1,
                    idUcitel: -1,
                    upravil: '',
                    stav: 0,
                    data: {
                        nazev: '',
                        aktivity: []
                    }
                },
                showConfirmBox: false,
                showConfirmTextBox: false,
                confirmMessage: "",
                confirmText: "",
                confirmType: -1,
                fileName: '',
                files: [],
                attachements: []
            }
        },
        computed: {
            getFilePrefix() {
                let nazev = this.cooperation.nazev.replace(/ /g, '_')
                nazev = nazev.length > 20 ? nazev.slice(0, 17) + "…" : nazev
                
                return '2_' + nazev + '_'
            }
        },
        methods: {
            async load() {
                this.cooperation.id = this.selectedCooperation

                try {
                    let response = await api.getCooperation(this.selectedCooperation)
                    if (response.status == 200) {
                        this.cooperation = response.data
                        await this.loadAttachements()
                    }
                }
                catch (error) {
                    console.log("Error: ", error)
                }
            },
            async loadAttachements() {
                try {
                    let response = await api.getFiles(this.cooperation.idSkola + '/sablony/2/' + this.cooperation.id)
                    this.attachements = response.data
                }
                catch (error) {
                    console.log('Error: ', error)
                }
            },
            formatDate(date) {
                return date !== null ? dayjs(date).utcOffset(0).format('D.M.YYYY') : ''
            },
            formatDateTime(date, timeFrom, timeTo) {
                return (date !== null 
                    ? dayjs(date).utcOffset(0).format('D.M.YYYY') 
                        + ' ' 
                        + (timeFrom != null ? timeFrom.hours + ':' + timeFrom.minutes.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}) : '') 
                        + ' - ' 
                        + (timeTo != null ? timeTo.hours + ':' + timeTo.minutes.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}) : '') 
                    : '')
            },
            getHours(a, t) {
                let hours = 0.0

                switch (t) {
                    case 0: // priprava
                        if (a.priprava.casOd != null && a.priprava.casDo != null) {
                            let minutes = a.priprava.casDo.hours * 60 + a.priprava.casDo.minutes - a.priprava.casOd.hours * 60 - a.priprava.casOd.minutes
                            hours += minutes / 60
                        }
                        break;

                    case 1: // realizace
                        for (let i = 0; i < a.realizace.length; i++) {
                            if (a.realizace[i].casOd != null && a.realizace[i].casDo != null) {
                                let minutes = a.realizace[i].casDo.hours * 60 + a.realizace[i].casDo.minutes - a.realizace[i].casOd.hours * 60 - a.realizace[i].casOd.minutes
                                hours += minutes / 45
                            }
                        }
                        break;

                    case 2: // reflexe
                        if (a.reflexe.casOd != null && a.reflexe.casDo != null) {
                            let minutes = a.reflexe.casDo.hours * 60 + a.reflexe.casDo.minutes - a.reflexe.casOd.hours * 60 - a.reflexe.casOd.minutes
                            hours += minutes / 60
                        }
                        break;
                }
                
                return hours % 1 != 0 ? hours.toFixed(2) : hours
            },
            getTotalHours(a) {
                let hours = 0.0
                if (a.priprava.casOd != null && a.priprava.casDo != null) {
                    let minutes = a.priprava.casDo.hours * 60 + a.priprava.casDo.minutes - a.priprava.casOd.hours * 60 - a.priprava.casOd.minutes
                    hours += minutes / 60
                }
                for (let i = 0; i < a.realizace.length; i++) {
                    if (a.realizace[i].casOd != null && a.realizace[i].casDo != null) {
                        let minutes = a.realizace[i].casDo.hours * 60 + a.realizace[i].casDo.minutes - a.realizace[i].casOd.hours * 60 - a.realizace[i].casOd.minutes
                        hours += minutes / 45
                    }
                }
                if (a.reflexe.casOd != null && a.reflexe.casDo != null) {
                    let minutes = a.reflexe.casDo.hours * 60 + a.reflexe.casDo.minutes - a.reflexe.casOd.hours * 60 - a.reflexe.casOd.minutes
                    hours += minutes / 60
                }
                return hours % 1 != 0 ? hours.toFixed(2) : hours
            },
            getForma(f) {
                switch (f) {
                    case 'hospitace ve výuce':
                        return 'hospitace';
                    case 'minilekce ve výuce':
                        return 'minilekce';
                    case 'návštěva v jiné škole/školském zařízení':
                        return 'návštěva v jiném zařízení';
                    default:
                        return '';
                }
            },
            showDocumentgenerationMessage(value) {
                this.$emit('show-document-generation-message', value)
            },
            async generateDocument(rid) {
                this.showDocumentgenerationMessage(true)

                if (this.cooperation.id !== -1) {
                    try {
                        await api.generateCooperationDocument(this.cooperation.id, rid)
                        this.load()
                    }
                    catch (error) {
                        console.log("Error: ", error)
                    }
                }

                this.showDocumentgenerationMessage(false)
            },
            async uploadDocument(rid) {
                this.fileName = '2.' + this.cooperation.nazev.slice(0, 1) + '_spoluprace ' + (rid+1) + '_' + this.formatDate(this.cooperation.data.aktivity[rid].reflexe.datum) + '_podepsano.pdf'
                this.$refs.fileInputInline.click()
            },
            async onChangeFileInputInline() {
                this.files = []
                this.files.push(...this.$refs.fileInputInline.files)
                await this.uploadFiles()
            },
            async uploadFiles() {
                const files = this.files
                const formData = new FormData()
                files.forEach((file) => {
                    formData.append("selectedFiles", file);
                })

                try {
                    await api.uploadSingleFile(formData, this.idSchool + '/sablony/2/' + this.selectedCooperation, this.fileName)
                }
                catch (error) {
                    console.log("Error: ", error)
                }

                this.files = []
            },
            getFile(path, name) {
                return '<a class="file-name" target="_blank" href="' + process.env.VUE_APP_API_URL + '/soubory/download?soubor=' + path + '/' + encodeURIComponent(name) + '">' + name + '</a>'
            },
            close() {
                this.$emit('close-card')
            },
            async sendToApproveAdmin() {
                this.confirm(1, 'Šablona bude odeslána ke schválení administrátorovi. Pokračovat?')
            },
            async approve() {
                this.confirm(2, 'Šablona bude schválena a uzamčena. Pokračovat?')
            },
            async returnToTeacher() {
                this.callConfirmText(3, 'Šablona bude vrácena učiteli k doplnění. Pokračovat?', this.cooperation.duvodVraceni)
            },
            async returnToCoordinator() {
                this.callConfirmText(4, 'Šablona bude vrácena koordinátorovi k doplnění. Pokračovat?', this.cooperation.duvodVraceni)
            },
            changeTabPage(page) {
                this.showDefault = false
                this.showActivity = false
                this.showAttachements = false
                this.showEvidence = false

                switch (page) {
                    case 1:
                        this.loadAttachements()

                        this.showActivity = true
                        break
                    case 2:
                        this.showAttachements = true
                        break
                    case 3:
                        this.showEvidence = true
                        break
                    default:
                        this.showDefault = true
                        break
                }
            },
            confirm(type, message) {                
                this.confirmType = type
                this.confirmMessage = message
                this.showConfirmBox = true
            },
            callConfirmText(type, message, text) {                
                this.confirmType = type
                this.confirmMessage = message
                this.confirmText = text
                this.showConfirmTextBox = true
            },
            async closeConfirmBox(dialogResult, dialogType) {
                this.showConfirmBox = false
                this.confirmMessage = ''
                this.confirmType = -1

                if (dialogResult == 1) {
                    switch (dialogType) {
                        case 1:
                            await api.sendCooperationToApproveByAdmin(this.cooperation.id)
                            this.close()
                            break
                            
                        case 2:
                            await api.approveCooperation(this.cooperation.id)
                            this.close()
                            break
                    }
                }
            },
            async closeConfirmTextBox(dialogResult, dialogType, text) {
                this.showConfirmTextBox = false
                this.confirmType = -1
                this.confirmMessage = ''
                this.confirmText = ''

                if (dialogResult == 1) {
                    switch (dialogType) {
                        case 3:
                            await api.returnCooperationToTeacher(this.cooperation.id, text)
                            this.close()
                            break

                        case 4:
                            await api.returnCooperationToCoordinator(this.cooperation.id, text)
                            this.close()
                            break
                    }
                }
            }
        },
        async created() {
            await this.load()
        }
    }
</script>