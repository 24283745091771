<template>
    <div class="menu">
        <div class="login-title" style="padding: 10px;">
            <img src="@/assets/img/sorbina-img.png" height="30" />
            <span style="margin-left: 20px;">SORBINA</span>
        </div>

        <div class="menu-ul">
            <ul>
                <li v-if="isAdmin || isSchoolSelected" :class="selectedItem == 1 ? 'active' : ''" @click="setSelectedItem(1)">Přehled</li>
                <li v-if="isAdmin || isSchoolSelected" :class="selectedItem == 2 ? 'active' : ''" @click="setSelectedItem(2)">Šablony</li>
                <li v-if="isAdmin || (isCoordinator && isSchoolSelected)" :class="selectedItem == 12 ? 'active' : ''" @click="setSelectedItem(12)">Karty účastníků</li>
                <!-- <li v-if="isAdmin || (isCoordinator && isSchoolSelected)" :class="selectedItem == 3 ? 'active' : ''" @click="setSelectedItem(3)">Čerpání</li> -->
                <!-- <li v-if="isAdmin || (isCoordinator && isSchoolSelected)" :class="selectedItem == 4 ? 'active' : ''" @click="setSelectedItem(4)">Schvalování</li> -->
                <!-- <li v-if="isAdmin" :class="selectedItem == 5 ? 'active' : ''" @click="setSelectedItem(5)">Formuláře</li> -->
                <li v-if="isAdmin || (isCoordinator && isSchoolSelected)" :class="selectedItem == 6 ? 'active' : ''" @click="setSelectedItem(6)">Dokumenty</li>
                <li v-if="isAdmin" :class="selectedItem == 7 ? 'active' : ''" @click="setSelectedItem(7)">Školy</li>
                <li v-if="isAdmin || (isCoordinator && isSchoolSelected)" :class="selectedItem == 8 ? 'active' : ''" @click="setSelectedItem(8)">Učitelé</li>
                <li v-if="isAdmin" :class="selectedItem == 9 ? 'active' : ''" @click="setSelectedItem(9)">Administrátoři</li>
                <li :class="selectedItem == 10 ? 'active' : ''" @click="setSelectedItem(10)">Nastavení</li>
                <li :class="selectedItem == 11 ? 'active' : ''" @click="setSelectedItem(11)">O aplikaci</li>
                <li @click="loggoutUser()">Odhlášení</li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MenuComponent',
        emits: ['loggout-user', 'set-menu-item'],
        props: ['isAdmin', 'isCoordinator', 'isSchoolSelected'],
        data() {
            return {
                selectedItem: 1
            }
        },
        methods: {
            setSelectedItem(value) {
                this.selectedItem = value
                this.$emit('set-menu-item', value)
            },
            loggoutUser() {
                this.selectedItem = -1
                this.$emit('loggout-user')
            }
        }
    }
</script>