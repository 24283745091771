<template>
    <div class="rows" style="gap: 20px;">
        <div class="cols" style="gap: 5px;">
            <button class="button-small" @click="close(true)">Zavřít</button>
            <button v-if="cooperation.stav <= 1" class="button-small" @click="save()">Uložit</button>
            <button v-if="cooperation.stav <= 1" class="button-small" @click="saveAndClose()">Uložit a zavřít</button>            
            <button v-if="cooperation.stav == 1 && !isCoordinator" class="button-small" @click="sendToApprove()" style="margin-left: 20px;">Předat ke schválení</button>
            <button v-if="cooperation.stav == 1 && isCoordinator" class="button-small" @click="sendToApproveAdmin()" style="margin-left: 20px;">Předat ke schválení</button>
        </div>

        <div>
            <p>
                <b>Pokyny k vyplnění</b>
                <br />
                Do záložky <b>Základní údaje</b> vyplňte název skupiny aktivit, obvykle se uvádí 1. monitorovací zpráva (2., 3...).
                <br />
                Na záložce <b>Aktivity</b> zadáváte lektora (pokud není z vaší školy, zaškrtněte <i>Externí</i>), ve sloupci <i>Pracovníci</i> jsou učitelé vaší školy (další přidáváte +). Ve sloupci <i>Příprava</i> uvedete datum a čas (vždy násobek 60 minut). Sloupec <i>Realizace</i> obsahuje datum a čas (vždy násobek 45 minut), formu spolupráce a téma - je možné zadat <b>maximálně 3 bloky</b> (přidáváte +). V případě návštěvy jiné školy zadáte IČO hostitelské školy a automaticky se vyplní údaje. Ve sloupci <i>Reflexe</i> uvedete datum a čas (vždy násobek 60 minut).
                <br />
                Příprava, realizace a reflexe musí dát <b>dohromady 8 hodin</b> (sloupec <i>Souhrn</i>). Pokud je uvedeno číslo s desetinným místem, je chyba v čase od-do. Zcela vpravo je tlačítko <i>Vygenerovat dokument</i> - po doplnění všech údajů k aktivitě kliknete na toto tlačítko a do záložky <b>Přílohy</b> se vám uloží vyplněný formulář k šabloně č. 2. Tento dokument poté vytiskněte a podepište (podepisuje lektor, učitelé a ředitel školy). Pak dokument naskenujte a tlačítkem <i>Nahrát dokument</i> uložíte sken do aplikace.
                <br />
                Každý řádek je jedna aktivita v rozsahu 8 hodin. V případě zapojení 1 učitele = 1 šablona č. 2, 2 učitelé = 2 šablony č. 2 atd.
            </p>
        </div>

        <div class="cols" style="gap: 5px;">
            <button :class="'button-small' + (!this.showDefault ? ' button-inverse' : '')" @click="changeTabPage(0)">Základní údaje</button>
            <button :class="'button-small' + (!this.showActivity ? ' button-inverse' : '')" @click="changeTabPage(1)">Aktivity</button>
            <button v-if="this.cooperation.id !== -1" :class="'button-small' + (!this.showAttachements ? ' button-inverse' : '')" @click="changeTabPage(2)">Přílohy</button>
        </div>

        <div v-if="showDefault">
            <table class="table-border-none">
                <tr>
                    <td style="padding-left: 0;">Název:</td>
                    <td style="padding-right: 5px;"><input v-model="cooperation.nazev" style="width: 200px;" /><span class="required">*</span></td>
                </tr>
            </table>
        </div>

        <div v-if="showActivity">
            <input type="file" class="hidden-input" multiple name="fileInputInline" id="fileInputInline" @change="onChangeFileInputInline" ref="fileInputInline" accept=".pdf" />

            <table>
                <tr>
                    <th>&nbsp;</th>
                    <th>Lektor</th>
                    <th>Pracovníci</th>
                    <th>Příprava</th>
                    <th>Realizace</th>
                    <th>Jiná škola</th>
                    <th>Reflexe</th>
                    <th>Souhrn</th>
                    <th>&nbsp;</th>
                </tr>
                <tr v-for="(a, i) in this.cooperation.data.aktivity" :key="i">
                    <td style="vertical-align: top; padding-top: 10px; padding-bottom: 10px;"><span @click="removeActivity(i)" class="select-list-button-inverse">-</span>&nbsp;&nbsp;<span>{{ i + 1 }}</span></td>
                    <td style="vertical-align: top; padding-top: 10px; padding-bottom: 10px;">
                        <input type="checkbox" v-model="a.lektor.externi" />Externí<br />

                        <select v-if="!a.lektor.externi" v-model="a.lektor.id" style="width: 200px;">
                            <option :value="-1">&lt; vyberte &gt;</option>
                            <option v-for="(s, m) in sortedPracovnik" :key="m" :value="s.id">{{ s.prijmeni + ' ' + s.jmeno }}</option>
                        </select>

                        <input v-if="a.lektor.externi" v-model="a.lektor.jmenoExterni" placeholder="Příjmení Jméno" style="width: 200px;" />
                    </td>
                    <td style="vertical-align: top; padding-top: 10px; padding-bottom: 10px;">
                        <div v-for="(p, j) in a.pracovnici" :key="j" style="padding-bottom: 10px;">
                            <select v-model="p.typ">
                                <option :value="0">Pracovník</option>
                                <option :value="1">Student</option>
                            </select>
                            <span v-if="j > 0" @click="removePracovnik(i, j)" class="select-list-button-inverse" style="margin-left: 5px;">-</span>
                            <br />

                            <select v-if="p.typ == 0" v-model="p.id" style="width: 200px;">
                                <option :value="-1">&lt; vyberte &gt;</option>
                                <option v-for="(s, m) in sortedPracovnik" :key="m" :value="s.id">{{ s.prijmeni + ' ' + s.jmeno }}</option>
                            </select>

                            <input v-if="p.typ == 1" v-model="p.jmenoExterni" style="width: 200px;" />
                        </div>
                        <div><span @click="addPracovnik(i)" class="select-list-button-inverse">+</span></div>
                    </td>
                    <td style="vertical-align: top; padding-top: 10px; padding-bottom: 10px;">
                        <div style="display: flex; flex-direction: column;">
                            <VueDatePicker
                                v-model="a.priprava.datum"
                                locale="cs"
                                :auto-apply="true"
                                :enable-time-picker="false"
                                :hide-input-icon="true"
                                :text-input="true"
                                format="d.M.yyyy"
                                timezone="UTC"
                                utc="preserve"
                                class="datepicker-140"
                                six-weeks="append"
                            />
                            <div style="display: flex; flex-direction: row;">
                                <VueDatePicker
                                    v-model="a.priprava.casOd"
                                    time-picker
                                    :hide-input-icon="true"
                                    :text-input="true"
                                    select-text="Vybrat"
                                    cancel-text="Zrušit"
                                    class="timepicker"
                                    minutes-increment="5"
                                    :min-time="{ hours: 6, minutes: 0 }"
                                    :start-time="{ hours: 13, minutes: 0 }"
                                />
                                <VueDatePicker
                                    v-model="a.priprava.casDo"
                                    time-picker
                                    :hide-input-icon="true"
                                    :text-input="true"
                                    select-text="Vybrat"
                                    cancel-text="Zrušit"
                                    class="timepicker"
                                    minutes-increment="5"
                                    :min-time="{ hours: 6, minutes: 0 }"
                                    :start-time="{ hours: 13, minutes: 0 }"
                                />
                            </div>                        
                        </div>
                    </td>
                    <td style="vertical-align: top; padding-top: 10px; padding-bottom: 10px;">
                        <div v-for="(r, k) in a.realizace" :key="k" style="padding-bottom: 10px;">
                            <div style="display: flex; flex-direction: column;">
                                <div style="display: flex; flex-direction: row;">
                                    <VueDatePicker
                                        v-model="r.datum"
                                        locale="cs"
                                        :auto-apply="true"
                                        :enable-time-picker="false"
                                        :hide-input-icon="true"
                                        :text-input="true"
                                        format="d.M.yyyy"
                                        timezone="UTC"
                                        utc="preserve"
                                        class="datepicker-140"
                                        six-weeks="append"
                                    />
                                    <span v-if="k > 0" @click="removeRealizace(i, k)" class="select-list-button-inverse" style="margin-left: 5px;">-</span>
                                </div>
                                <div style="display: flex; flex-direction: row;">
                                    <VueDatePicker
                                        v-model="r.casOd"
                                        time-picker
                                        :hide-input-icon="true"
                                        :text-input="true"
                                        select-text="Vybrat"
                                        cancel-text="Zrušit"
                                        class="timepicker"
                                        minutes-increment="5"
                                        :min-time="{ hours: 6, minutes: 0 }"
                                        :start-time="{ hours: 13, minutes: 0 }"
                                    />
                                    <VueDatePicker
                                        v-model="r.casDo"
                                        time-picker
                                        :hide-input-icon="true"
                                        :text-input="true"
                                        select-text="Vybrat"
                                        cancel-text="Zrušit"
                                        class="timepicker"
                                        minutes-increment="5"
                                        :min-time="{ hours: 6, minutes: 0 }"
                                        :start-time="{ hours: 13, minutes: 0 }"
                                    />
                                </div>
                                <div>
                                    <select v-model="r.forma" style="width: 200px;">
                                        <option value="">&lt; vyberte formu &gt;</option>
                                        <option value="hospitace ve výuce">hospitace ve výuce</option>
                                        <option value="minilekce ve výuce">minilekce ve výuce</option>
                                        <option value="návštěva v jiné škole/školském zařízení">návštěva v jiné škole/školském zařízení</option>
                                    </select>
                                </div>
                                <div>
                                    <select v-model="r.tema" style="width: 200px;">
                                        <option value="">&lt; vyberte téma &gt;</option>
                                        <option value="umělecká gramotnost">umělecká gramotnost</option>
                                        <option value="kulturní povědomí a vyjádření">kulturní povědomí a vyjádření</option>
                                        <option value="řízení organizace, leadership a řízení pedagogického procesu">řízení organizace, leadership a řízení pedagogického procesu</option>
                                        <option value="cizí jazyky/komunikace v cizím jazyce">cizí jazyky/komunikace v cizím jazyce</option>
                                        <option value="profesní rozvoj ostatních pracovníků ve vzdělávání">profesní rozvoj ostatních pracovníků ve vzdělávání</option>
                                        <option value="alternativní/inovativní formy výuky">alternativní/inovativní formy výuky</option>
                                        <option value="čtenářská gramotnost">čtenářská gramotnost</option>
                                        <option value="EVVO včetně vzdělávání pro udržitelný rozvoj">EVVO včetně vzdělávání pro udržitelný rozvoj</option>
                                        <option value="formativní hodnocení">formativní hodnocení</option>
                                        <option value="genderová tematika v obsahu vzdělávání">genderová tematika v obsahu vzdělávání</option>
                                        <option value="historické povědomí, výuka moderních dějin">historické povědomí, výuka moderních dějin</option>
                                        <option value="individualizace vzdělávání a vedení portfolia dítěte/žáka/studenta">individualizace vzdělávání a vedení portfolia dítěte/žáka/studenta</option>
                                        <option value="inkluze">inkluze</option>
                                        <option value="inovace ŠVP/ŠAP/Koncepce rozvoje školy/školského zařízení">inovace ŠVP/ŠAP/Koncepce rozvoje školy/školského zařízení</option>
                                        <option value="kariérové poradenství včetně identifikace a rozvoje nadání">kariérové poradenství včetně identifikace a rozvoje nadání</option>
                                        <option value="matematická gramotnost">matematická gramotnost</option>
                                        <option value="mediální gramotnost, prevence kyberšikany, chování na sociálních sítích">mediální gramotnost, prevence kyberšikany, chování na sociálních sítích</option>
                                        <option value="občanské vzdělávání a demokratické myšlení">občanské vzdělávání a demokratické myšlení</option>
                                        <option value="pedagogická diagnostika">pedagogická diagnostika</option>
                                        <option value="podpora uvádějících/provázejících učitelů">podpora uvádějících/provázejících učitelů</option>
                                        <option value="projektová výuka">projektová výuka</option>
                                        <option value="propojování formálního a neformálního vzdělávání">propojování formálního a neformálního vzdělávání</option>
                                        <option value="přírodovědné a technické vzdělávání">přírodovědné a technické vzdělávání</option>
                                        <option value="rozvoj podnikavosti a kreativity">rozvoj podnikavosti a kreativity</option>
                                        <option value="spolupráce s rodiči a zákonnými zástupci dětí a žáků">spolupráce s rodiči a zákonnými zástupci dětí a žáků</option>
                                        <option value="vzdělávání s využitím nových technologií">vzdělávání s využitím nových technologií</option>
                                        <option value="well-being a psychohygiena">well-being a psychohygiena</option>
                                        <option value="zážitková pedagogika">zážitková pedagogika</option>                         
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div v-if="a.realizace.length < 3"><span @click="addRealizace(i)" class="select-list-button-inverse">+</span></div>
                    </td>
                    <td style="vertical-align: top; padding-top: 10px; padding-bottom: 10px; width: 200px;">
                        <span v-if="a.realizace.filter(r => r.forma == 'návštěva v jiné škole/školském zařízení').slice().length > 0">
                            <input v-model="a.jinaSkolaICO" style="width: 100px;" placeholder="IČ" @input="icoChange(a)" />
                            <br /><br />
                            {{ a.jinaSkolaNazev }}
                        </span>
                    </td>
                    <td style="vertical-align: top; padding-top: 10px; padding-bottom: 10px;">
                        <div style="display: flex; flex-direction: column;">
                            <VueDatePicker
                                v-model="a.reflexe.datum"
                                locale="cs"
                                :auto-apply="true"
                                :enable-time-picker="false"
                                :hide-input-icon="true"
                                :text-input="true"
                                format="d.M.yyyy"
                                timezone="UTC"
                                utc="preserve"
                                class="datepicker-140"
                                six-weeks="append"
                            />
                            <div style="display: flex; flex-direction: row;">
                                <VueDatePicker
                                    v-model="a.reflexe.casOd"
                                    time-picker
                                    :hide-input-icon="true"
                                    :text-input="true"
                                    select-text="Vybrat"
                                    cancel-text="Zrušit"
                                    class="timepicker"
                                    minutes-increment="5"
                                    :min-time="{ hours: 6, minutes: 0 }"
                                    :start-time="{ hours: 13, minutes: 0 }"
                                />
                                <VueDatePicker
                                    v-model="a.reflexe.casDo"
                                    time-picker
                                    :hide-input-icon="true"
                                    :text-input="true"
                                    select-text="Vybrat"
                                    cancel-text="Zrušit"
                                    class="timepicker"
                                    minutes-increment="5"
                                    :min-time="{ hours: 6, minutes: 0 }"
                                    :start-time="{ hours: 13, minutes: 0 }"
                                />
                            </div>                        
                        </div>
                    </td>
                    <td style="vertical-align: top; padding-top: 10px; padding-bottom: 10px;">
                        Příprava:&nbsp;{{ getHours(a, 0) }}&nbsp;h<br />
                        Realizace:&nbsp;{{ getHours(a, 1) }}&nbsp;h<br />
                        Reflexe:&nbsp;{{ getHours(a, 2) }}&nbsp;h<br /><br />
                        <span :class="getTotalHours(a) == 8 ? 'color-green' : 'color-red'">Celkem:&nbsp;{{ getTotalHours(a) }}&nbsp;h</span>
                    </td>
                    <td style="vertical-align: top; padding-top: 10px; padding-bottom: 10px;">
                        <button class="button-small button-inverse" @click="generateDocument(i)" style="width: 170px;">Vygenerovat dokument</button>
                        <br />
                        <button class="button-small button-inverse" @click="uploadDocument(i)" style="width: 170px;">Uložit dokument</button>
                        <br /><br />

                        <div v-for="(at, m) in this.attachements.filter(f => f.nazev.startsWith('2.' + this.cooperation.nazev.slice(0, 1) + '_spoluprace ' + (i+1)))" :key="m">
                            <span class="file-span" v-html="getFile(at.cesta, at.nazev)"></span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" style="border: none; padding-top: 10px; padding-bottom: 10px;">
                        <span @click="addActivity(1)" class="select-list-button-wide">+ 1</span>&nbsp;<span @click="addActivity(5)" class="select-list-button-wide">+ 5</span>
                    </td>
                    <td colspan="5" style="border: none; padding-top: 10px; padding-bottom: 10px;">&nbsp;</td>
                </tr>
            </table>
        </div>

        <div v-if="showAttachements">
            <FilesComponent :path="idSchool + '/sablony/2/' + selectedCooperation" :prefix="getFilePrefix" />
        </div>
        
        <div v-if="showActivity" class="cols" style="gap: 5px; margin-top: 50px; margin-bottom: 20px;">
            <button class="button-small" @click="close(true)">Zavřít</button>
            <button v-if="cooperation.stav <= 1" class="button-small" @click="save()">Uložit</button>
            <button v-if="cooperation.stav <= 1" class="button-small" @click="saveAndClose()">Uložit a zavřít</button>
        </div>
    </div>

    <MessageBoxComponent v-if="showMessageBox" :message="messageText" @close-event="closeMessageBox" />       
    <ConfirmBoxComponent v-if="showConfirmBox" :message="confirmMessage" :dialogType="confirmType" @close-event="closeConfirmBox" />
</template>

<script>
    import api from '@/api/api.js'
    import VueDatePicker from '@vuepic/vue-datepicker'
    import '@vuepic/vue-datepicker/dist/main.css'
    import dayjs from 'dayjs'
    import utc from 'dayjs/plugin/utc'
    dayjs.extend(utc)

    import MessageBoxComponent from '../dialogs/MessageBoxComponent.vue'
    import ConfirmBoxComponent from '../dialogs/ConfirmBoxComponent.vue'
    import FilesComponent from '../files/FilesComponent.vue'

    export default {
        name: 'CooperationComponent',
        emits: ['show-saving-message', 'show-document-generation-message', 'close-card'],
        props: ['selectedCooperation', 'idUser', 'idSchool', 'isCoordinator'],
        expose: ['save'],
        components: {
            VueDatePicker,
            MessageBoxComponent,
            ConfirmBoxComponent,
            FilesComponent
        },
        data() {
            return {
                showDefault: true,
                showActivity: false,
                showAttachements: false,
                cooperation: {
                    id: -1,
                    nazev: '',
                    idSkola: -1,
                    idUcitel: -1,
                    upravil: '',
                    stav: 0,
                    data: {
                        nazev: '',
                        aktivity: []
                    }
                },
                pracovnici: [],
                showMessageBox: false,
                messageText: "",
                showConfirmBox: false,
                confirmMessage: "",
                confirmType: -1,
                removeIndex: -1,
                fileName: '',
                files: [],
                attachements: []
            }
        },
        computed: {
            sortedPracovnik() {
                return this.pracovnici
                    .slice()
                    .sort((a, b) => (a.prijmeni + ' ' + a.jmeno).localeCompare(b.prijmeni + ' ' + b.jmeno, 'cz'))
            },
            getFilePrefix() {
                let nazev = this.cooperation.nazev.replace(/ /g, '_')
                nazev = nazev.length > 20 ? nazev.slice(0, 17) + "…" : nazev
                
                return '2_' + nazev + '_'
            }
        },
        methods: {
            formatDate(date) {
                return date !== null ? dayjs(date).utcOffset(0).format('D.M.YYYY') : ''
            },
            showSavingDataMessage(value) {
                this.$emit('show-saving-message', value)
            },
            showDocumentgenerationMessage(value) {
                this.$emit('show-document-generation-message', value)
            },
            async load() {
                this.cooperation.id = this.selectedCooperation

                try {
                    let response = await api.getTeacher(this.idSchool)
                    this.pracovnici = response.data
                }
                catch (error) {
                    console.log('Error: ', error)
                }

                if (this.cooperation.id == -1) {
                    try {
                        let response = await api.getTeacherByUserId(this.idUser, this.idSchool)
                        this.cooperation.idSkola = this.idSchool
                        this.cooperation.idUcitel = response.data.id
                        this.cooperation.upravil = response.data.prijmeni + ' ' + response.data.jmeno
                    }
                    catch (error) {
                        console.log('Error: ', error)
                    }
                }
                else {
                    try {
                        let response = await api.getCooperation(this.selectedCooperation)
                        this.cooperation = response.data
                        await this.loadAttachements()
                    }
                    catch (error) {
                        console.log('Error: ', error)
                    }
                }
            },
            async loadAttachements() {
                try {
                    let response = await api.getFiles(this.idSchool + '/sablony/2/' + this.cooperation.id)
                    this.attachements = response.data
                }
                catch (error) {
                    console.log('Error: ', error)
                }
            },
            getTotalHours(a) {
                let hours = 0.0
                if (a.priprava.casOd != null && a.priprava.casDo != null) {
                    let minutes = a.priprava.casDo.hours * 60 + a.priprava.casDo.minutes - a.priprava.casOd.hours * 60 - a.priprava.casOd.minutes
                    hours += minutes / 60
                }
                for (let i = 0; i < a.realizace.length; i++) {
                    if (a.realizace[i].casOd != null && a.realizace[i].casDo != null) {
                        let minutes = a.realizace[i].casDo.hours * 60 + a.realizace[i].casDo.minutes - a.realizace[i].casOd.hours * 60 - a.realizace[i].casOd.minutes
                        hours += minutes / 45
                    }
                }
                if (a.reflexe.casOd != null && a.reflexe.casDo != null) {
                    let minutes = a.reflexe.casDo.hours * 60 + a.reflexe.casDo.minutes - a.reflexe.casOd.hours * 60 - a.reflexe.casOd.minutes
                    hours += minutes / 60
                }
                return hours % 1 != 0 ? hours.toFixed(2) : hours
            },
            getHours(a, t) {
                let hours = 0.0

                switch (t) {
                    case 0: // priprava
                        if (a.priprava.casOd != null && a.priprava.casDo != null) {
                            let minutes = a.priprava.casDo.hours * 60 + a.priprava.casDo.minutes - a.priprava.casOd.hours * 60 - a.priprava.casOd.minutes
                            hours += minutes / 60
                        }
                        break;

                    case 1: // realizace
                        for (let i = 0; i < a.realizace.length; i++) {
                            if (a.realizace[i].casOd != null && a.realizace[i].casDo != null) {
                                let minutes = a.realizace[i].casDo.hours * 60 + a.realizace[i].casDo.minutes - a.realizace[i].casOd.hours * 60 - a.realizace[i].casOd.minutes
                                hours += minutes / 45
                            }
                        }
                        break;

                    case 2: // reflexe
                        if (a.reflexe.casOd != null && a.reflexe.casDo != null) {
                            let minutes = a.reflexe.casDo.hours * 60 + a.reflexe.casDo.minutes - a.reflexe.casOd.hours * 60 - a.reflexe.casOd.minutes
                            hours += minutes / 60
                        }
                        break;
                }
                
                return hours % 1 != 0 ? hours.toFixed(2) : hours
            },
            async icoChange(a) {
                if (a.jinaSkolaICO.length == 8) {
                    let response = await api.ares(a.jinaSkolaICO)
                    if (response.status == 200) {
                        a.jinaSkolaNazev = response.data.obchodniJmeno
                    }
                    else
                        a.jinaSkolaNazev = ''
                }
            },
            async generateDocument(rid) {
                this.showDocumentgenerationMessage(true)

                if (await this.save()) {
                    if (this.cooperation.id !== -1) {    
                        try {                
                            await api.generateCooperationDocument(this.cooperation.id, rid)
                            await this.loadAttachements()
                        }
                        catch (error) {
                            console.log("Error: ", error)
                        }
                    }
                }

                this.showDocumentgenerationMessage(false)
            },
            async uploadDocument(rid) {
                this.fileName = '2.' + this.cooperation.nazev.slice(0, 1) + '_spoluprace ' + (rid+1) + '_' + this.formatDate(this.cooperation.data.aktivity[rid].reflexe.datum) + '_podepsano.pdf'
                this.$refs.fileInputInline.click()
            },
            async onChangeFileInputInline() {
                this.files = []
                this.files.push(...this.$refs.fileInputInline.files)
                await this.uploadFiles()
            },
            async uploadFiles() {
                const files = this.files
                const formData = new FormData()
                files.forEach((file) => {
                    formData.append("selectedFiles", file);
                })

                try {
                    await api.uploadSingleFile(formData, this.idSchool + '/sablony/2/' + this.selectedCooperation, this.fileName)
                }
                catch (error) {
                    console.log("Error: ", error)
                }

                this.files = []
            },
            getFile(path, name) {
                return '<a class="file-name" target="_blank" href="' + process.env.VUE_APP_API_URL + '/soubory/download?soubor=' + path + '/' + encodeURIComponent(name) + '">' + name + '</a>'
            },
            async save() {
                if (this.cooperation.nazev.length > 0) {
                    try {
                        this.showSavingDataMessage(true)

                        this.cooperation.data.nazev = this.cooperation.nazev
                        for (let i = 0; i < this.cooperation.data.aktivity.length; i++) {
                            if (this.cooperation.data.aktivity[i].lektor.id == -1) {
                                this.cooperation.data.aktivity[i].lektor.prijmeni = ""
                                this.cooperation.data.aktivity[i].lektor.jmeno = ""
                            }
                            else {
                                let p = this.pracovnici.filter(a => a.id == this.cooperation.data.aktivity[i].lektor.id).slice()[0]
                                this.cooperation.data.aktivity[i].lektor.prijmeni = p.prijmeni
                                this.cooperation.data.aktivity[i].lektor.jmeno = p.jmeno
                            }

                            for (let j = 0; j < this.cooperation.data.aktivity[i].pracovnici.length; j++) {
                                if (this.cooperation.data.aktivity[i].pracovnici[j].id == -1) {
                                    this.cooperation.data.aktivity[i].pracovnici[j].prijmeni = ""
                                    this.cooperation.data.aktivity[i].pracovnici[j].jmeno = ""
                                    this.cooperation.data.aktivity[i].pracovnici[j].datumNarozeni = null
                                }
                                else {
                                    let p = this.pracovnici.filter(a => a.id == this.cooperation.data.aktivity[i].pracovnici[j].id).slice()[0]
                                    this.cooperation.data.aktivity[i].pracovnici[j].prijmeni = p.prijmeni
                                    this.cooperation.data.aktivity[i].pracovnici[j].jmeno = p.jmeno
                                    this.cooperation.data.aktivity[i].pracovnici[j].datumNarozeni = p.datumNarozeni
                                }
                            }
                        }

                        if (this.cooperation.id !== -1) {
                            await api.putCooperation(this.cooperation)
                        }
                        else {
                            let response = await api.postCooperation(this.cooperation)
                            if (response.status == 200)
                                this.cooperation.id = response.data
                        }

                        this.showSavingDataMessage(false)
                    }
                    catch (error) {
                        this.showSavingDataMessage(false)
                        console.log('Error: ', error)
                        this.message("Nastala chyba při ukládání dat")
                        return false
                    }
                }
                else{
                    this.message('Vyplňte všechny povinné položky!')
                    return false
                }
                return true
            },
            close(ask = false) {
                if (ask)
                    this.confirm(4, 'Uložit změny?')
                else
                    this.$emit('close-card')
            },
            async saveAndClose() {
                if (await this.save())
                    this.close()
            },
            async sendToApprove() {
                if (await this.save())
                    this.confirm(1, 'Šablona bude odeslána ke schválení koordinátorovi. Pokračovat?')
            },
            async sendToApproveAdmin() {
                if (await this.save())
                    this.confirm(2, 'Šablona bude odeslána ke schválení administrátorovi. Pokračovat?')
            },
            async changeTabPage(page) {
                this.showDefault = false
                this.showActivity = false
                this.showAttachements = false

                switch (page) {
                    case 1:
                        await this.loadAttachements()
                        this.showActivity = true
                        break
                    case 2:
                        this.showAttachements = true
                        break
                    default:
                        this.showDefault = true
                        break
                }
            },
            addActivity(count) {
                for (let c = 0; c < count; c++) {
                    this.cooperation.data.aktivity.push({
                        lektor: { externi: false, id: -1, prijmeni: '', jmeno: '', jmenoExterni: '' },
                        pracovnici: [ { typ: 0, id: -1, prijmeni: '', jmeno: '', datumNarozeni: null, jmenoExterni: '' } ],
                        priprava: { datum: null, casOd: null, casDo: null },
                        realizace: [ { datum: null, casOd: null, casDo: null, forma: '', tema: '' } ],
                        reflexe: { datum: null, casOd: null, casDo: null },
                        jinaSkolaICO: '',
                        jinaSkolaNazev: ''
                    })
                }
            },
            removeActivity(index) {
                this.removeIndex = index
                this.confirm(3, 'Chcete smazat aktivitu?')
            },
            addPracovnik(index) {
                this.cooperation.data.aktivity[index].pracovnici.push({ typ: 0, externi: false, id: -1, jmeno: '' })
            },
            removePracovnik(indexA, indexP) {
                this.cooperation.data.aktivity[indexA].pracovnici.splice(indexP, 1)
            },
            addRealizace(index) {
                this.cooperation.data.aktivity[index].realizace.push({ datum: null, casOd: null, casDo: null, forma: '', tema: '' })
            },
            removeRealizace(indexA, indexR) {
                this.cooperation.data.aktivity[indexA].realizace.splice(indexR, 1)
            },
            message(message) {
                this.messageText = message
                this.showMessageBox = true
            },
            async closeMessageBox() {
                this.showMessageBox = false
            },
            confirm(type, message) {                
                this.confirmType = type
                this.confirmMessage = message
                this.showConfirmBox = true
            },
            async closeConfirmBox(dialogResult, dialogType) {
                this.showConfirmBox = false
                this.confirmType = -1

                if (dialogResult == 1) {
                    switch (dialogType) {
                        case 1:
                            await api.sendCooperationToApproveByCoordinator(this.cooperation.id)
                            this.close()
                            break

                        case 2:
                            await api.sendCooperationToApproveByAdmin(this.cooperation.id)
                            this.close()
                            break
                            
                        case 3:
                            this.cooperation.data.aktivity.splice(this.removeIndex, 1)
                            this.removeIndex = -1
                            break

                        case 4:
                            this.saveAndClose()
                            break
                    }
                }
                else {
                    switch (dialogType) {
                        case 4:
                            this.close()
                            break
                    }
                }
            }
        },
        async created() {
            await this.load()
        }
    }
</script>