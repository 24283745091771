<template>
    <div class="fullwidth">
        <div class="rows gap-30 fullscreen">
            <div class="cols" style="gap: 5px;">
                <button :class="'button-small' + (!this.showConflicts ? ' button-inverse' : '')" @click="changeTabPage(1)">Souběhy</button>
            </div>

            <div v-if="showConflicts" class="fullscreen">

                <div class="conflict-third">
                    <div class="font-bold" style="margin-bottom: 10px;">Souběhy akcí učitelů</div>
                    <div v-for="(ct, i) in this.conflictsTeachers" :key="i">
                        <div class="foldable-row">
                            <button class="button-small button-inverse" @click="changeFoldCT1School(i)">{{ ct.rozbaleno ? '-' : '+' }}</button>
                            <span>{{ ct.skola }}</span>                            

                            <div v-if="ct.rozbaleno">
                                <div v-for="(ctu, j) in ct.ucitele" :key="j">
                                    <div class="foldable-row foldable-row-sublevel">
                                        <button class="button-small button-inverse" @click="changeFoldCT1Teacher(i, j)">{{ ctu.rozbaleno ? '-' : '+' }}</button>
                                        <span>{{ ctu.ucitel }}</span>

                                        <div v-if="ctu.rozbaleno">
                                            <div v-for="(cta, k) in ctu.akce" :key="k">
                                                <div class="foldable-row foldable-row-sublevel">
                                                    <button class="button-small button-inverse" @click="changeFoldCT1Action(i, j, k)">{{ cta.rozbaleno ? '-' : '+' }}</button>
                                                    <span>{{ cta.nazev }}</span>

                                                    <div v-if="cta.rozbaleno">
                                                        <div v-for="(ctr, m) in cta.rozsah" :key="m">
                                                            <div class="foldable-row foldable-row-sublevel">
                                                                <span>{{ formatDate(ctr.datumOd) + ' - ' + formatDate(ctr.datumDo) }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div class="conflict-third">
                    <div class="font-bold" style="margin-bottom: 10px;">Akce mimo zaměstnání</div>
                    <div v-for="(ct, i) in this.conflictsWorks" :key="i">
                        <div class="foldable-row">
                            <button class="button-small button-inverse" @click="changeFoldCT2School(i)">{{ ct.rozbaleno ? '-' : '+' }}</button>
                            <span>{{ ct.skola }}</span>                            

                            <div v-if="ct.rozbaleno">
                                <div v-for="(ctu, j) in ct.ucitele" :key="j">
                                    <div class="foldable-row foldable-row-sublevel">
                                        <button class="button-small button-inverse" @click="changeFoldCT2Teacher(i, j)">{{ ctu.rozbaleno ? '-' : '+' }}</button>
                                        <span>{{ ctu.ucitel }}</span>

                                        <div v-if="ctu.rozbaleno">
                                            <div v-for="(cta, k) in ctu.akce" :key="k">
                                                <div class="foldable-row foldable-row-sublevel">
                                                    <button class="button-small button-inverse" @click="changeFoldCT2Action(i, j, k)">{{ cta.rozbaleno ? '-' : '+' }}</button>
                                                    <span>{{ cta.nazev }}</span>

                                                    <div v-if="cta.rozbaleno">
                                                        <div v-for="(ctr, m) in cta.rozsah" :key="m">
                                                            <div class="foldable-row foldable-row-sublevel">
                                                                <span>{{ formatDate(ctr.datumOd) + ' - ' + formatDate(ctr.datumDo) }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div class="conflict-third">
                    <div class="font-bold" style="margin-bottom: 10px;">Souběhy akcí žáků</div>
                    <div v-for="(ct, i) in this.conflictsStudents" :key="i">
                        <div class="foldable-row">
                            <button class="button-small button-inverse" @click="changeFoldCT3School(i)">{{ ct.rozbaleno ? '-' : '+' }}</button>
                            <span>{{ ct.skola }}</span>                            

                            <div v-if="ct.rozbaleno">
                                <div v-for="(ctz, j) in ct.zaci" :key="j">
                                    <div class="foldable-row foldable-row-sublevel">
                                        <button class="button-small button-inverse" @click="changeFoldCT3Student(i, j)">{{ ctz.rozbaleno ? '-' : '+' }}</button>
                                        <span>{{ ctz.zak }}</span>

                                        <div v-if="ctz.rozbaleno">
                                            <div v-for="(cta, k) in ctz.akce" :key="k">
                                                <div class="foldable-row foldable-row-sublevel">
                                                    <button class="button-small button-inverse" @click="changeFoldCT3Action(i, j, k)">{{ cta.rozbaleno ? '-' : '+' }}</button>
                                                    <span>{{ cta.nazev }}</span>

                                                    <div v-if="cta.rozbaleno">
                                                        <div v-for="(ctr, m) in cta.rozsah" :key="m">
                                                            <div class="foldable-row foldable-row-sublevel">
                                                                <span>{{ formatDateTime(ctr.datumOd) + ' - ' + formatDateTime(ctr.datumDo) }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
    import api from '@/api/api.js'
    import dayjs from 'dayjs'
    import utc from 'dayjs/plugin/utc'
    dayjs.extend(utc)

    export default {
        name: 'OverviewAdminComponent',
        props: [],
        data() {
            return {
                showConflicts: false,
                conflictsTeachers: [],
                conflictsWorks: [],
                conflictsStudents: []
            }
        },
        methods: {
            formatDate(date) {
                return date !== null ? dayjs(date + 'Z').utcOffset(0).format('D.M.YYYY') : ''
            },
            formatDateTime(date) {
                return date !== null ? dayjs(date + 'Z').utcOffset(0).format('D.M.YYYY H:mm') : ''
            },
            async updateConflicts() {
                try {
                    let response = await api.getConflicts()
                    this.conflictsTeachers = response.data.soubehyUcitele
                    this.conflictsWorks = response.data.soubehyZamestnani
                    this.conflictsStudents = response.data.soubehyZaci
                }
                catch (error) {
                    console.log('Error: ', error)
                }
            },
            changeTabPage(page) {
                switch (page) {
                    case 1:
                        this.showConflicts = true
                        this.updateConflicts()
                        break
                }
            },
            changeFoldCT1School(index) {
                this.conflictsTeachers[index].rozbaleno = !this.conflictsTeachers[index].rozbaleno
            },
            changeFoldCT1Teacher(indexSchool, index) {
                this.conflictsTeachers[indexSchool].ucitele[index].rozbaleno = !this.conflictsTeachers[indexSchool].ucitele[index].rozbaleno
            },
            changeFoldCT1Action(indexSchool, indexTeacher, index) {
                this.conflictsTeachers[indexSchool].ucitele[indexTeacher].akce[index].rozbaleno = !this.conflictsTeachers[indexSchool].ucitele[indexTeacher].akce[index].rozbaleno
            },
            changeFoldCT2School(index) {
                this.conflictsWorks[index].rozbaleno = !this.conflictsWorks[index].rozbaleno
            },
            changeFoldCT2Teacher(indexSchool, index) {
                this.conflictsWorks[indexSchool].ucitele[index].rozbaleno = !this.conflictsWorks[indexSchool].ucitele[index].rozbaleno
            },
            changeFoldCT2Action(indexSchool, indexTeacher, index) {
                this.conflictsWorks[indexSchool].ucitele[indexTeacher].akce[index].rozbaleno = !this.conflictsWorks[indexSchool].ucitele[indexTeacher].akce[index].rozbaleno
            },
            changeFoldCT3School(index) {
                this.conflictsStudents[index].rozbaleno = !this.conflictsStudents[index].rozbaleno
            },
            changeFoldCT3Student(indexSchool, index) {
                this.conflictsStudents[indexSchool].zaci[index].rozbaleno = !this.conflictsStudents[indexSchool].zaci[index].rozbaleno
            },
            changeFoldCT3Action(indexSchool, indexStudent, index) {
                this.conflictsStudents[indexSchool].zaci[indexStudent].akce[index].rozbaleno = !this.conflictsStudents[indexSchool].zaci[indexStudent].akce[index].rozbaleno
            }
        }
    }
</script>