<template>
    <div v-if="!showCard">
        <button class="button-small" @click="newAdmin()">Nový záznam</button>
        <br />
        <table style="margin-top: 20px;">
            <thead>
                <tr>
                    <th v-for="(h, k) in headers" :key="k" @click="sort(k)">{{ h }}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, i) in sortedRows" :key="i" >
                    <td v-for="(header, j) in headerKeys" :key="j" @click="openCard(row.id)">{{ row[header] }}</td>
                    <td><button class="button-small" @click="deleteAdmin(row.id)">Smazat</button></td>
                </tr>
            </tbody>
        </table>
    </div>

    <div v-if="showCard">
        <div class="cols" style="gap: 5px;">
            <button class="button-small" @click="closeCard(true)">Zavřít</button>
            <button class="button-small" @click="saveCard()">Uložit</button>
            <button class="button-small" @click="saveAndCloseCard()">Uložit a zavřít</button>
        </div>

        <table class="table-border-none" style="margin-top: 20px;">
            <tr>
                <td style="padding-left: 0;">E-mail:</td>
                <td><input v-model="selectedAdmin.email" style="width: 200px;" /><span class="required">*</span></td>
            </tr>
            <tr>
                <td style="padding-left: 0;">Jméno:</td>
                <td><input v-model="selectedAdmin.jmeno" style="width: 200px;" /><span class="required">*</span></td>
            </tr>
            <tr>
                <td style="padding-left: 0;">Příjmení:</td>
                <td><input v-model="selectedAdmin.prijmeni" style="width: 200px;" /><span class="required">*</span></td>
            </tr>
        </table>
    </div>
                
    <ConfirmBoxComponent v-if="showConfirmBox" :message="confirmMessage" :dialogType="confirmType" @close-event="closeConfirmBox" />
    <MessageBoxComponent v-if="showMessageBox" :message="messageText" @close-event="closeMessageBox" />
</template>

<script>
    import api from '@/api/api.js'
    
    import ConfirmBoxComponent from '../dialogs/ConfirmBoxComponent.vue'
    import MessageBoxComponent from '../dialogs/MessageBoxComponent.vue'

    export default {
        name: 'AdminsComponent',
        emits: ['show-saving-message'],
        components: {
            ConfirmBoxComponent,
            MessageBoxComponent
        },
        data() {
            return {
                headers: {},
                rows: [],
                currentSort: '',
                currentSortDir: 'asc',
                selectedAdmin: {},
                showCard: false,
                showConfirmBox: false,
                confirmMessage: "",
                confirmType: -1,
                deleteId: -1,
                showMessageBox: false,
                messageText: ""
            }
        },
        computed: {
            headerKeys() {
                return Object.keys(this.headers)
            },
            sortedRows() {
                if (this.currentSort === '')
                    return this.rows
                return this.rows.slice().sort(
                    (a, b) => this.currentSortDir === 'desc' 
                        ? (b[this.currentSort] === null || b[this.currentSort] === undefined ? -1 : (a[this.currentSort] === null || a[this.currentSort] === undefined ? 1 : b[this.currentSort].localeCompare(a[this.currentSort], 'cz'))) 
                        : (a[this.currentSort] === null || a[this.currentSort] === undefined ? -1 : (b[this.currentSort] === null || b[this.currentSort] === undefined ? 1 : a[this.currentSort].localeCompare(b[this.currentSort], 'cz'))))
            }
        },
        methods: {
            showSavingDataMessage(value) {
                this.$emit('show-saving-message', value)
            },
            async updateTable() {
                this.headers = { "email": "E-mail", "prijmeni": "Příjmení", "jmeno": "Jméno" }

                try {
                    let response = await api.getAdmin()
                    this.rows = response.data
                }
                catch (error) {
                    console.log('Error: ', error)
                }
            },
            sort(s) {
                if (s === this.currentSort) {
                    this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc'
                }
                else {
                    this.currentSort = s
                    this.currentSortDir = 'asc'
                }
            },
            openCard(id) {
                this.selectedAdmin = this.rows.filter(a => a.id == id).slice()[0]
                this.showCard = true
            },
            closeCard(ask = false)
            {
                if (ask)
                    this.confirm(2, 'Uložit změny?')
                else {
                    this.showCard = false
                    this.selectedAdmin = {}
                    this.updateTable()
                }
            },
            async saveCard()
            {
                if (this.selectedAdmin.email.length > 0 && this.selectedAdmin.jmeno.length > 0 && this.selectedAdmin.prijmeni.length > 0) {
                    try {
                        this.showSavingDataMessage(true)
                        if (this.selectedAdmin.id !== -1)
                            await api.putAdmin(this.selectedAdmin)
                        else {
                            let response = await api.postAdmin(this.selectedAdmin)
                            if (response.status == 200)
                                this.selectedAdmin.id = response.data
                        }
                        this.showSavingDataMessage(false)
                    }
                    catch (error) {
                        this.showSavingDataMessage(false)
                        console.log("Error: ", error)
                        this.message("Nastala chyba při ukládání dat")
                    }
                }
                else{
                    alert('Vyplňte všechny povinné položky!')
                }
            },
            async saveAndCloseCard()
            {
                await this.saveCard()
                this.closeCard()
            },
            newAdmin() {
                this.selectedAdmin = { id: -1, email: '', jmeno: '', prijmeni: '' }
                this.showCard = true
            },
            deleteAdmin(id) {
                this.deleteId = id
                this.confirm(1, 'VAROVÁNÍ! Tuto akci nelze vzít zpátky. Opravdu chcete smazat tento záznam?')
            },
            confirm(type, message) {                
                this.confirmType = type
                this.confirmMessage = message
                this.showConfirmBox = true
            },
            async closeConfirmBox(dialogResult, dialogType) {
                this.showConfirmBox = false
                this.confirmType = -1

                if (dialogResult == 1) {
                    switch (dialogType) {
                        case 1:
                            await api.deleteAdmin(this.deleteId)
                            this.deleteId = -1
                            this.updateTable()
                            break

                        case 2:
                            this.saveAndCloseCard()
                            break
                    }
                }
                else {
                    switch (dialogType) {
                        case 2:
                            this.closeCard()
                            break
                    }
                }
            },
            message(message) {
                this.messageText = message
                this.showMessageBox = true
            },
            async closeMessageBox() {
                this.showMessageBox = false
            }
        },
        created() {
            this.updateTable()
        }
    }
</script>