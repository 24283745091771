<template>
    <div v-if="!showCard">
        <button class="button-small" @click="newSchool()">Nový záznam</button>
        <br />
        <table style="margin-top: 20px;">
            <thead>
                <tr>
                    <th v-for="(h, k) in headers" :key="k" @click="sort(k)">{{ h }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, i) in sortedRows" :key="i" >
                    <td v-for="(header, j) in headerKeys" :key="j" @click="openCard(row.id)">
                        <span v-if="header === 'adresa'">{{ row.ulice + ', ' + row.mesto + ', ' + row.psc }}</span>
                        <span v-else-if="header === 'sablony'">
                            <span v-if="row.template1">1&nbsp;</span>
                            <span v-if="row.template2">2&nbsp;</span>
                            <span v-if="row.template3">3&nbsp;</span>
                            <span v-if="row.template4">4&nbsp;</span>
                        </span>
                        <span v-else-if="header === 'koordinatori'">
                            <span v-for="(k, m) in row.koordinatori" :key="m">
                                {{ k.jmeno }}
                                <span v-if="m < row.koordinatori.length - 1">, </span>
                            </span>                            
                        </span>
                        <span v-else>{{ row[header] }}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div v-if="showCard">
        <div class="cols" style="gap: 5px;">
            <button class="button-small" @click="closeCard(true)">Zavřít</button>
            <button class="button-small" @click="saveCard()">Uložit</button>
            <button class="button-small" @click="saveAndCloseCard()">Uložit a zavřít</button>
        </div>

        <table class="table-border-none" style="margin-top: 20px;">
            <tr>
                <td style="padding-left: 0;">Název:</td>
                <td><input v-model="selectedSchool.nazev" style="width: 200px;" /><span class="required">*</span></td>
            </tr>
            <tr>
                <td style="padding-left: 0;">Typ:</td>
                <td>
                    <select v-model="selectedSchool.typ" style="width: 200px;">
                        <option :value="-1">&lt; vyberte &gt;</option>
                        <option v-for="(t, i) in sortedTyp" :key="i" :value="t.id">{{ t.nazev }}</option>
                    </select>
                </td>
            </tr>
            <tr>
                <td style="padding-left: 0;">IČO:</td>
                <td><input v-model="selectedSchool.ico" style="width: 200px;" /></td>
            </tr>
            <tr>
                <td style="padding-left: 0;">Ulice:</td>
                <td><input v-model="selectedSchool.ulice" style="width: 200px;" /></td>
            </tr>
            <tr>
                <td style="padding-left: 0;">Město:</td>
                <td><input v-model="selectedSchool.mesto" style="width: 200px;" /></td>
            </tr>
            <tr>
                <td style="padding-left: 0;">PSČ:</td>
                <td><input v-model="selectedSchool.psc" style="width: 200px;" /></td>
            </tr>
            <tr>
                <td style="padding-left: 0;">E-mail:</td>
                <td><input v-model="selectedSchool.email" style="width: 200px;" /></td>
            </tr>
            <tr>
                <td style="padding-left: 0;">Telefon:</td>
                <td><input v-model="selectedSchool.telefon" style="width: 200px;" /></td>
            </tr>
            <tr>
                <td style="padding-left: 0;">Ředitel:</td>
                <td>
                    <select v-model="selectedSchool.reditel" style="width: 200px;">
                        <option :value="-1">&lt; vyberte &gt;</option>
                        <option v-for="(r, i) in sortedUcitel" :key="i" :value="r.id">{{ r.prijmeni + ' ' + r.jmeno }}</option>
                    </select>
                </td>
            </tr>
            <tr>
                <td style="padding-left: 0; vertical-align: top;">Koordinátoři:</td>
                <td>
                    <select v-model="this.koordinator" style="width: 200px;">
                        <option :value="-1">&lt; vyberte &gt;</option>
                        <option v-for="(r, i) in sortedUcitel" :key="i" :value="r.id">{{ r.prijmeni + ' ' + r.jmeno }}</option>
                    </select>
                    <span @click="addItem" class="select-list-button-inverse" style="margin-left: 2px;">+</span>
                    <div class="selectList">
                        <div v-for="(ko, i) in this.selectedSchool.koordinatori" :key="i" class="selectListItem">
                            <span @click="removeItem(ko.idUcitel)" class="select-list-button-inverse">-</span>
                            {{ ko.jmeno }}
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td style="padding-left: 0; vertical-align: top;">Šablony:</td>
                <td>
                    <div style="text-align: center; float: left;">
                        1<br />
                        <input type="checkbox" v-model="selectedSchool.template1" />
                    </div>
                    <div style="text-align: center; float: left;">
                        2<br />
                        <input type="checkbox" v-model="selectedSchool.template2" />
                    </div>
                    <div style="text-align: center; float: left;">
                        3<br />
                        <input type="checkbox" v-model="selectedSchool.template3" />
                    </div>
                    <div style="text-align: center; float: left;">
                        4<br />
                        <input type="checkbox" v-model="selectedSchool.template4" />
                    </div>
                </td>
            </tr>
            <tr>
                <td style="padding-left: 0;">Oficialní název:</td>
                <td><input v-model="selectedSchool.oficialniNazev" style="width: 200px;" /></td>
            </tr>
            <tr>
                <td style="padding-left: 0;">Číslo projektu:</td>
                <td><input v-model="selectedSchool.cisloProjektu" style="width: 200px;" /></td>
            </tr>
            <tr>
                <td style="padding-left: 0;">Název projektu:</td>
                <td><input v-model="selectedSchool.nazevProjektu" style="width: 200px;" /></td>
            </tr>
        </table>
    </div>

    <ConfirmBoxComponent v-if="showConfirmBox" :message="confirmMessage" :dialogType="confirmType" @close-event="closeConfirmBox" />
    <MessageBoxComponent v-if="showMessageBox" :message="messageText" @close-event="closeMessageBox" />
</template>

<script>
    import api from '@/api/api.js'

    import ConfirmBoxComponent from '../dialogs/ConfirmBoxComponent.vue'
    import MessageBoxComponent from '../dialogs/MessageBoxComponent.vue'

    export default {
        name: 'SchoolsComponent',
        components: {
            ConfirmBoxComponent,
            MessageBoxComponent
        },
        emits: ['show-saving-message'],
        props: [],
        data() {
            return {
                headers: {},
                rows: [],
                typ: [],
                reditel: [],
                currentSort: '',
                currentSortDir: 'asc',
                selectedSchool: {},
                showCard: false,
                koordinator: -1,

                showConfirmBox: false,
                confirmMessage: "",
                confirmType: -1,
                showMessageBox: false,
                messageText: ""
            }
        },
        computed: {
            headerKeys() {
                return Object.keys(this.headers)
            },
            sortedRows() {
                if (this.currentSort === '')
                    return this.rows
                return this.rows.slice().sort(
                    (a, b) => this.currentSortDir === 'desc' 
                        ? (b[this.currentSort] === null || b[this.currentSort] === undefined ? -1 : (a[this.currentSort] === null || a[this.currentSort] === undefined ? 1 : b[this.currentSort].localeCompare(a[this.currentSort], 'cz'))) 
                        : (a[this.currentSort] === null || a[this.currentSort] === undefined ? -1 : (b[this.currentSort] === null || b[this.currentSort] === undefined ? 1 : a[this.currentSort].localeCompare(b[this.currentSort], 'cz'))))
            },
            sortedTyp() {
                return this.typ
                    .slice()
                    .sort((a, b) => (a.nazev).localeCompare(b.nazev, 'cz'))
            },
            sortedUcitel() {
                return this.reditel
                    .slice()
                    .sort((a, b) => (a.prijmeni + ' ' + a.jmeno).localeCompare(b.prijmeni + ' ' + b.jmeno, 'cz'))
            }
        },
        methods: {
            showSavingDataMessage(value) {
                this.$emit('show-saving-message', value)
            },
            async load() {
                try {
                    let response = await api.getTyp()
                    this.typ = response.data
                }
                catch (error) {
                    console.log('Error: ', error)
                }
            },
            async updateTable() {
                this.headers = { "id": "ID", "nazev": "Název", "typZkratka": "Typ", "ico": "IČO", "adresa": "Adresa", "email": "E-mail", "telefon": "Telefon", "reditelJmeno": "Ředitel", 
                    "koordinatori": "Koordinátoři", "sablony": "Šablony" }

                try {
                    let response = await api.getSchool()
                    this.rows = response.data
                }
                catch (error) {
                    console.log('Error: ', error)
                }
            },
            sort(s) {
                if (s === this.currentSort) {
                    this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc'
                }
                else {
                    this.currentSort = s
                    this.currentSortDir = 'asc'
                }
            },
            async openCard(id) {
                this.selectedSchool = this.rows.filter(a => a.id == id).slice()[0]
                
                try {
                    let response = await api.getTeacher(this.selectedSchool.id)
                    this.reditel = response.data
                }
                catch (error) {
                    console.log('Error: ', error)
                    this.reditel = []
                }

                this.showCard = true
            },
            closeCard(ask = false)
            {
                if (ask)
                    this.confirm(1, 'Uložit změny?')
                else {
                    this.showCard = false
                    this.koordinator = -1
                    this.selectedSchool = {}
                    this.updateTable()
                }
            },
            async saveCard()
            {
                if (this.selectedSchool.nazev.length > 0) {
                    try {
                        this.showSavingDataMessage(true)
                        if (this.selectedSchool.id !== -1)
                            await api.putSchool(this.selectedSchool)
                        else {
                            let response = await api.postSchool(this.selectedSchool)
                            if (response.status == 200)
                            this.selectedSchool.id = response.data
                        }
                        this.showSavingDataMessage(false)
                    }
                    catch (error) {
                        this.showSavingDataMessage(false)
                        console.log('Error: ', error)
                        this.message("Nastala chyba při ukládání dat")
                    }
                }
                else{
                    alert('Vyplňte všechny povinné položky!')
                    return false
                }
                return true
            },
            async saveAndCloseCard()
            {
                if (await this.saveCard())
                    this.closeCard()
            },
            newSchool() {
                this.reditel = []
                this.selectedSchool = { id: -1, typ: -1, typZkratka: '', nazev: '', ulice: '', mesto: '', psc: '', email: '', telefon: '', reditel: -1, reditelJmeno: '', ico: '', oficialniNazev: '', cisloProjektu: '', nazevProjektu: '' }
                this.showCard = true
            },
            removeItem(id) {
                this.selectedSchool.koordinatori = this.selectedSchool.koordinatori.filter(ko => ko.idUcitel !== id)
            },
            addItem() {
                if (this.koordinator === -1) return
                if (this.selectedSchool.koordinatori.filter(ko => ko.idUcitel === this.koordinator).length > 0) return
                let temp = this.reditel.filter(r => r.id === this.koordinator)[0]
                this.selectedSchool.koordinatori.push({ id: -1, idSkola: this.selectedSchool.id, idUcitel: temp.id, idUzivatel: -1, jmeno: temp.prijmeni + ' ' + temp.jmeno })
            },
            confirm(type, message) {                
                this.confirmType = type
                this.confirmMessage = message
                this.showConfirmBox = true
            },
            async closeConfirmBox(dialogResult, dialogType) {
                this.showConfirmBox = false
                this.confirmType = -1

                if (dialogResult == 1) {
                    switch (dialogType) {
                        case 1:
                            this.saveAndCloseCard()
                            break
                    }
                }
                else {
                    switch (dialogType) {
                        case 1:
                            this.closeCard()
                            break
                    }
                }
            },
            message(message) {
                this.messageText = message
                this.showMessageBox = true
            },
            async closeMessageBox() {
                this.showMessageBox = false
            }
        },
        async created() {
            await this.load()
            this.updateTable()
        }
    }
</script>