<template>
    <div class="rows" style="gap: 20px;">
        <div class="cols" style="gap: 5px;">
            <button class="button-small" @click="close(true)">Zavřít</button>
            <button v-if="support.stav <= 1" class="button-small" @click="save()">Uložit</button>
            <button v-if="support.stav <= 1" class="button-small" @click="saveAndClose()">Uložit a zavřít</button>
            <button v-if="support.stav == 1 && !isCoordinator" class="button-small" @click="sendToApprove()" style="margin-left: 20px;">Předat ke schválení</button>
            <button v-if="support.stav == 1 && isCoordinator" class="button-small" @click="sendToApproveAdmin()" style="margin-left: 20px;">Předat ke schválení</button>
        </div>

        <div>
            <p>
                <b>Pokyny k vyplnění</b>
                <br />
                Do záložky <b>Základní údaje</b> vyplňte název skupiny kurzů, obvykle se uvádí 1. monitorovací zpráva (2., 3...).
                <br />
                Na záložce <b>Kurzy</b> zadáváte učitele a případně nepedagogické pracovníky, kteří se účastní kurzů. Můžete zadat 1 řádek nebo 5 řádků. V případě, že se kurzu účastnilo více pracovníků, můžete zvolit přidání řádků s kopírováním údajů ‒ POZOR, nejprve vyplňte první řádek, kde uvedete <i>Název kurzu</i>, <i>Realizaci od-do</i> (v případě jednodenního kurzu uveďte 2x stejné datum), <i>Počet hodin</i> (vždy celé číslo), zaškrtněte případně variantu <i>On-line</i>, ze seznamu vyberte <i>Formu kurzu</i> a <i>Téma kurzu</i>. Poté zadáte další řádky s kopírováním údajů a následně vyberete jména učitelů a doplníte jejich data narození.
                <br />
                <b>Přílohy</b>: Na konci řádku je vždy seznam požadovaných příloh ‒ u akreditovaných kurzů se přikládají osvědčení, u neakreditovaných kurzů je přílohou osvědčení/potvrzení o účasti a faktura. V případě realizace on-line se navíc dokládají printscreeny. V případě realizace stáže, mentoringu, supervize nebo koučinku kontaktujte ohledně příloh administrátora.
            </p>
        </div>

        <div class="cols" style="gap: 5px; margin-top: 50px; margin-bottom: 20px;">
            <button :class="'button-small' + (!this.showDefault ? ' button-inverse' : '')" @click="changeTabPage(0)">Základní údaje</button>
            <button :class="'button-small' + (!this.showCourses ? ' button-inverse' : '')" @click="changeTabPage(1)">Kurzy</button>
            <button v-if="this.support.id !== -1" :class="'button-small' + (!this.showAttachements ? ' button-inverse' : '')" @click="changeTabPage(2)">Přílohy</button>
        </div>

        <div v-if="showDefault">
            <table class="table-border-none">
                <tr>
                    <td style="padding-left: 0;">Název:</td>
                    <td style="padding-right: 5px;"><input v-model="support.nazev" style="width: 200px;" /><span class="required">*</span></td>
                </tr>
            </table>
        </div>

        <div v-if="showCourses">
            <input type="file" class="hidden-input" multiple name="fileInputInline" id="fileInputInline" @change="onChangeFileInputInline" ref="fileInputInline" accept=".doc,.docx,.pdf,.jpg,.jpeg,.png,.tif,.tiff,.xls,.xlsx" />
            
            <table class="table-border-none" style="border-collapse: separate; border-spacing: 0 20px;">
                <tr>
                    <th>&nbsp;</th>
                    <th>Nepedagog</th>
                    <th>Pracovník</th>
                    <th>Dat. nar.</th>
                    <th>Název kurzu</th>
                    <th>Navazující<br />kurz</th>
                    <th>Realizace<br />od - do</th>
                    <th>Počet hodin</th>
                    <th>On-line</th>
                    <th>Forma</th>
                    <th>Téma</th>
                    <th>Společné<br />přílohy</th>
                    <th>Počet<br />příloh</th>
                    <th colspan="2">Přílohy</th>
                    <th>Dat. kontroly</th>
                </tr>
                <tr v-for="(k, i) in this.support.data.kurzy" :key="i">
                    <td style="vertical-align: top;"><span @click="removeCourse(i)" class="select-list-button-inverse">-</span>&nbsp;&nbsp;<span>{{ i + 1 }}</span></td>
                    <td style="vertical-align: top; text-align: center;">
                        <input type="checkbox" v-model="k.nepedagog" />
                    </td>
                    <td style="vertical-align: top;">
                        <select v-if="!k.nepedagog" v-model="k.idUcitel" @change="changeBirthday(i)" style="width: 200px;">
                            <option :value="-1">&lt; vyberte &gt;</option>
                            <option v-for="(s, m) in sortedPracovnik" :key="m" :value="s.id">{{ s.prijmeni + ' ' + s.jmeno }}</option>
                        </select>
                        
                        <input v-if="k.nepedagog" v-model="k.nepedagogJmeno" placeholder="Příjmení Jméno" style="width: 200px;" />
                    </td>
                    <td style="vertical-align: top;">
                        <VueDatePicker
                            v-model="k.datumNarozeni"
                            locale="cs"
                            :auto-apply="true"
                            :enable-time-picker="false"
                            :hide-input-icon="true"
                            :text-input="true"
                            format="d.M.yyyy"
                            timezone="UTC"
                            utc="preserve"
                            class="datepicker"
                            six-weeks="append"
                        />
                    </td>
                    <td style="vertical-align: top;">
                        <input v-model="k.nazev" style="width: 200px;" />
                    </td>
                    <td style="vertical-align: top;">
                        <input type="checkbox" v-model="k.navazujiciKurz" />
                    </td>
                    <td style="vertical-align: top;">
                        <VueDatePicker
                            v-model="k.realizaceOd"
                            locale="cs"
                            :auto-apply="true"
                            :enable-time-picker="false"
                            :hide-input-icon="true"
                            :text-input="true"
                            format="d.M.yyyy"
                            timezone="UTC"
                            utc="preserve"
                            class="datepicker"
                            six-weeks="append"
                        />
                        <VueDatePicker
                            v-model="k.realizaceDo"
                            locale="cs"
                            :auto-apply="true"
                            :enable-time-picker="false"
                            :hide-input-icon="true"
                            :text-input="true"
                            format="d.M.yyyy"
                            timezone="UTC"
                            utc="preserve"
                            class="datepicker"
                            six-weeks="append"
                        />
                    </td>
                    <td style="vertical-align: top;">
                        <input type="number" class="number-without-spinner" oninput="this.value = this.value.replace(/[^0-9]/g, '');" v-model="k.pocetHodin" style="width: 50px; text-align: right;" />
                    </td>
                    <td style="vertical-align: top; text-align: center;">
                        <input type="checkbox" v-model="k.online" />
                    </td>
                    <td style="vertical-align: top;">
                        <select v-model="k.forma" style="width: 200px;">
                            <option value="">&lt; vyberte &gt;</option>
                            <option value="akreditovaný kurz průběžné DVPP">akreditovaný kurz průběžné DVPP</option>
                            <option value="neakreditovaný kurz">neakreditovaný kurz</option>
                            <option value="kvalifikační_studium_DVPP">kvalifikační_studium_DVPP</option>
                            <option value="akreditovaný kurz jiný">akreditovaný kurz jiný</option>
                            <option value="stáž">stáž</option>
                            <option value="mentoring">mentoring</option>
                            <option value="supevize">supervize</option>
                            <option value="koučink">koučink</option>
                        </select>
                    </td>
                    <td style="vertical-align: top;">
                        <select v-model="k.tema" style="width: 200px;">
                            <option value="">&lt; vyberte &gt;</option>
                            <option value="umělecká gramotnost">umělecká gramotnost</option>
                            <option value="kulturní povědomí a vyjádření">kulturní povědomí a vyjádření</option>
                            <option value="řízení organizace, leadership a řízení pedagogického procesu">řízení organizace, leadership a řízení pedagogického procesu</option>
                            <option value="cizí jazyky/komunikace v cizím jazyce">cizí jazyky/komunikace v cizím jazyce</option>
                            <option value="profesní rozvoj ostatních pracovníků ve vzdělávání">profesní rozvoj ostatních pracovníků ve vzdělávání</option>
                            <option value="alternativní/inovativní formy výuky">alternativní/inovativní formy výuky</option>
                            <option value="čtenářská gramotnost">čtenářská gramotnost</option>
                            <option value="EVVO včetně vzdělávání pro udržitelný rozvoj">EVVO včetně vzdělávání pro udržitelný rozvoj</option>
                            <option value="formativní hodnocení">formativní hodnocení</option>
                            <option value="genderová tematika v obsahu vzdělávání">genderová tematika v obsahu vzdělávání</option>
                            <option value="historické povědomí, výuka moderních dějin">historické povědomí, výuka moderních dějin</option>
                            <option value="individualizace vzdělávání a vedení portfolia dítěte/žáka/studenta">individualizace vzdělávání a vedení portfolia dítěte/žáka/studenta</option>
                            <option value="inkluze">inkluze</option>
                            <option value="inovace ŠVP/ŠAP/Koncepce rozvoje školy/školského zařízení">inovace ŠVP/ŠAP/Koncepce rozvoje školy/školského zařízení</option>
                            <option value="kariérové poradenství včetně identifikace a rozvoje nadání">kariérové poradenství včetně identifikace a rozvoje nadání</option>
                            <option value="matematická gramotnost">matematická gramotnost</option>
                            <option value="mediální gramotnost, prevence kyberšikany, chování na sociálních sítích">mediální gramotnost, prevence kyberšikany, chování na sociálních sítích</option>
                            <option value="občanské vzdělávání a demokratické myšlení">občanské vzdělávání a demokratické myšlení</option>
                            <option value="pedagogická diagnostika">pedagogická diagnostika</option>
                            <option value="podpora uvádějících/provázejících učitelů">podpora uvádějících/provázejících učitelů</option>
                            <option value="projektová výuka">projektová výuka</option>
                            <option value="propojování formálního a neformálního vzdělávání">propojování formálního a neformálního vzdělávání</option>
                            <option value="přírodovědné a technické vzdělávání">přírodovědné a technické vzdělávání</option>
                            <option value="rozvoj podnikavosti a kreativity">rozvoj podnikavosti a kreativity</option>
                            <option value="spolupráce s rodiči a zákonnými zástupci dětí a žáků">spolupráce s rodiči a zákonnými zástupci dětí a žáků</option>
                            <option value="vzdělávání s využitím nových technologií">vzdělávání s využitím nových technologií</option>
                            <option value="well-being a psychohygiena">well-being a psychohygiena</option>
                            <option value="zážitková pedagogika">zážitková pedagogika</option>
                        </select>
                    </td>
                    <td style="vertical-align: top; text-align: center;">
                        <input type="checkbox" v-model="k.spolecnePrilohy" />
                    </td>
                    <td style="vertical-align: top; text-align: center;">
                        {{ this.attachements.filter(f => f.nazev.startsWith(this.getFilePrefix(i))).length }}
                    </td>
                    <td style="vertical-align: top;">{{ getAttachementsText(i) }}</td>
                    <td style="vertical-align: top;"><button @click="addAttachement(i)" class="button-small button-inverse">Nahrát</button></td>
                    <td style="vertical-align: top;">
                        <VueDatePicker
                            v-model="k.datumKontroly"
                            locale="cs"
                            :auto-apply="true"
                            :enable-time-picker="false"
                            :hide-input-icon="true"
                            :text-input="true"
                            format="d.M.yyyy"
                            timezone="UTC"
                            utc="preserve"
                            class="datepicker"
                            six-weeks="append"
                        />
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <span @click="addCourse(1, false)" class="select-list-button-wide">+ 1</span>&nbsp;<span @click="addCourse(5, false)" class="select-list-button-wide">+ 5</span>
                        <br />
                        <span @click="addCourse(1, true)" class="select-list-button-wide">+ 1 K</span>&nbsp;<span @click="addCourse(5, true)" class="select-list-button-wide">+ 5 K</span>
                    </td>
                    <td colspan="11">&nbsp;</td>
                </tr>
            </table>
        </div>

        <div v-if="showAttachements">
            <FilesComponent :path="idSchool + '/sablony/1/' + selectedSupport" :prefix="getFilePrefix(-1)" />
        </div>
        
        <div v-if="showCourses" class="cols" style="gap: 5px; margin-top: 50px; margin-bottom: 20px;">
            <button class="button-small" @click="close(true)">Zavřít</button>
            <button v-if="support.stav <= 1" class="button-small" @click="save()">Uložit</button>
            <button v-if="support.stav <= 1" class="button-small" @click="saveAndClose()">Uložit a zavřít</button>
        </div>
    </div>
         
    <MessageBoxComponent v-if="showMessageBox" :message="messageText" @close-event="closeMessageBox" />       
    <ConfirmBoxComponent v-if="showConfirmBox" :message="confirmMessage" :dialogType="confirmType" @close-event="closeConfirmBox" />
</template>

<script>
    import api from '@/api/api.js'
    import VueDatePicker from '@vuepic/vue-datepicker'
    import '@vuepic/vue-datepicker/dist/main.css'
    import dayjs from 'dayjs'
    import utc from 'dayjs/plugin/utc'
    dayjs.extend(utc)
    
    import MessageBoxComponent from '../dialogs/MessageBoxComponent.vue'
    import ConfirmBoxComponent from '../dialogs/ConfirmBoxComponent.vue'
    import FilesComponent from '../files/FilesComponent.vue'

    export default {
        name: 'SupportComponent',
        emits: ['show-saving-message', 'close-card'],
        props: ['selectedSupport', 'idUser', 'idSchool', 'isCoordinator'],
        expose: ['save'],
        components: {
            VueDatePicker,
            MessageBoxComponent,
            ConfirmBoxComponent,
            FilesComponent
        },
        data() {
            return {
                showDefault: true,
                showCourses: false,
                showAttachements: false,
                support: {
                    id: -1,
                    nazev: '',
                    idSkola: -1,
                    idUcitel: -1,
                    upravil: '',
                    stav: 0,
                    data: {
                        nazev: '',
                        kurzy: []
                    }
                },
                pracovnici: [],
                showMessageBox: false,
                messageText: "",
                showConfirmBox: false,
                confirmMessage: "",
                confirmType: -1,
                removeIndex: -1,
                filePrefix: '',                
                files: [],
                attachements: []
            }
        },
        computed: {
            sortedPracovnik() {
                return this.pracovnici
                    .slice()
                    .sort((a, b) => (a.prijmeni + ' ' + a.jmeno).localeCompare(b.prijmeni + ' ' + b.jmeno, 'cz'))
            },
            getFilePrefix() {
                return (id) => {
                    let nazev = ''
                    let jmeno = ''
                    let forma = ''

                    if (id != -1) {
                        nazev = this.support.data.kurzy[id].nazev.replace(/ /g, '_')
                        nazev = nazev.length > 20 ? nazev.slice(0, 17) + "…" : nazev

                        if (!this.support.data.kurzy[id].spolecnePrilohy) {
                            if (!this.support.data.kurzy[id].nepedagog) {
                                if (this.support.data.kurzy[id].idUcitel != -1) {
                                    let p = this.pracovnici.filter(a => a.id == this.support.data.kurzy[id].idUcitel).slice()[0]
                                    jmeno = '_' + p.prijmeni + '_' + p.jmeno
                                }
                            }
                            else {
                                jmeno = '_' + this.support.data.kurzy[id].nepedagogJmeno.replace(/ /g, '_')
                            }
                        }
                        else {
                            jmeno = '_společná_příloha'
                        }

                        switch (this.support.data.kurzy[id].forma) {
                            case 'akreditovaný kurz průběžné DVPP':
                            case 'akreditovaný kurz jiný':
                                forma = '_akredit'
                                break;
                            case 'neakreditovaný kurz':
                                forma = '_neakredit'
                                break;
                            case 'kvalifikační_studium_DVPP':
                                forma = '_studium'
                                break;
                            case 'stáž':
                                forma = '_stáž'
                                break;
                            case 'mentoring':
                                forma = '_mentoring'
                                break;
                            case 'supevize':
                                forma = '_supervize'
                                break;
                            case 'koučink':
                                forma = '_koučink'
                                break;
                        }
                    }

                    return '1.' + this.support.nazev.slice(0, 1) + '_' + (id != -1 ? (nazev + forma + jmeno + '_') : '')
                }
            }
        },
        methods: {
            showSavingDataMessage(value) {
                this.$emit('show-saving-message', value)
            },
            async load() {
                this.support.id = this.selectedSupport

                try {
                    let response = await api.getTeacher(this.idSchool)
                    this.pracovnici = response.data
                }
                catch (error) {
                    console.log('Error: ', error)
                    this.reditel = []
                }

                if (this.support.id == -1) {
                    try {
                        let response = await api.getTeacherByUserId(this.idUser, this.idSchool)
                        this.support.idSkola = this.idSchool
                        this.support.idUcitel = response.data.id
                        this.support.upravil = response.data.prijmeni + ' ' + response.data.jmeno
                    }
                    catch (error) {
                        console.log('Error: ', error)
                    }
                }
                else {
                    try {
                        let response = await api.getSupport(this.selectedSupport)
                        this.support = response.data
                        await this.loadAttachements()
                    }
                    catch (error) {
                        console.log('Error: ', error)
                    }
                }
            },
            async loadAttachements() {
                try {
                    let response = await api.getFiles(this.idSchool + '/sablony/1/' + this.selectedSupport)
                    this.attachements = response.data
                }
                catch (error) {
                    console.log('Error: ', error)
                }
            },
            formatDate(date) {
                return date !== null ? dayjs(date).utcOffset(0).format('D.M.YYYY') : ''
            },
            changeBirthday(index) {
                if (this.support.data.kurzy[index].idUcitel != -1)
                    this.support.data.kurzy[index].datumNarozeni = this.pracovnici.filter(a => a.id == this.support.data.kurzy[index].idUcitel).slice()[0].datumNarozeni + 'Z'
            },
            async save() {
                if (this.support.nazev.length > 0) {
                    try {
                        this.showSavingDataMessage(true)

                        this.support.data.nazev = this.support.nazev
                        for (let i = 0; i < this.support.data.kurzy.length; i++) {
                            // validate hours
                            if (this.support.data.kurzy[i].pocetHodin == "")
                                this.support.data.kurzy[i].pocetHodin = 0
                            if (this.support.data.kurzy[i].pocetHodin > 999)
                                this.support.data.kurzy[i].pocetHodin = 999
                            // end validate

                            if (this.support.data.kurzy[i].idUcitel == -1) {
                                this.support.data.kurzy[i].prijmeni = ""
                                this.support.data.kurzy[i].jmeno = ""
                            }
                            else {
                                let p = this.pracovnici.filter(a => a.id == this.support.data.kurzy[i].idUcitel).slice()[0]
                                this.support.data.kurzy[i].prijmeni = p.prijmeni
                                this.support.data.kurzy[i].jmeno = p.jmeno
                            }
                        }

                        if (this.support.id !== -1) {
                            await api.putSupport(this.support)
                        }
                        else {
                            let response = await api.postSupport(this.support)
                            if (response.status == 200)
                                this.support.id = response.data
                        }

                        this.showSavingDataMessage(false)
                    }
                    catch (error) {
                        this.showSavingDataMessage(false)
                        console.log('Error: ', error)
                        this.message("Nastala chyba při ukládání dat")
                        return false
                    }
                }
                else{
                    this.message('Vyplňte všechny povinné položky!')
                    return false
                }
                return true
            },
            close(ask = false) {
                if (ask)
                    this.confirm(4, 'Uložit změny?')
                else
                    this.$emit('close-card')
            },
            async saveAndClose() {
                if (await this.save())
                    this.close()
            },
            async sendToApprove() {
                if (await this.save())
                    this.confirm(1, 'Šablona bude odeslána ke schválení koordinátorovi. Pokračovat?')
            },
            async sendToApproveAdmin() {
                if (await this.save())
                    this.confirm(2, 'Šablona bude odeslána ke schválení administrátorovi. Pokračovat?')
            },
            addCourse(count, copyPrevious) {
                let n = ""
                let navazujici = false
                let rOd = null
                let rDo = null
                let ph = 0
                let o = false
                let f = ""
                let t = ""
                let sp = false
                let index = this.support.data.kurzy.length - 1

                for (let c = 0; c < count; c++) {
                    if (copyPrevious && index >= 0) {
                        n = this.support.data.kurzy[index].nazev
                        navazujici = this.support.data.kurzy[index].navazujiciKurz
                        rOd = this.support.data.kurzy[index].realizaceOd
                        rDo = this.support.data.kurzy[index].realizaceDo
                        ph = this.support.data.kurzy[index].pocetHodin
                        o = this.support.data.kurzy[index].online
                        f = this.support.data.kurzy[index].forma
                        t = this.support.data.kurzy[index].tema
                        sp = this.support.data.kurzy[index].spolecnePrilohy
                    }

                    this.support.data.kurzy.push({ idUcitel: -1, prijmeni: "", jmeno: "", nepedagog: false, nepedagogJmeno: "", datumNarozeni: null, nazev: n, navazujiciKurz: navazujici, realizaceOd: rOd,
                        realizaceDo: rDo, pocetHodin: ph, online: o, forma: f, tema: t, spolecnePrilohy: sp, datumKontroly: null })
                }
            },
            removeCourse(index) {
                this.removeIndex = index
                this.confirm(3, 'Chcete smazat kurz?')
            },
            async changeTabPage(page) {
                this.showDefault = false
                this.showCourses = false
                this.showAttachements = false

                switch (page) {
                    case 1:
                        await this.loadAttachements()
                        this.showCourses = true
                        break
                    case 2:
                        this.showAttachements = true
                        break
                    default:
                        this.showDefault = true
                        break
                }
            },
            getAttachementsText(index) {
                let text = 'Seznam požadovaných příloh: Osvědčení/potvrzení o účasti'

                if (this.support.data.kurzy[index].online)
                    text += ', Printscreen'
                
                switch (this.support.data.kurzy[index].forma) {
                    case 'neakreditovaný kurz':
                        text += ', Faktura'
                        break
                    case 'stáž':
                    case 'mentoring':
                    case 'supevize':
                    case 'koučink':
                        text = 'Přílohy konzultujte s administrátorem'
                        break
                }

                return text
            },
            message(message) {
                this.messageText = message
                this.showMessageBox = true
            },
            async closeMessageBox() {
                this.showMessageBox = false
            },
            confirm(type, message) {                
                this.confirmType = type
                this.confirmMessage = message
                this.showConfirmBox = true
            },
            async closeConfirmBox(dialogResult, dialogType) {
                this.showConfirmBox = false
                this.confirmType = -1

                if (dialogResult == 1) {
                    switch (dialogType) {
                        case 1:
                            await api.sendSupportToApproveByCoordinator(this.support.id)
                            this.close()
                            break

                        case 2:
                            await api.sendSupportToApproveByAdmin(this.support.id)
                            this.close()
                            break
                            
                        case 3:
                            this.support.data.kurzy.splice(this.removeIndex, 1)
                            this.removeIndex = -1
                            break

                        case 4:
                            this.saveAndClose()
                            break
                    }
                }
                else {
                    switch (dialogType) {
                        case 4:
                            this.close()
                            break
                    }
                }
            },
            addAttachement(id) {
                this.filePrefix = this.getFilePrefix(id)
                this.$refs.fileInputInline.click()
            },
            async onChangeFileInputInline() {
                this.files = []
                this.files.push(...this.$refs.fileInputInline.files)
                await this.uploadFiles()
            },
            async uploadFiles() {
                const files = this.files
                const formData = new FormData()
                files.forEach((file) => {
                    formData.append("selectedFiles", file);
                })

                try {
                    await api.uploadFiles(formData, this.idSchool + '/sablony/1/' + this.selectedSupport, this.filePrefix)
                }
                catch (error) {
                    console.log("Error: ", error)
                }

                this.files = []

                await this.loadAttachements()
            }
        },
        async created() {
            await this.load()
        }
    }
</script>