<template>
    <div class="cols">
        <div class="rows gap-30">
            <select v-model="selectedSchool" style="width: 200px;">
                <option :value="-1">&lt; vyberte školu &gt;</option>
                <option v-for="(t, i) in sortedSkola" :key="i" :value="t.id">{{ t.nazev }}</option>
            </select>

            <div class="cols" style="gap: 5px;">
                <button v-if="showTemplate1" :class="'button-small' + (!this.showSupport ? ' button-inverse' : '')" @click="changeTabPage(1)">Šablona 1</button>
                <button v-if="showTemplate2" :class="'button-small' + (!this.showCooperation ? ' button-inverse' : '')" @click="changeTabPage(2)">Šablona 2</button>
                <button v-if="showTemplate3" :class="'button-small' + (!this.showAttendance ? ' button-inverse' : '')" @click="changeTabPage(3)">Šablona 3</button>
            </div>

            <div v-if="showTemplate1 && showSupport">
                <div v-if="!showSupportCard">
                    <table style="margin-top: 20px;">
                        <thead>
                            <tr>
                                <th v-for="(h, k) in headersSupport" :key="k" @click="sortSupport(k)">{{ h }}</th>
                                <th v-if="rowsSupport.length > 0 && rowsSupport.filter(a => a.stav == 4).length > 0">&nbsp;</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, i) in rowsSupport" :key="i" >
                                <td v-for="(header, j) in headerKeysSupport" :key="j" @click="openSupport(row.id)">
                                    <span>{{ row[header] }}</span>
                                </td>
                                <td v-if="row.stav == 4"><button class="button-small button-inverse" @click="returnSupport(row.id)" style="margin-left: 20px;">Vrátit</button></td>
                                <td><button class="button-small button-inverse" @click="deleteSupport(row.id)" style="margin-left: 20px;">Smazat</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
                <SupportReadonlyComponent v-if="showSupportCard" :selectedSupport="selectedSupport" :isAdmin="true" @close-card="closeSupportCard" />
            </div>

            <div v-if="showTemplate2 && showCooperation">
                <div v-if="!showCooperationCard">
                    <table style="margin-top: 20px;">
                        <thead>
                            <tr>
                                <th v-for="(h, k) in headersCooperation" :key="k" @click="sortCooperation(k)">{{ h }}</th>
                                <th v-if="rowsCooperation.length > 0 && rowsCooperation.filter(a => a.stav == 4).length > 0">&nbsp;</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, i) in rowsCooperation" :key="i" >
                                <td v-for="(header, j) in headerKeysCooperation" :key="j" @click="openCooperation(row.id)">
                                    <span>{{ row[header] }}</span>
                                </td>
                                <td v-if="row.stav == 4"><button class="button-small button-inverse" @click="returnCooperation(row.id)" style="margin-left: 20px;">Vrátit</button></td>
                                <td><button class="button-small button-inverse" @click="deleteCooperation(row.id)" style="margin-left: 20px;">Smazat</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
                <CooperationReadonlyComponent v-if="showCooperationCard" :selectedCooperation="selectedCooperation" :isAdmin="true" @close-card="closeCooperationCard" @show-document-generation-message="showDocumentGenerationMessage" />
            </div>

            <div v-if="showTemplate3 && showAttendance">
                <div v-if="!showAttendanceCard">
                    <table style="margin-top: 20px;">
                        <thead>
                            <tr>
                                <th v-for="(h, k) in headersAttendance" :key="k" @click="sortAttendance(k)">{{ h }}</th>
                                <th v-if="rowsAttendance.length > 0 && rowsAttendance.filter(a => a.stav == 4).length > 0">&nbsp;</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, i) in rowsAttendance" :key="i" >
                                <td v-for="(header, j) in headerKeysAttendance" :key="j" @click="openAttendance(row.id)">
                                    <span>{{ row[header] }}</span>
                                </td>
                                <td v-if="row.stav == 4"><button class="button-small button-inverse" @click="returnAttendance(row.id)" style="margin-left: 20px;">Vrátit</button></td>
                                <td><button class="button-small button-inverse" @click="deleteAttendance(row.id)" style="margin-left: 20px;">Smazat</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
                <AttendanceReadonlyComponent v-if="showAttendanceCard" :selectedAttendance="selectedAttendance" :isAdmin="true" @close-card="closeAttendanceCard" />
            </div>
        </div>
    </div>
                
    <ConfirmTextBoxComponent v-if="showConfirmTextBox" :message="confirmMessage" :dialogType="confirmType" :inputText="confirmText" @close-event="closeConfirmTextBox" />
    <ConfirmBoxComponent v-if="showConfirmBox" :message="confirmMessage" :dialogType="confirmType" @close-event="closeConfirmBox" />
</template>

<script>
    import api from '@/api/api.js'

    import SupportReadonlyComponent from './SupportReadonlyComponent.vue'
    import CooperationReadonlyComponent from './CooperationReadonlyComponent.vue'
    import AttendanceReadonlyComponent from './AttendanceReadonlyComponent.vue'
    import ConfirmTextBoxComponent from '../dialogs/ConfirmTextBoxComponent.vue'
    import ConfirmBoxComponent from '../dialogs/ConfirmBoxComponent.vue'

    export default {
        name: 'TemplatesAdminComponent',
        components: {
            SupportReadonlyComponent,
            CooperationReadonlyComponent,
            AttendanceReadonlyComponent,
            ConfirmTextBoxComponent,
            ConfirmBoxComponent
        },
        emits: ['show-document-generation-message'],
        data() {
            return {
                skola: [],
                selectedSchool: -1,
                showTemplate1: false,
                showTemplate2: false,
                showTemplate3: false,
                showTemplate4: false,

                showSupport: false,
                showSupportCard: false,
                headersSupport: [],
                rowsSupport: [],
                selectedSupport: -1,
                currentSortSupport: '',
                currentSortDirSupport: 'asc',

                showCooperation: false,
                showCooperationCard: false,
                headersCooperation: [],
                rowsCooperation: [],
                selectedCooperation: -1,
                currentSortCooperation: '',
                currentSortDirCooperation: 'asc',

                showAttendance: false,
                showAttendanceCard: false,
                headersAttendance: [],
                rowsAttendance: [],
                selectedAttendance: -1,
                currentSortAttendance: '',
                currentSortDirAttendance: 'asc',

                showConfirmTextBox: false,
                showConfirmBox: false,
                confirmMessage: "",
                confirmText: "",
                confirmType: -1,
                confirmBoxId: -1
            }
        },
        computed: {
            sortedSkola() {
                return this.skola
                    .slice()
                    .sort((a, b) => (a.nazev).localeCompare(b.nazev, 'cz'))
            },
            headerKeysSupport() {
                return Object.keys(this.headersSupport)
            },
            sortedRowsSupport() {
                if (this.currentSortSupport === '')
                    return this.rowsSupport
                return this.rowsSupport.slice().sort(
                    (a, b) => this.currentSortDirSupport === 'desc' 
                        ? (b[this.currentSortSupport] === null || b[this.currentSortSupport] === undefined ? -1 : (a[this.currentSortSupport] === null || a[this.currentSortSupport] === undefined ? 1 : b[this.currentSortSupport].localeCompare(a[this.currentSortSupport], 'cz'))) 
                        : (a[this.currentSortSupport] === null || a[this.currentSortSupport] === undefined ? -1 : (b[this.currentSortSupport] === null || b[this.currentSortSupport] === undefined ? 1 : a[this.currentSortSupport].localeCompare(b[this.currentSortSupport], 'cz'))))
            },
            headerKeysCooperation() {
                return Object.keys(this.headersCooperation)
            },
            sortedRowsCooperation() {
                if (this.currentSortCooperation === '')
                    return this.rowsCooperation
                return this.rowsCooperation.slice().sort(
                    (a, b) => this.currentSortDirCooperation === 'desc' 
                        ? (b[this.currentSortCooperation] === null || b[this.currentSortCooperation] === undefined ? -1 : (a[this.currentSortCooperation] === null || a[this.currentSortCooperation] === undefined ? 1 : b[this.currentSortCooperation].localeCompare(a[this.currentSortCooperation], 'cz'))) 
                        : (a[this.currentSortCooperation] === null || a[this.currentSortCooperation] === undefined ? -1 : (b[this.currentSortCooperation] === null || b[this.currentSortCooperation] === undefined ? 1 : a[this.currentSortCooperation].localeCompare(b[this.currentSortCooperation], 'cz'))))
            },
            headerKeysAttendance() {
                return Object.keys(this.headersAttendance)
            },
            sortedRowsAttendance() {
                if (this.currentSortAttendance === '')
                    return this.rowsAttendance
                return this.rowsAttendance.slice().sort(
                    (a, b) => this.currentSortDirAttendance === 'desc' 
                        ? (b[this.currentSortAttendance] === null || b[this.currentSortAttendance] === undefined ? -1 : (a[this.currentSortAttendance] === null || a[this.currentSortAttendance] === undefined ? 1 : b[this.currentSortAttendance].localeCompare(a[this.currentSortAttendance], 'cz'))) 
                        : (a[this.currentSortAttendance] === null || a[this.currentSortAttendance] === undefined ? -1 : (b[this.currentSortAttendance] === null || b[this.currentSortAttendance] === undefined ? 1 : a[this.currentSortAttendance].localeCompare(b[this.currentSortAttendance], 'cz'))))
            }
        },
        watch: {
            selectedSchool(newVal, oldVal) {
                if (oldVal !== newVal) {
                    if (newVal !== -1) {
                        let school = this.skola.filter(a => a.id == newVal).slice()[0]
                        this.showTemplate1 = school.template1
                        this.showTemplate2 = school.template2
                        this.showTemplate3 = school.template3
                        this.showTemplate4 = school.template4

                        this.changeTabPage(0)
                    }
                    else {
                        this.showTemplate1 = false
                        this.showTemplate2 = false
                        this.showTemplate3 = false
                        this.showTemplate4 = false
                    }
                }
            }
        },
        methods: {
            async load() {
                try {
                    let response = await api.getSchool()
                    this.skola = response.data
                }
                catch (error) {
                    console.log('Error: ', error)
                }
            },
            async updateSupportTable() {
                this.headersSupport = { "id": "ID", "nazev": "Název", "ucitel": "Učitel", "stavNazev": "Stav" }

                try {
                    let response = await api.getSupportBySchool(this.selectedSchool, -1)
                    this.rowsSupport = response.data
                }
                catch (error) {
                    console.log('Error: ', error)
                }
            },
            showDocumentGenerationMessage(value) {
                this.$emit('show-document-generation-message', value)
            },
            sortSupport(s) {
                if (s === this.currentSortSupport) {
                    this.currentSortDirSupport = this.currentSortDirSupport === 'asc' ? 'desc' : 'asc'
                }
                else {
                    this.currentSortSupport = s
                    this.currentSortDirSupport = 'asc'
                }
            },
            openSupport(id) {
                this.selectedSupport = id
                this.showSupportCard = true
            },
            closeSupportCard() {
                this.showSupportCard = false
                this.updateSupportTable()
            },
            async updateCooperationTable() {
                this.headersCooperation = { "id": "ID", "nazev": "Název", "ucitel": "Učitel", "stavNazev": "Stav" }

                try {
                    let response = await api.getCooperationBySchool(this.selectedSchool, -1)
                    this.rowsCooperation = response.data
                }
                catch (error) {
                    console.log('Error: ', error)
                }
            },
            sortCooperation(s) {
                if (s === this.currentSortCooperation) {
                    this.currentSortDirCooperation = this.currentSortDirCooperation === 'asc' ? 'desc' : 'asc'
                }
                else {
                    this.currentSortCooperation = s
                    this.currentSortDirCooperation = 'asc'
                }
            },
            openCooperation(id) {
                this.selectedCooperation = id
                this.showCooperationCard = true
            },
            closeCooperationCard() {
                this.showCooperationCard = false
                this.updateCooperationTable()
            },
            async updateAttendanceTable() {
                this.headersAttendance = { "id": "ID", "nazev": "Název akce", "ucitel": "Učitel", "stavNazev": "Stav" }

                try {
                    let response = await api.getAttendanceBySchool(this.selectedSchool, -1)
                    this.rowsAttendance = response.data
                }
                catch (error) {
                    console.log('Error: ', error)
                }
            },
            sortAttendance(s) {
                if (s === this.currentSortAttendance) {
                    this.currentSortDirAttendance = this.currentSortDirAttendance === 'asc' ? 'desc' : 'asc'
                }
                else {
                    this.currentSortAttendance = s
                    this.currentSortDirAttendance = 'asc'
                }
            },
            openAttendance(id) {
                this.selectedAttendance = id
                this.showAttendanceCard = true
            },
            closeAttendanceCard() {
                this.showAttendanceCard = false
                this.updateAttendanceTable()
            },
            changeTabPage(page) {
                this.showSupportCard = false
                this.showSupport = false
                this.showCooperationCard = false
                this.showCooperation = false
                this.showAttendanceCard = false
                this.showAttendance = false

                switch (page) {
                    case 1:
                        this.showSupport = true
                        this.updateSupportTable()
                        break
                    case 2:
                        this.showCooperation = true
                        this.updateCooperationTable()
                        break
                    case 3:
                        this.showAttendance = true;
                        this.updateAttendanceTable()
                        break
                }
            },
            returnSupport(id) {
                this.callConfirmText(id, 1, 'Šablona bude vrácena do procesu schvalování. Pokračovat?', this.rowsSupport.filter(a => a.id == id)[0].duvodVraceni)
            },
            returnCooperation(id) {
                this.callConfirmText(id, 2, 'Šablona bude vrácena do procesu schvalování. Pokračovat?', this.rowsCooperation.filter(a => a.id == id)[0].duvodVraceni)
            },
            returnAttendance(id) {
                this.callConfirmText(id, 3, 'Šablona bude vrácena do procesu schvalování. Pokračovat?', this.rowsAttendance.filter(a => a.id == id)[0].duvodVraceni)
            },
            deleteSupport(id) {
                this.callConfirm(id, 4, 'Šablona bude smazána. Proces je nevratný. Pokračovat?')
            },
            deleteCooperation(id) {
                this.callConfirm(id, 5, 'Šablona bude smazána. Proces je nevratný. Pokračovat?')
            },
            deleteAttendance(id) {
                this.callConfirm(id, 6, 'Šablona bude smazána. Proces je nevratný. Pokračovat?')
            },
            callConfirmText(id, type, message, text) {                
                this.confirmType = type
                this.confirmMessage = message
                this.confirmText = text
                this.showConfirmTextBox = true
                this.confirmBoxId = id
            },
            callConfirm(id, type, message) {                
                this.confirmType = type
                this.confirmMessage = message
                this.showConfirmBox = true
                this.confirmBoxId = id
            },
            async closeConfirmTextBox(dialogResult, dialogType, text) {
                this.showConfirmTextBox = false
                this.confirmType = -1
                this.confirmMessage = ''
                this.confirmText = ''

                if (dialogResult == 1) {
                    switch (dialogType) {
                        case 1:
                            await api.returnSupport(this.confirmBoxId, text)
                            this.updateSupportTable()
                            break
                        case 2:
                            await api.returnCooperation(this.confirmBoxId, text)
                            this.updateCooperationTable()
                            break
                        case 3:
                            await api.returnAttendance(this.confirmBoxId, text)
                            this.updateAttendanceTable()
                            break
                    }
                }
                
                this.confirmBoxId = -1
            },
            async closeConfirmBox(dialogResult, dialogType) {
                this.showConfirmBox = false
                this.confirmType = -1
                this.confirmMessage = ''

                if (dialogResult == 1) {
                    switch (dialogType) {
                        case 4:
                            await api.deleteSupport(this.confirmBoxId)
                            this.updateSupportTable()
                            break
                        case 5:
                            await api.deleteCooperation(this.confirmBoxId)
                            this.updateCooperationTable()
                            break
                        case 6:
                            await api.deleteAttendance(this.confirmBoxId)
                            this.updateAttendanceTable()
                            break
                    }
                }
                
                this.confirmBoxId = -1
            }
        },
        async created() {
            await this.load()
        }
    }
</script>