<template>
  <LoginComponent v-if="!isLogged" @set-logged-user="setLoggedUser" />

  <div v-if="isLogged" class="cols content">
    <MenuComponent @loggout-user="loggoutUser" @set-menu-item="setMenuItem" :isCoordinator="isCoordinator" :isAdmin="isAdmin" :isSchoolSelected="teacherSchool !== -1" />

    <div class="rows" style="flex-grow: 1; overflow: hidden;">
      <div class="header">
        <div class="header-school">{{ schoolName }}</div>
        <div class="header-user">
          <span class="header-user-name">{{ this.loggedUser.prijmeni }} {{ this.loggedUser.jmeno }}</span>
          <br />
          <span class="header-user-type">{{ userType }}</span>
        </div>
      </div>

      <div v-if="showSchoolSelect" class="school-select">
        <div class="cols justify-center align-center fullscreen">
          <div class="rows gap-10 message-box">
            <h3>Vyberte školu</h3>
            <div style="max-height: 400px; overflow-y: auto;">
                <div v-for="(s, j) in sortedSchools" :key="j">
                  <input type="radio" :id="s.id" name="schoolSelect" v-model="teacherSchool" :value="s.id" />{{ s.nazev }}
                </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="showSavingDataMessage" class="saving-data">
        <div class="cols justify-center align-center fullscreen">
          <div class="rows gap-10 message-box">
            <h3>Ukládám data...</h3>
          </div>
        </div>
      </div>

      <div v-if="showDGMessage" class="saving-data">
        <div class="cols justify-center align-center fullscreen">
          <div class="rows gap-10 message-box">
            <h3>Generuji dokument...</h3>
          </div>
        </div>
      </div>

      <div class="main-content">
        <OverviewTeacherComponent v-if="!isAdmin && !isCoordinator && menuItem == 1" />
        <OverviewCoordinatorComponent v-if="isCoordinator && menuItem == 1" />
        <OverviewAdminComponent v-if="isAdmin && menuItem == 1" />
        
        <TemplatesComponent v-if="!isAdmin && menuItem == 2" :isCoordinator="isCoordinator" :idUser="loggedUser.id" :idSchool="teacherSchool" @show-saving-message="showSavingMessage" @show-document-generation-message="showDocumentGenerationMessage"
          :showTemplate1="teacherSchool != -1 && school.template1" 
          :showTemplate2="teacherSchool != -1 && school.template2" 
          :showTemplate3="teacherSchool != -1 && school.template3" 
          :showTemplate4="teacherSchool != -1 && school.template4"
        />
        
        <TemplatesAdminComponent v-if="isAdmin && menuItem == 2" @show-document-generation-message="showDocumentGenerationMessage" />
        
        <ParticipationComponent v-if="(isAdmin || isCoordinator) && menuItem == 12" :isCoordinator="isCoordinator" :teacherSchool="teacherSchool" 
          @show-saving-message="showSavingMessage" @show-document-generation-message="showDocumentGenerationMessage" />
        
        <BudgetCoordinatorComponent v-if="isCoordinator && menuItem == 3" />
        <BudgetAdminComponent v-if="isAdmin && menuItem == 3" />
        
        <ApprovalCoordinatorComponent v-if="isCoordinator && menuItem == 4" />
        <ApprovalAdminComponent v-if="isAdmin && menuItem == 4" />

        <FormsComponent v-if="isAdmin && menuItem == 5" />
        
        <DocumentsCoordinatorComponent v-if="isCoordinator && menuItem == 6" :idSchool="teacherSchool" />
        <DocumentsAdminComponent v-if="isAdmin && menuItem == 6" />
        
        <SchoolsComponent v-if="isAdmin && menuItem == 7" @show-saving-message="showSavingMessage" />
        
        <TeachersComponent v-if="(isAdmin || isCoordinator) && menuItem == 8" :isCoordinator="isCoordinator" :teacherSchool="teacherSchool" 
          @show-saving-message="showSavingMessage" />
        
        <AdminsComponent v-if="isAdmin && menuItem == 9" @show-saving-message="showSavingMessage" />
        
        <SettingsComponent v-if="menuItem == 10" :email="this.loggedUser.email" :isAdmin="isAdmin" :idUser="loggedUser.id" :idSchool="teacherSchool"
          @show-saving-message="showSavingMessage" />

        <AboutComponent v-if="menuItem == 11" />
      </div>
    </div>
  </div>
</template>

<script>
  import api from '@/api/api.js'

  import LoginComponent from './components/LoginComponent.vue'
  import MenuComponent from './components/MenuComponent.vue'

  import OverviewTeacherComponent from './components/overviews/OverviewTeacherComponent.vue'
  import OverviewCoordinatorComponent from './components/overviews/OverviewCoordinatorComponent.vue'
  import OverviewAdminComponent from './components/overviews/OverviewAdminComponent.vue'

  import TemplatesComponent from './components/templates/TemplatesComponent.vue'
  import TemplatesAdminComponent from './components/templates/TemplatesAdminComponent.vue'
  
  import ParticipationComponent from './components/participationcards/ParticipationComponent.vue'

  import BudgetCoordinatorComponent from './components/budgets/BudgetCoordinatorComponent.vue'
  import BudgetAdminComponent from './components/budgets/BudgetAdminComponent.vue'

  import ApprovalCoordinatorComponent from './components/approvals/ApprovalCoordinatorComponent.vue'
  import ApprovalAdminComponent from './components/approvals/ApprovalAdminComponent.vue'

  import FormsComponent from './components/forms/FormsComponent.vue'

  import DocumentsCoordinatorComponent from './components/documents/DocumentsCoordinatorComponent.vue'
  import DocumentsAdminComponent from './components/documents/DocumentsAdminComponent.vue'

  import SchoolsComponent from './components/settings/SchoolsComponent.vue'
  import TeachersComponent from './components/settings/TeachersComponent.vue'
  import AdminsComponent from './components/settings/AdminsComponent.vue'
  import SettingsComponent from './components/settings/SettingsComponent.vue'

  import AboutComponent from './components/other/AboutComponent.vue'

  export default {
    name: 'App',
    components: {
      LoginComponent,
      MenuComponent,
      OverviewTeacherComponent,
      TemplatesComponent,
      TemplatesAdminComponent,
      SettingsComponent,
      AboutComponent,
      OverviewCoordinatorComponent,
      OverviewAdminComponent,
      BudgetCoordinatorComponent,
      BudgetAdminComponent,
      ApprovalCoordinatorComponent,
      ApprovalAdminComponent,
      FormsComponent,
      DocumentsCoordinatorComponent,
      DocumentsAdminComponent,
      SchoolsComponent,
      TeachersComponent,
      AdminsComponent,
      ParticipationComponent
    },
    data () {
      return {
        isLogged: false,
        loggedUser: {},
        menuItem: -1,
        teacherSchool: -1,
        schools: [],
        showSchoolSelect: false,
        showSavingDataMessage: false,
        showDGMessage: false
      }
    },
    computed: {
      isAdmin() {
        if (this.loggedUser !== null && Object.keys(this.loggedUser).length !== 0 && this.loggedUser.isAdmin)
          return true
        else
          return false
      },
      isCoordinator() {
        if (this.teacherSchool !== -1) {
          if (this.schools.filter(a => a.id == this.teacherSchool).slice()[0].koordinatori.filter(k => k.idUzivatel === this.loggedUser.id).length > 0)
            return true
          else
            return false
        }
        else
          return false
      },
      userType() {
        if (this.isAdmin)
          return 'administrátor'
        else if (this.isCoordinator)
          return 'koordinátor'
        else
          return 'učitel'
      },
      school() {
        if (this.teacherSchool !== -1)
          return this.schools.filter(a => a.id == this.teacherSchool).slice()[0]
        else
          return null
      },
      schoolName() {
        if (this.teacherSchool !== -1)
          return this.schools.filter(a => a.id == this.teacherSchool).slice()[0].nazev
        else
          return ''
      },
      sortedSchools() {
          return this.schools
              .slice()
              .sort((a, b) => a.nazev.localeCompare(b.nazev, 'cz'))
      }
    },
    watch: {
      teacherSchool(newVal, oldVal) {
        if (newVal !== oldVal && oldVal === -1)
          this.showSchoolSelect = false
      }
    },
    methods: {
      showSavingMessage(value) {
        this.showSavingDataMessage = value
      },
      showDocumentGenerationMessage(value) {
        this.showDGMessage = value
      },
      async setLoggedUser(value) {
        if (value !== null && Object.keys(value).length !== 0 && (value.id !== -1 || value.isAdmin)) {
          this.loggedUser = value

          try {
            let response = await api.getSchoolsForUser(value.id)

            this.schools = response.data
            if (this.schools.length > 0) {
              if (this.schools.length == 1)
                this.teacherSchool = this.schools[0].id
              else
                this.showSchoolSelect = true
            }

            this.menuItem = 1
            this.isLogged = true
          }
          catch (error) {
            console.log('Error: ', error)
            this.isLogged = false
          }
        }
      },
      loggoutUser() {
        this.menuItem = -1
        this.isLogged = false
        this.loggedUser = {}
        this.teacherSchool = -1
        this.schools = []
      },
      setMenuItem(value) {
        this.menuItem = value
      }
    }
  }
</script>