<template>
    <div>
    </div>
</template>

<script>
export default {
    name: 'ApprovalCoordinatorComponent',
    props: [],
    data() {
        return {
        }
    },
    methods: {
    }
}
</script>