<template>
    <div class="cols">
        <div class="rows gap-30">
            <div class="cols" style="gap: 5px;">
                <button :class="'button-small' + (!this.showAbout ? ' button-inverse' : '')" @click="changeTabPage(0)">O aplikaci</button>
                <button :class="'button-small' + (!this.showCookies ? ' button-inverse' : '')" @click="changeTabPage(1)">Cookies</button>
                <button :class="'button-small' + (!this.showContacts ? ' button-inverse' : '')" @click="changeTabPage(2)">Kontakty</button>
            </div>

            <div v-if="showAbout" class="rows" style="max-width: 800px; padding: 10px;">
                <p>Aplikace Sorbina je vytvořena pro správu projektů financovaných z operačního programu J. A. Komenský (MŠMT). Aktuálně jsou vytvořeny formuláře pro projekty z výzvy č. 02_22_002 Šablony pro MŠ a ZŠ I. Aplikaci spravuje Portedo o.p.s., organizace, která se zaměřuje na projektovou podporu uměleckým školám.</p>
            </div>

            <div v-if="showCookies" class="rows" style="max-width: 800px; padding: 10px;">
                <h2>Co jsou cookies?</h2>
                <p>Soubory cookie používáme k zobrazení personalizovaného obsahu.</p>
                <h2>Funkční cookies</h2>
                <p>Tyto soubory cookie jsou nezbytné pro fungování webových stránek, není tedy možné je zakázat. Obvykle se nastavují v reakci na akci, kterou na webu sami provedete, jako je nastavení zabezpečení, přihlášení a vyplňování formulářů. Svůj prohlížeč můžete nastavit tak, aby blokoval soubory cookie nebo o nich zasílal upozornění. Mějte na paměti, že některé stránky nebudou bez těchto souborů fungovat. Tyto soubory cookie neukládají žádné informace, které lze přiřadit konkrétní osobě.</p>
            </div>

            <div v-if="showContacts" class="rows" style="max-width: 800px; padding: 10px;">
                <p>Robert Mimra<br />office@portedo.cz</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AboutComponent',
        props: [],
        data() {
            return {
                showAbout: true,
                showCookies: false,
                showContacts: false
            }
        },
        methods: {
            changeTabPage(page) {
                this.showAbout = false
                this.showCookies = false
                this.showContacts = false

                switch (page) {
                    case 1:
                        this.showCookies = true;
                        break;
                    case 2:
                        this.showContacts = true;
                        break;
                    default:
                        this.showAbout = true;
                        break;
                }
            }
        }
    }
</script>