<template>
    <div>
        <button class="button-small" @click="save()">Uložit</button>
        <button class="button-small" @click="generateCards()" style="margin-left: 20px;">Generovat karty</button>

        <select v-if="!isCoordinator" v-model="selectedSchool" style="width: 200px; margin-left: 20px;">
            <option :value="-1">&lt; vyberte školu &gt;</option>
            <option v-for="(t, i) in sortedSkola" :key="i" :value="t.id">{{ t.nazev }}</option>
        </select>
        <br />
        <table style="margin-top: 20px;">
            <thead>
                <tr>
                    <th colspan="5">Identifikace</th>
                    <th colspan="9">Trvalé bydliště</th>
                    <th colspan="4">Další údaje</th>
                    <th colspan="2">Podklady pro ESC CR</th>
                    <th>&nbsp;</th>
                    <th colspan="2">Karta</th>
                </tr>
                <tr>
                    <th>Titul&nbsp;před</th>
                    <th>Jméno</th>
                    <th>Příjmení</th>
                    <th>Titul&nbsp;za</th>
                    <th>Datum&nbsp;nar.</th>

                    <th>Obec</th>
                    <th>Část&nbsp;obce</th>
                    <th>Ulice</th>
                    <th>Č.&nbsp;popisné</th>
                    <th>Č.&nbsp;orientační</th>
                    <th>PSČ</th>
                    <th>RUIAN</th>
                    <th>Telefon</th>
                    <th>Email</th>

                    <th>Muž&nbsp;/&nbsp;Žena</th>
                    <th>Trh&nbsp;práce</th>
                    <th>Vzdělání</th>
                    <th>Typ&nbsp;znevýhodnění</th>

                    <th>Téma&nbsp;vzdělávání</th>
                    <th>Počet&nbsp;hodin</th>

                    <th>Příjmení</th>
                    
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>

            <tbody>
                <tr v-for="(row, i) in sortedCards" :key="i" :class="row.idUcitel == -1 ? 'background-silver' : ''" >
                    <td style="vertical-align: top;">{{ row.data.titulPred }}</td>
                    <td style="vertical-align: top;">{{ row.data.jmeno }}</td>
                    <td style="vertical-align: top;">{{ row.data.prijmeni }}</td>
                    <td style="vertical-align: top;">{{ row.data.titulZa }}</td>
                    <td style="vertical-align: top;">
                        <VueDatePicker
                            v-model="row.data.datumNarozeni"
                            locale="cs"
                            :auto-apply="true"
                            :enable-time-picker="false"
                            :hide-input-icon="true"
                            :text-input="true"
                            format="d.M.yyyy"
                            timezone="UTC"
                            utc="preserve"
                            class="datepicker"
                            six-weeks="append"
                        />
                    </td>
                    
                    <td style="vertical-align: top;"><input v-model="row.data.obec" style="width: 150px;" /></td>
                    <td style="vertical-align: top;"><input v-model="row.data.castObce" style="width: 150px;" /></td>
                    <td style="vertical-align: top;"><input v-model="row.data.ulice" style="width: 150px;" /></td>
                    <td style="vertical-align: top;"><input v-model="row.data.cPopisne" style="width: 60px;" /></td>
                    <td style="vertical-align: top;"><input v-model="row.data.cOrientacni" style="width: 60px;" /></td>
                    <td style="vertical-align: top;"><input v-model="row.data.psc" style="width: 60px;" /></td>
                    <td style="vertical-align: top;"><a :href="'https://vdp.cuzk.cz/vdp/ruian/overeniadresy?nazevUl=' + row.data.ulice + '&cisDom=' + row.data.cPopisne + '&cisOr=' + row.data.cOrientacni + '&nazevCo=' + row.data.castObce + '&nazevOb=' + row.data.obec + '&psc=' + row.data.psc + '&sort=UZEMI&search='" target=”_blank”><button class="button-small button-inverse">Ověřit</button></a></td>
                    <td style="vertical-align: top;"><input v-model="row.data.telefon" style="width: 100px;" /></td>
                    <td style="vertical-align: top;"><input v-model="row.data.email" style="width: 150px;" /></td>
                    
                    <td style="vertical-align: top;">
                        <select v-model="row.data.pohlavi" style="width: 110px;">
                            <option value="">&lt; vyberte &gt;</option>
                            <option value="Muž">muž</option>
                            <option value="Žena">žena</option>
                        </select>
                    </td>
                    <td style="vertical-align: top;">{{ row.data.trh }}</td>
                    <td style="vertical-align: top;">
                        <select v-model="row.data.vzdelani" style="width: 200px;">
                            <option value="">&lt; vyberte &gt;</option>
                            <option value="3">základní (ISCED 0 až 2)</option>
                            <option value="4">středoškolské, maturita či vyučení anebo pomaturitní studium (ISCED 3 až 4)</option>
                            <option value="5">vyšší odborné, bakalářské, magisterské, doktorské studium (ISCED 5 až 8)</option>
                        </select>
                    </td>

                    <td style="white-space: nowrap; vertical-align: top;">
                        <input type="checkbox" v-model="row.data.znPostizeni" />účastníci&nbsp;se&nbsp;zdravotním&nbsp;postižením <sup>1</sup><br />
                        <input type="checkbox" v-model="row.data.znTretiZem" />státní&nbsp;příslušníci&nbsp;třetích&nbsp;zemí <sup>2</sup><br />
                        <input type="checkbox" v-model="row.data.znZahranici" />účastníci&nbsp;zahraničního&nbsp;původu <sup>3</sup><br />
                        <input type="checkbox" v-model="row.data.znMensina" />příslušníci&nbsp;menšin <sup>4</sup><br />
                        <input type="checkbox" v-model="row.data.znBezPristresi" />osoby&nbsp;bez&nbsp;přístřeší <sup>5</sup>
                    </td>

                    <td style="white-space: nowrap; vertical-align: top;">
                        <div v-for="(t, j) in row.data.tema" :key="j">{{ t.tema }}</div>
                    </td>

                    <td style="white-space: nowrap; vertical-align: top; text-align: center;">
                        <div v-for="(t, k) in row.data.tema" :key="k">{{ t.pocetHodin }}</div>
                    </td>
                    
                    <td style="vertical-align: top;">{{ row.data.prijmeni }}</td>

                    <td style="white-space: nowrap; vertical-align: top;">{{ row.existujeKarta ? 'ANO' : 'NE' }}</td>
                    <td style="white-space: nowrap; vertical-align: top;"><button class="button-small" @click="generateCard(row.id)">Generovat kartu</button></td>
                </tr>
            </tbody>
        </table>
        <br />
        <button class="button-small" @click="save()">Uložit</button>

        <br /><br />
        <div style="font-size: 10pt;">
            <div>1 - Do kategorie osob se zdravotním postižením patří v souladu s § 67 zákona č. 435/2004 Sb. fyzické osoby, které jsou orgánem sociálního zabezpečení uznány a) invalidními ve třetím stupni, b) invalidními v prvním nebo druhém stupni, c) zdravotně znevýhodněnými. Do této kategorie patří také fyzické osoby, které byly uznány Úřadem práce ČR zdravotně znevýhodněnými, a rozhodnutí nepozbylo platnosti. V případě projektů týkajících se škol a školských zařízení se nad rámec výše uvedeného zdravotně postiženými účastníky rozumí také děti, žáci a studenti se zdravotním postižením dle § 16 zákona č. 561/2004 Sb., a vyhlášky č. 73/2005 Sb., kteří potřebují speciální pomoc při vzdělávání kvůli svému znevýhodnění.</div>
            <div>2 - Státním příslušníkem třetí země se rozumí jakákoli osoba, která není občanem Unie ve smyslu čl. 20 odst. 1 SFEU, s výjimkou osob s vícenásobným občanstvím, kde alespoň jedno občanství je z členského státu EU. Státní příslušníci třetích zemí zahrnují osoby bez státní příslušnosti a osoby s neurčenou národností.</div>
            <div>3 - Účastníci, jejichž oba rodiče se narodili mimo ČR nebo účastníci, kteří mají české občanství, nicméně původem jsou cizinci. Patří sem i osoby, jejichž rodiče se narodili v ČR a poté emigrovali.</div>
            <div>4 - Účastníci, kteří žijí na území ČR, avšak pocházejí z území mimo ČR, náleží do některé z národnostních menšin či potřebují speciální pomoc na trhu práce kvůli jazyku či jinému kulturnímu znevýhodnění/ problémům. V ČR jsou národnostní menšiny uvedeny výčtem v článku 3 statutu Rady vlády pro národnostní menšiny. (Jedná se o celkem 14 menšin: běloruskou, bulharskou, chorvatskou, maďarskou, německou, polskou, romskou, rusínskou, ruskou, řeckou, slovenskou, srbskou, ukrajinskou, vietnamskou).</div>
            <div>5 - Jedná se o osoby, jejichž bydlení je nejisté nebo neodpovídá standardům bydlení v daném prostředí (z důvodu chudoby, zadlužení, provizorního charakteru ubytování, blížícího se propuštění z instituce, pobytu bez právního nároku apod.), osoby v ubytovacím zařízení pro bezdomovce, osoby spící venku (bez střechy) – „na ulici“ / bez přístřeší, a tudíž potřebují speciální pomoc v procesu začlenění se na trhu práce.</div>
        </div>
    </div>
</template>

<script>
    import api from '@/api/api.js'
    import VueDatePicker from '@vuepic/vue-datepicker'
    import '@vuepic/vue-datepicker/dist/main.css'
    import dayjs from 'dayjs'
    import utc from 'dayjs/plugin/utc'
    dayjs.extend(utc)

    export default {
        name: 'ParticipationComponent',
        emits: ['show-saving-message', 'show-document-generation-message'],
        props: ['isCoordinator', 'teacherSchool'],
        components: {
            VueDatePicker
        },
        data() {
            return {
                skola: [],
                selectedSchool: -1,
                karty: []
            }
        },
        computed: {
            sortedCards() {
                return this.karty
                    .slice()
                    .sort((a, b) => (a.prijmeni + ' ' + a.jmeno).localeCompare(b.prijmeni + ' ' + b.jmeno, 'cz'))
            },
            sortedSkola() {
                return this.skola
                    .slice()
                    .sort((a, b) => (a.nazev).localeCompare(b.nazev, 'cz'))
            }
        },
        watch: {
            selectedSchool(newVal, oldVal) {
                if (oldVal !== newVal)
                    this.updateTable()
            }
        },
        methods: {
            async load() {
                try {
                    let response = await api.getSchool()
                    this.skola = response.data
                    this.selectedSchool = this.teacherSchool
                }
                catch (error) {
                    console.log('Error: ', error)
                }
            },
            async updateTable() {
                if (this.selectedSchool != -1) {
                    try {
                        let response = await api.getParticipants(this.selectedSchool)
                        this.karty = response.data
                    }
                    catch (error) {
                        console.log('Error: ', error)
                    }
                }
                else
                    this.karty = []
            },
            formatDate(date) {
                return date !== null ? dayjs(date).utcOffset(0).format('D.M.YYYY') : ''
            },
            showSavingDataMessage(value) {
                this.$emit('show-saving-message', value)
            },
            showDocumentGenerationMessage(value) {
                this.$emit('show-document-generation-message', value)
            },
            async save() {
                if (this.selectedSchool != -1) {
                    this.showSavingDataMessage(true)

                    let response = await api.postParticipants(this.selectedSchool, this.karty)
                    if (response.status == 204)
                        await this.updateTable()
                    
                    this.showSavingDataMessage(false)
                }
            },
            async generateCard(id) {
                if (this.selectedSchool != -1) {
                    this.showDocumentGenerationMessage(true)

                    let response = await api.generateCards(this.selectedSchool, this.karty.filter(k => k.id == id))
                    if (response.status == 204)
                        await this.updateTable()
                    
                    this.showDocumentGenerationMessage(false)
                }
            },
            async generateCards() {
                if (this.selectedSchool != -1) {
                    this.showDocumentGenerationMessage(true)

                    let response = await api.generateCards(this.selectedSchool, this.karty)
                    if (response.status == 204)
                        await this.updateTable()
                    
                    this.showDocumentGenerationMessage(false)
                }
            }
        },
        async created() {
            await this.load()
        }
    }
</script>