<template>
    <div class="cols justify-center align-center fullscreen">
        <div class="rows align-center gap-10">
            <div class="login-title">
                <img src="@/assets/img/sorbina-img.png" height="30" />
                <span style="margin-left: 20px;">SORBINA</span>
            </div>

            <input type="email" v-model="email" placeholder="E-mail" @keyup.enter="loginUser()" />
            <div class="rows">
                <input type="password" v-model="password" placeholder="Heslo" @keyup.enter="loginUser()" />
                <div style="font-size: x-small; text-align: right;"><a href="resethesla.html">Zapomenuté heslo</a></div>
            </div>
            <button @click="loginUser()">Přihlásit</button>

            <div v-if="logginError" class="color-red">Chybný e-mail nebo heslo.</div>
            <div v-else>&nbsp;</div>
        </div>
    </div>
</template>

<script>
    import api from '@/api/api.js'

    export default {
        name: 'LoginComponent',
        emits: ['set-logged-user'],
        data() {
            return {
                logginError: false,
                // email: 'jarosm2@centrum.cz',
                // password: 'test'
                email: '',
                password: ''
            }
        },
        methods: {
            async loginUser() {
                try {
                    let response = await api.login({ email: this.email, heslo: this.password })
                    if (response.data !== null && Object.keys(response.data).length !== 0 && (response.data.id !== -1 || response.data.isAdmin)) {
                        this.logginError = false
                        this.$emit("set-logged-user", response.data)
                    }
                    else {
                        this.logginError = true
                    }
                }
                catch (error) {
                    console.log('Error: ', error)
                }
            }
        },
        created() {
            console.log(process.env.VUE_APP_API_URL)
        }
    }
</script>