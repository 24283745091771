<template>
    <div class="message-box-wrapper">
        <div class="cols justify-center align-center fullscreen">
            <div class="rows gap-10 message-box">
                <div>{{ this.message }}</div>
                <div class="cols justify-center gap-10">
                    <button class="button-small" @click="close()">OK</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MessageBoxComponent',
        emits: ['close-event'],
        props: ['message', 'dialogType'],
        data() {
            return {}
        },
        methods: {
            close() {
                this.$emit('close-event', this.dialogType)
            }
        }
    }
</script>