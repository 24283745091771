<template>
    <div class="cols">
        <div class="rows gap-30">
            <div class="cols" style="gap: 5px;">
                <button v-if="showTemplate1" :class="'button-small' + (!this.showSupport ? ' button-inverse' : '')" @click="changeTabPage(1)">Šablona 1</button>
                <button v-if="showTemplate2" :class="'button-small' + (!this.showCooperation ? ' button-inverse' : '')" @click="changeTabPage(2)">Šablona 2</button>
                <button v-if="showTemplate3" :class="'button-small' + (!this.showAttendance ? ' button-inverse' : '')" @click="changeTabPage(3)">Šablona 3</button>
            </div>

            <div v-if="showTemplate1 && showSupport">
                <div v-if="!showSupportCard">
                    <button class="button-small" @click="newSupport()">Nový záznam</button>
                    <br />
                    <table style="margin-top: 20px;">
                        <thead>
                            <tr>
                                <th v-for="(h, k) in headersSupport" :key="k" @click="sortSupport(k)">{{ h }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, i) in rowsSupport" :key="i" >
                                <td v-for="(header, j) in headerKeysSupport" :key="j" @click="openSupportCard(row.id)">
                                    <span>{{ row[header] }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
                <SupportComponent v-if="showSupportCard && (selectedSupport == -1 || rowsSupport.filter(a => a.id == selectedSupport)[0].stav == 1)" 
                    :selectedSupport="selectedSupport" :idUser="idUser" :idSchool="idSchool" :isCoordinator="isCoordinator"
                    @show-saving-message="showSavingDataMessage" @close-card="closeSupportCard" ref="refSupportComponent" />
                <SupportReadonlyComponent v-if="showSupportCard && (selectedSupport != -1 && rowsSupport.filter(a => a.id == selectedSupport)[0].stav > 1)"
                    :selectedSupport="selectedSupport" :isAdmin="false" @close-card="closeSupportCard" />
            </div>

            <div v-if="showTemplate2 && showCooperation">
                <div v-if="!showCooperationCard">
                    <button class="button-small" @click="newCooperation()">Nový záznam</button>
                    <br />
                    <table style="margin-top: 20px;">
                        <thead>
                            <tr>
                                <th v-for="(h, k) in headersCooperation" :key="k" @click="sortCooperation(k)">{{ h }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, i) in rowsCooperation" :key="i" >
                                <td v-for="(header, j) in headerKeysCooperation" :key="j" @click="openCooperationCard(row.id)">
                                    <span>{{ row[header] }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
                <CooperationComponent v-if="showCooperationCard && (selectedCooperation == -1 || rowsCooperation.filter(a => a.id == selectedCooperation)[0].stav == 1)" 
                    :selectedCooperation="selectedCooperation" :idUser="idUser" :idSchool="idSchool" :isCoordinator="isCoordinator"
                    @show-saving-message="showSavingDataMessage" @close-card="closeCooperationCard" @show-document-generation-message="showDocumentGenerationMessage" ref="refCooperationComponent" />
                <CooperationReadonlyComponent v-if="showCooperationCard && (selectedCooperation != -1 && rowsCooperation.filter(a => a.id == selectedCooperation)[0].stav > 1)"
                    :selectedCooperation="selectedCooperation" :isAdmin="false" @close-card="closeCooperationCard" @show-document-generation-message="showDocumentGenerationMessage" />
            </div>

            <div v-if="showTemplate3 && showAttendance">
                <div v-if="!showAttendanceCard">
                    <button class="button-small" @click="newAttendance()">Nový záznam</button>
                    <br />
                    <table style="margin-top: 20px;">
                        <thead>
                            <tr>
                                <th v-for="(h, k) in headersAttendance" :key="k" @click="sortAttendance(k)">{{ h }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, i) in rowsAttendance" :key="i" >
                                <td v-for="(header, j) in headerKeysAttendance" :key="j" @click="openAttendanceCard(row.id)">
                                    <span>{{ row[header] }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
                <AttendanceComponent v-if="showAttendanceCard && (selectedAttendance == -1 || rowsAttendance.filter(a => a.id == selectedAttendance)[0].stav == 1)" 
                    :selectedAttendance="selectedAttendance" :idUser="idUser" :idSchool="idSchool" :isCoordinator="isCoordinator"
                    @show-saving-message="showSavingDataMessage" @close-card="closeAttendanceCard" ref="refAttendanceComponent" />
                <AttendanceReadonlyComponent v-if="showAttendanceCard && (selectedAttendance != -1 && rowsAttendance.filter(a => a.id == selectedAttendance)[0].stav > 1)"
                    :selectedAttendance="selectedAttendance" :isAdmin="false" @close-card="closeAttendanceCard" />
            </div>
        </div>
    </div>

    <ConfirmBoxComponent v-if="showConfirmBox" :message="confirmMessage" :dialogType="confirmType" @close-event="closeConfirmBox" />
</template>

<script>
    import api from '@/api/api.js'

    import SupportComponent from './SupportComponent.vue'
    import SupportReadonlyComponent from './SupportReadonlyComponent.vue'

    import CooperationComponent from './CooperationComponent.vue'
    import CooperationReadonlyComponent from './CooperationReadonlyComponent.vue'

    import AttendanceComponent from './AttendanceComponent.vue'
    import AttendanceReadonlyComponent from './AttendanceReadonlyComponent.vue'

    import ConfirmBoxComponent from '../dialogs/ConfirmBoxComponent.vue'

    export default {
        name: 'TemplatesComponent',
        components: {
            SupportComponent,
            SupportReadonlyComponent,
            CooperationComponent,
            CooperationReadonlyComponent,
            AttendanceComponent,
            AttendanceReadonlyComponent,
            ConfirmBoxComponent
        },
        emits: ['show-saving-message', 'show-document-generation-message'],
        props: ['idUser', 'idSchool', 'isCoordinator', 'showTemplate1', 'showTemplate2', 'showTemplate3', 'showTemplate4'],
        data() {
            return {
                showSupport: false,
                showSupportCard: false,
                headersSupport: [],
                rowsSupport: [],
                selectedSupport: -1,
                currentSortSupport: '',
                currentSortDirSupport: 'asc',
                
                showCooperation: false,
                showCooperationCard: false,
                headersCooperation: [],
                rowsCooperation: [],
                selectedCooperation: -1,
                currentSortCooperation: '',
                currentSortDirCooperation: 'asc',

                showAttendance: false,
                showAttendanceCard: false,
                headersAttendance: [],
                rowsAttendance: [],
                selectedAttendance: -1,
                currentSortAttendance: '',
                currentSortDirAttendance: 'asc',
                
                idTeacher: -1,

                showConfirmBox: false,
                confirmMessage: "",
                confirmType: -1
            }
        },
        computed: {
            headerKeysSupport() {
                return Object.keys(this.headersSupport)
            },
            sortedRowsSupport() {
                if (this.currentSortSupport === '')
                    return this.rowsSupport
                return this.rowsSupport.slice().sort(
                    (a, b) => this.currentSortDirSupport === 'desc' 
                        ? (b[this.currentSortSupport] === null || b[this.currentSortSupport] === undefined ? -1 : (a[this.currentSortSupport] === null || a[this.currentSortSupport] === undefined ? 1 : b[this.currentSortSupport].localeCompare(a[this.currentSortSupport], 'cz'))) 
                        : (a[this.currentSortSupport] === null || a[this.currentSortSupport] === undefined ? -1 : (b[this.currentSortSupport] === null || b[this.currentSortSupport] === undefined ? 1 : a[this.currentSortSupport].localeCompare(b[this.currentSortSupport], 'cz'))))
            },
            headerKeysCooperation() {
                return Object.keys(this.headersCooperation)
            },
            sortedRowsCooperation() {
                if (this.currentSortCooperation === '')
                    return this.rowsCooperation
                return this.rowsCooperation.slice().sort(
                    (a, b) => this.currentSortDirCooperation === 'desc' 
                        ? (b[this.currentSortCooperation] === null || b[this.currentSortCooperation] === undefined ? -1 : (a[this.currentSortCooperation] === null || a[this.currentSortCooperation] === undefined ? 1 : b[this.currentSortCooperation].localeCompare(a[this.currentSortCooperation], 'cz'))) 
                        : (a[this.currentSortCooperation] === null || a[this.currentSortCooperation] === undefined ? -1 : (b[this.currentSortCooperation] === null || b[this.currentSortCooperation] === undefined ? 1 : a[this.currentSortCooperation].localeCompare(b[this.currentSortCooperation], 'cz'))))
            },
            headerKeysAttendance() {
                return Object.keys(this.headersAttendance)
            },
            sortedRowsAttendance() {
                if (this.currentSortAttendance === '')
                    return this.rowsAttendance
                return this.rowsAttendance.slice().sort(
                    (a, b) => this.currentSortDirAttendance === 'desc' 
                        ? (b[this.currentSortAttendance] === null || b[this.currentSortAttendance] === undefined ? -1 : (a[this.currentSortAttendance] === null || a[this.currentSortAttendance] === undefined ? 1 : b[this.currentSortAttendance].localeCompare(a[this.currentSortAttendance], 'cz'))) 
                        : (a[this.currentSortAttendance] === null || a[this.currentSortAttendance] === undefined ? -1 : (b[this.currentSortAttendance] === null || b[this.currentSortAttendance] === undefined ? 1 : a[this.currentSortAttendance].localeCompare(b[this.currentSortAttendance], 'cz'))))
            }
        },
        methods: {
            showSavingDataMessage(value) {
                this.$emit('show-saving-message', value)
            },
            showDocumentGenerationMessage(value) {
                this.$emit('show-document-generation-message', value)
            },
            async load() {
                try {
                    let response = await api.getTeacherByUserId(this.idUser, this.idSchool)
                    this.idTeacher = response.data.id
                }
                catch (error) {
                    console.log('Error: ', error)
                }
            },
            async updateSupportTable() {
                if (!this.isCoordinator)
                    this.headersSupport = { "nazev": "Název", "stavNazev": "Stav" }
                else
                    this.headersSupport = { "nazev": "Název", "ucitel": "Učitel", "stavNazev": "Stav" }

                try {
                    let response = await api.getSupportBySchool(this.idSchool, !this.isCoordinator ? this.idTeacher : -1)
                    this.rowsSupport = response.data
                }
                catch (error) {
                    console.log('Error: ', error)
                }
            },
            sortSupport(s) {
                if (s === this.currentSortSupport) {
                    this.currentSortDirSupport = this.currentSortDirSupport === 'asc' ? 'desc' : 'asc'
                }
                else {
                    this.currentSortSupport = s
                    this.currentSortDirSupport = 'asc'
                }
            },
            newSupport() {
                this.selectedSupport = -1
                this.showSupportCard = true
            },
            openSupportCard(id) {
                this.selectedSupport = id
                this.showSupportCard = true
            },
            closeSupportCard() {
                this.showSupportCard = false
                this.updateSupportTable()
            },
            async updateCooperationTable() {
                if (!this.isCoordinator)
                    this.headersCooperation = { "nazev": "Název", "stavNazev": "Stav" }
                else
                    this.headersCooperation = { "nazev": "Název", "ucitel": "Učitel", "stavNazev": "Stav" }

                try {
                    let response = await api.getCooperationBySchool(this.idSchool, !this.isCoordinator ? this.idTeacher : -1)
                    this.rowsCooperation = response.data
                }
                catch (error) {
                    console.log('Error: ', error)
                }
            },
            sortCooperation(s) {
                if (s === this.currentSortCooperation) {
                    this.currentSortDirCooperation = this.currentSortDirCooperation === 'asc' ? 'desc' : 'asc'
                }
                else {
                    this.currentSortCooperation = s
                    this.currentSortDirCooperation = 'asc'
                }
            },
            newCooperation() {
                this.selectedCooperation = -1
                this.showCooperationCard = true
            },
            openCooperationCard(id) {
                this.selectedCooperation = id
                this.showCooperationCard = true
            },
            closeCooperationCard() {
                this.showCooperationCard = false
                this.updateCooperationTable()
            },
            async updateAttendanceTable() {
                if (!this.isCoordinator)
                    this.headersAttendance = { "nazev": "Název akce", "stavNazev": "Stav" }
                else
                    this.headersAttendance = { "nazev": "Název akce", "ucitel": "Učitel", "stavNazev": "Stav" }

                try {
                    let response = await api.getAttendanceBySchool(this.idSchool, !this.isCoordinator ? this.idTeacher : -1)
                    this.rowsAttendance = response.data
                }
                catch (error) {
                    console.log('Error: ', error)
                }
            },
            sortAttendance(s) {
                if (s === this.currentSortAttendance) {
                    this.currentSortDirAttendance = this.currentSortDirAttendance === 'asc' ? 'desc' : 'asc'
                }
                else {
                    this.currentSortAttendance = s
                    this.currentSortDirAttendance = 'asc'
                }
            },
            newAttendance() {
                this.selectedAttendance = -1
                this.showAttendanceCard = true
            },
            openAttendanceCard(id) {
                this.selectedAttendance = id
                this.showAttendanceCard = true
            },
            closeAttendanceCard() {
                this.showAttendanceCard = false
                this.updateAttendanceTable()
            },
            changeTabPage(page, change = false) {
                if (!change) {
                    if ((this.showAttendanceCard && (this.selectedAttendance == -1 || this.rowsAttendance.filter(a => a.id == this.selectedAttendance)[0].stav == 1))
                        || (this.showCooperationCard && (this.selectedCooperation == -1 || this.rowsCooperation.filter(a => a.id == this.selectedCooperation)[0].stav == 1))
                        || (this.showSupportCard && (this.selectedSupport == -1 || this.rowsSupport.filter(a => a.id == this.selectedSupport)[0].stav == 1))) {
                        this.confirm(page, 'Uložit změny?')
                        return
                    }
                }

                this.showSupportCard = false
                this.showSupport = false
                this.showCooperationCard = false
                this.showCooperation = false
                this.showAttendanceCard = false
                this.showAttendance = false

                switch (page) {
                    case 1:
                        this.showSupport = true
                        this.updateSupportTable()
                        break
                    case 2:
                        this.showCooperation = true
                        this.updateCooperationTable()
                        break
                    case 3:
                        this.showAttendance = true;
                        this.updateAttendanceTable()
                        break
                }
            },
            confirm(page, message) {
                this.confirmType = page
                this.confirmMessage = message
                this.showConfirmBox = true
            },
            async closeConfirmBox(dialogResult, dialogType) {
                this.showConfirmBox = false
                this.confirmType = -1

                if (dialogResult == 1) {
                    if (this.showSupportCard)
                        await this.$refs.refSupportComponent.save()
                    if (this.showCooperationCard)
                        await this.$refs.refCooperationComponent.save()
                    if (this.showAttendanceCard)
                        await this.$refs.refAttendanceComponent.save()

                    this.changeTabPage(dialogType, true)
                }
                else
                    this.changeTabPage(dialogType, true)
            }
        },
        async created() {
            await this.load()
        }
    }
</script>