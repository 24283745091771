<template>
    <div class="rows" style="gap: 20px;">
        <div class="cols" style="gap: 5px;">
            <button class="button-small" @click="close(true)">Zavřít</button>
            <button v-if="attendance.stav <= 1" class="button-small" @click="save()">Uložit</button>
            <button v-if="attendance.stav <= 1" class="button-small" @click="saveAndClose()">Uložit a zavřít</button>
            <button v-if="attendance.stav == 1 && !isCoordinator" class="button-small" @click="sendToApprove()" style="margin-left: 20px;">Předat ke schválení</button>
            <button v-if="attendance.stav == 1 && isCoordinator" class="button-small" @click="sendToApproveAdmin()" style="margin-left: 20px;">Předat ke schválení</button>
        </div>

        <div>
            <p>
                <b>Pokyny k vyplnění</b>
                <br />
                Do záložky <b>Účastníci</b> vyplňte jména žáků (příjmení a jméno). Při opuštění záložky Účastníci se jména seřadí abecedně.
                <br />
                V záložce <b>Aktivity</b> přidejte křížkem 1 řádek = 1 den. V případě, že se během dne bude střídat téma nebo forma (např. dopoledne projekt. den ve škole, odpoledne projekt. den mimo školu), je potřeba každý blok výuky zadat do samostatného řádku. V 1 řádku jsou možné max. 3 bloky výuky. Čas od-do pište bez přestávek (posuňte např. začátek výuky o čas přestávek). Ve sloupci <b>Počet hodin</b> musí být celé číslo ‒ v opačném případě je chyba v zadaném času od-do. <b>Jména pracovníků</b> vybírejte ze seznamu, zadáním plus přidáváte další osoby. <b>Jména odborníků</b> vypište (v pořadí jméno a příjmení).
            </p>
        </div>

        <div class="cols" style="gap: 5px;">
            <button :class="'button-small' + (!this.showDefault ? ' button-inverse' : '')" @click="changeTabPage(0)">Základní údaje</button>
            <button :class="'button-small' + (!this.showParticipants ? ' button-inverse' : '')" @click="changeTabPage(1)">Účastníci</button>
            <button :class="'button-small' + (!this.showActivity ? ' button-inverse' : '')" @click="changeTabPage(2)">Aktivity</button>
            <button :class="'button-small' + (!this.showAttendance ? ' button-inverse' : '')" @click="changeTabPage(3)">Docházka</button>
            <button v-if="this.attendance.id !== -1" :class="'button-small' + (!this.showAttachements ? ' button-inverse' : '')" @click="changeTabPage(4)">Přílohy (třídnice)</button>
        </div>

        <div v-if="showDefault">
            <table class="table-border-none">
                <tr>
                    <td style="padding-left: 0;">Název akce:</td>
                    <td style="padding-right: 5px;"><input v-model="attendance.nazev" style="width: 200px;" /><span class="required">*</span></td>
                </tr>
            </table>
        </div>

        <div v-if="showParticipants">
            <table class="table-border-none">
                <tr><th>&nbsp;</th><th>&nbsp;</th><th style="width: 230px;">Příjmení</th><th style="width: 230px;">Jméno</th></tr>
                <tr v-for="(p, i) in this.attendance.data.dochazka" :key="i">
                    <td><span @click="removeParticipant(i)" class="select-list-button-inverse">-</span></td>
                    <td>{{ i + 1 }}</td>
                    <td><input v-model="p.ucastnik.prijmeni" style="width: 200px;" /></td>
                    <td><input v-model="p.ucastnik.jmeno" style="width: 200px;" /></td>
                </tr>
                <tr><td><span @click="addParticipant" class="select-list-button-inverse">+</span></td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
            </table>
        </div>

        <div v-if="showActivity">
            <table class="table-border-none" style="border-collapse: separate; border-spacing: 0 20px;">
                <tr>
                    <th>&nbsp;</th>
                    <th>Datum</th>
                    <th>Od - Do</th>
                    <th>Od - Do</th>
                    <th>Od - Do</th>
                    <th><div style="text-align: center;">Počet hodin<br />{{ getTotalSchoolHours() }}</div></th>
                    <th>Forma vzdělávání</th>
                    <th>Téma vzdělávání</th>
                    <th>Jména pracovníků<br />zapojených do aktivity</th>
                    <th>Jména odborníků<br />zapojených do aktivity</th>
                </tr>
                <tr v-for="(a, i) in this.attendance.data.aktivity" :key="i">
                    <td style="vertical-align: top;"><span @click="removeActivity(i)" class="select-list-button-inverse">-</span></td>
                    <td style="vertical-align: top;">
                        <VueDatePicker
                            v-model="a.datum"
                            locale="cs"
                            :auto-apply="true"
                            :enable-time-picker="false"
                            :hide-input-icon="true"
                            :text-input="true"
                            format="d.M.yyyy"
                            timezone="UTC"
                            utc="preserve"
                            class="datepicker"
                            six-weeks="append"
                        />
                    </td>
                    <td style="vertical-align: top;">
                        <VueDatePicker
                            v-model="a.cas11"
                            time-picker
                            :hide-input-icon="true"
                            :text-input="true"
                            select-text="Vybrat"
                            cancel-text="Zrušit"
                            class="timepicker"
                            minutes-increment="5"
                            :min-time="{ hours: 6, minutes: 0 }"
                            :start-time="{ hours: 13, minutes: 0 }"
                        />
                        <div style="margin-top: 5px;"></div>
                        <VueDatePicker
                            v-model="a.cas12"
                            time-picker
                            :hide-input-icon="true"
                            :text-input="true"
                            select-text="Vybrat"
                            cancel-text="Zrušit"
                            class="timepicker"
                            minutes-increment="5"
                            :min-time="{ hours: 6, minutes: 0 }"
                            :start-time="{ hours: 13, minutes: 0 }"
                        />
                    </td>
                    <td style="vertical-align: top;">
                        <VueDatePicker
                            v-model="a.cas21"
                            time-picker
                            :hide-input-icon="true"
                            :text-input="true"
                            select-text="Vybrat"
                            cancel-text="Zrušit"
                            class="timepicker"
                            minutes-increment="5"
                            :min-time="{ hours: 6, minutes: 0 }"
                            :start-time="{ hours: 13, minutes: 0 }"
                        />
                        <div style="margin-top: 5px;"></div>
                        <VueDatePicker
                            v-model="a.cas22"
                            time-picker
                            :hide-input-icon="true"
                            :text-input="true"
                            select-text="Vybrat"
                            cancel-text="Zrušit"
                            class="timepicker"
                            minutes-increment="5"
                            :min-time="{ hours: 6, minutes: 0 }"
                            :start-time="{ hours: 13, minutes: 0 }"
                        />
                    </td>
                    <td style="vertical-align: top;">
                        <VueDatePicker
                            v-model="a.cas31"
                            time-picker
                            :hide-input-icon="true"
                            :text-input="true"
                            select-text="Vybrat"
                            cancel-text="Zrušit"
                            class="timepicker"
                            minutes-increment="5"
                            :min-time="{ hours: 6, minutes: 0 }"
                            :start-time="{ hours: 13, minutes: 0 }"
                        />
                        <div style="margin-top: 5px;"></div>
                        <VueDatePicker
                            v-model="a.cas32"
                            time-picker
                            :hide-input-icon="true"
                            :text-input="true"
                            select-text="Vybrat"
                            cancel-text="Zrušit"
                            class="timepicker"
                            minutes-increment="5"
                            :min-time="{ hours: 6, minutes: 0 }"
                            :start-time="{ hours: 13, minutes: 0 }"
                        />
                    </td>
                    <td style="vertical-align: top;"><span :class="this.getSchoolHours(a) > 9 ? 'color-red font-bold' : ''">{{ getSchoolHours(a) }}</span></td>
                    <td style="vertical-align: top;">
                        <select v-model="a.forma" style="width: 200px;">
                            <option value="">&lt; vyberte &gt;</option>
                            <option value="Projektová výuka ve škole">Projektová výuka ve škole</option>
                            <option value="Projektová výuka mimo školu">Projektová výuka mimo školu</option>
                            <option value="Tandemová výuka">Tandemová výuka</option>
                            <option value="Vzdělávání s využitím nových technologií">Vzdělávání s využitím nových technologií</option>
                            <option value="Zážitková pedagogika">Zážitková pedagogika</option>
                            <option value="Propojování formálního a neformálního vzdělávání">Propojování formálního a neformálního vzdělávání</option>
                            <option value="Aktivizující metody">Aktivizující metody</option>
                        </select>
                    </td>
                    <td style="vertical-align: top;">
                        <select v-model="a.tema" style="width: 200px;">
                            <option value="">&lt; vyberte &gt;</option>
                            <option value="Cizí jazyky/komunikace v cizím jazyce">Cizí jazyky/komunikace v cizím jazyce</option>
                            <option value="Čtenářská pre/gramotnost">Čtenářská pre/gramotnost</option>
                            <option value="EVVO a vzdělávání pro udržitelný rozvoj">EVVO a vzdělávání pro udržitelný rozvoj</option>
                            <option value="Genderová tematika v obsahu vzdělávání">Genderová tematika v obsahu vzdělávání</option>
                            <option value="Historické povědomí, výuka moderních dějin">Historické povědomí, výuka moderních dějin</option>
                            <option value="Inkluze včetně primární prevence">Inkluze včetně primární prevence</option>
                            <option value="Kariérové poradenství včetně identifikace a rozvoje nadání">Kariérové poradenství včetně identifikace a rozvoje nadání</option>
                            <option value="Kulturní povědomí a vyjádření">Kulturní povědomí a vyjádření</option>
                            <option value="Matematická pre/gramotnost">Matematická pre/gramotnost</option>
                            <option value="Mediální gramotnost">Mediální gramotnost</option>
                            <option value="Občanské vzdělávání a demokratické myšlení">Občanské vzdělávání a demokratické myšlení</option>
                            <option value="Přírodovědné a technické vzdělávání">Přírodovědné a technické vzdělávání</option>
                            <option value="Rozvoj podnikavosti a kreativity">Rozvoj podnikavosti a kreativity</option>
                            <option value="Umělecká gramotnost">Umělecká gramotnost</option>
                            <option value="Vzdělávání s využitím nových technologií">Vzdělávání s využitím nových technologií</option>
                            <option value="Well-being a psychohygiena">Well-being a psychohygiena</option>                            
                        </select>
                    </td>
                    <td style="vertical-align: top;">
                        <div class="rows" style="gap: 5px;">
                            <div v-for="(p, k) in a.pracovnici" :key="k" style="width: 190px;">
                                <select v-model="a.pracovnici[k]" style="width: 150px;">
                                    <option :value="-1">&lt; vyberte &gt;</option>
                                    <option v-for="(s, m) in sortedPracovnik" :key="m" :value="s.id">{{ s.prijmeni + ' ' + s.jmeno }}</option>
                                </select>
                                <span @click="removePracovnik(i, k)" class="select-list-button-inverse" style="margin-left: 5px;">-</span>
                            </div>
                            <div><span @click="addPracovnik(i)" class="select-list-button-inverse">+</span></div>
                        </div>
                    </td>
                    <td style="vertical-align: top;">
                        <div class="rows" style="gap: 5px;">
                            <div v-for="(o, k) in a.odbornici" :key="k" style="width: 190px;">
                                <input v-model="a.odbornici[k]" style="width: 150px;" />
                                <span @click="removeOdbornik(i, k)" class="select-list-button-inverse" style="margin-left: 5px;">-</span>
                            </div>
                            <div><span @click="addOdbornik(i)" class="select-list-button-inverse">+</span></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <span @click="addActivity(1, false)" class="select-list-button-wide">+ 1</span>&nbsp;<span @click="addActivity(5, false)" class="select-list-button-wide">+ 5</span>
                        <br />
                        <span @click="addActivity(1, true)" class="select-list-button-wide">+ 1 K</span>&nbsp;<span @click="addActivity(5, true)" class="select-list-button-wide">+ 5 K</span>
                    </td>
                    <td colspan="8">&nbsp;</td>
                </tr>
            </table>
        </div>

        <div v-if="showAttendance">
            <table>
                <tr>
                    <td colspan="2" style="vertical-align: bottom;"><i>všichni v daný den</i></td>
                    <th v-for="(a, i) in this.attendanceColumns" :key="i" style="text-align: center;">
                        {{ a.datum }}<br />{{ a.cas1 + ' - ' + a.cas2 }}<br /><input type="checkbox" v-model="a.fullColumn" @change="selectColumn(i)" />
                    </th>
                    <td colspan="2" style="vertical-align: bottom;">&nbsp;</td>
                </tr>
                <tr v-for="(d, i) in this.attendance.data.dochazka" :key="i" :class="i % 2 !== 0 ? 'background-silver' : ''">
                    <td>{{ i + 1 }}</td>
                    <td>{{ d.ucastnik.prijmeni + ' ' + d.ucastnik.jmeno }}</td>
                    <td v-for="(s, j) in d.ucast" :key="j" style="text-align: center;">
                        <input type="checkbox" v-model="s.ucast" />
                    </td>
                    <td>{{ d.ucastnik.prijmeni + ' ' + d.ucastnik.jmeno }}</td>
                    <td>{{ i + 1 }}</td>
                </tr>
            </table>
        </div>

        <div v-if="showAttachements">
            <FilesComponent :path="idSchool + '/sablony/3/' + selectedAttendance" :prefix="getFilePrefix" />
        </div>

        <div v-if="showActivity" class="cols" style="gap: 5px; margin-top: 50px; margin-bottom: 20px;">
            <button class="button-small" @click="close(true)">Zavřít</button>
            <button v-if="attendance.stav <= 1" class="button-small" @click="save()">Uložit</button>
            <button v-if="attendance.stav <= 1" class="button-small" @click="saveAndClose()">Uložit a zavřít</button>
        </div>
    </div>

    <div v-if="showMessage" class="saving-data">
        <div class="cols justify-center align-center fullscreen">
            <div class="rows gap-10 message-box">
                <h3>Generuji docházku...</h3>
            </div>
        </div>
    </div>

    <MessageBoxComponent v-if="showMessageBox" :message="messageText" @close-event="closeMessageBox" />
    <ConfirmBoxComponent v-if="showConfirmBox" :message="confirmMessage" :dialogType="confirmType" @close-event="closeConfirmBox" />
</template>

<script>
    import api from '@/api/api.js'
    import VueDatePicker from '@vuepic/vue-datepicker'
    import '@vuepic/vue-datepicker/dist/main.css'
    import dayjs from 'dayjs'
    
    import MessageBoxComponent from '../dialogs/MessageBoxComponent.vue'
    import ConfirmBoxComponent from '../dialogs/ConfirmBoxComponent.vue'
    import FilesComponent from '../files/FilesComponent.vue'

    export default {
        name: 'AttendanceComponent',
        emits: ['show-saving-message', 'close-card'],
        props: ['selectedAttendance', 'idUser', 'idSchool', 'isCoordinator'],
        expose: ['save'],
        components: {
            VueDatePicker,
            MessageBoxComponent,
            ConfirmBoxComponent,
            FilesComponent
        },
        data() {
            return {
                showDefault: true,
                showParticipants: false,
                showActivity: false,
                showAttendance: false,
                showAttachements: false,
                showMessage: false,
                isDirty: false,
                attendance: {
                    id: -1,
                    nazev: '',
                    idSkola: -1,
                    idUcitel: -1,
                    upravil: '',
                    stav: 0,
                    data: {
                        nazev: '',
                        ucastnici: [],
                        aktivity: [],
                        dochazka: []
                    }
                },
                pracovnici: [],
                attendanceColumns: [],
                showMessageBox: false,
                messageText: "",
                showConfirmBox: false,
                confirmMessage: "",
                confirmType: -1,
                removeIndex: -1
            }
        },
        watch: {
            showParticipants(newVal, oldVal) {
                if (oldVal !== newVal && !newVal)
                    this.sortParticipants()
            },
            showActivity(newVal, oldVal) {
                if (oldVal !== newVal && !newVal)
                    this.sortActivity()
            }
        },
        computed: {
            sortedPracovnik() {
                return this.pracovnici
                    .slice()
                    .sort((a, b) => (a.prijmeni + ' ' + a.jmeno).localeCompare(b.prijmeni + ' ' + b.jmeno, 'cz'))
            },
            getFilePrefix() {
                let nazev = this.attendance.nazev.replace(/ /g, '_')
                nazev = nazev.length > 20 ? nazev.slice(0, 17) + "…" : nazev
                
                return '3_TK_' + nazev + '_'
            }
        },
        methods: {
            showSavingDataMessage(value) {
                this.$emit('show-saving-message', value)
            },
            async load() {
                this.attendance.id = this.selectedAttendance

                try {
                    let response  = await api.getTeacher(this.idSchool)
                    this.pracovnici = response.data
                }
                catch (error) {
                    console.log('Error: ', error)
                }

                if (this.attendance.id == -1) {
                    try {
                        let response  = await api.getTeacherByUserId(this.idUser, this.idSchool)
                        this.attendance.idSkola = this.idSchool
                        this.attendance.idUcitel = response.data.id
                        this.attendance.upravil = response.data.prijmeni + ' ' + response.data.jmeno
                    }
                    catch (error) {
                        console.log('Error: ', error)
                    }
                }
                else {
                    try {
                        let response  = await api.getAttendance(this.selectedAttendance)
                        this.attendance = response.data
                    }
                    catch (error) {
                        console.log('Error: ', error)
                    }
                }
            },
            formatDate(date) {
                return date !== null ? dayjs(date).format('D.M.YYYY') : ''
            },
            getSchoolHours(a) {
                let hours = 0.0
                if (a.cas11 != null && a.cas12 != null) {
                    let minutes = a.cas12.hours * 60 + a.cas12.minutes - a.cas11.hours * 60 - a.cas11.minutes
                    hours += minutes / 45
                }
                if (a.cas21 != null && a.cas22 != null) {
                    let minutes = a.cas22.hours * 60 + a.cas22.minutes - a.cas21.hours * 60 - a.cas21.minutes
                    hours += minutes / 45
                }
                if (a.cas31 != null && a.cas32 != null) {
                    let minutes = a.cas32.hours * 60 + a.cas32.minutes - a.cas31.hours * 60 - a.cas31.minutes
                    hours += minutes / 45
                }
                return hours % 1 != 0 ? hours.toFixed(2) : hours
            },
            getTotalSchoolHours() {
                let hours = 0.0
                for (let d = 0; d < this.attendance.data.aktivity.length; d++) {
                    let a = this.attendance.data.aktivity[d]
                    if (a.cas11 != null && a.cas12 != null) {
                        let minutes = a.cas12.hours * 60 + a.cas12.minutes - a.cas11.hours * 60 - a.cas11.minutes
                        hours += minutes / 45
                    }
                    if (a.cas21 != null && a.cas22 != null) {
                        let minutes = a.cas22.hours * 60 + a.cas22.minutes - a.cas21.hours * 60 - a.cas21.minutes
                        hours += minutes / 45
                    }
                    if (a.cas31 != null && a.cas32 != null) {
                        let minutes = a.cas32.hours * 60 + a.cas32.minutes - a.cas31.hours * 60 - a.cas31.minutes
                        hours += minutes / 45
                    }
                }
                return hours % 1 != 0 ? hours.toFixed(2) : hours
            },
            async save() {
                if (this.attendance.nazev.length > 0) {
                    try {
                        if (this.isDirty) {
                            this.showMessage = true                    
                            this.generateAttendanceColumns()
                            this.generateAttendance()
                            this.showMessage = false
                        }

                        this.showSavingDataMessage(true)

                        this.attendance.data.nazev = this.attendance.nazev

                        if (this.attendance.id !== -1) {
                            await api.putAttendance(this.attendance)
                        }
                        else {
                            let response = await api.postAttendance(this.attendance)
                            if (response.status == 200)
                                this.attendance.id = response.data
                        }

                        this.showSavingDataMessage(false)
                    }
                    catch (error) {
                        this.showSavingDataMessage(false)
                        console.log('Error: ', error)
                        this.message("Nastala chyba při ukládání dat")
                        return false
                    }
                }
                else{
                    this.message('Vyplňte všechny povinné položky!')
                    return false
                }
                return true
            },
            close(ask = false) {
                if (ask)
                    this.confirm(5, 'Uložit změny?')
                else
                    this.$emit('close-card')
            },
            async saveAndClose() {
                if (await this.save())
                    this.close()
            },
            async sendToApprove() {
                if (await this.save())
                    this.confirm(3, 'Šablona bude odeslána ke schválení koordinátorovi. Pokračovat?')
            },
            async sendToApproveAdmin() {
                if (await this.save())
                    this.confirm(4, 'Šablona bude odeslána ke schválení administrátorovi. Pokračovat?')
            },
            changeTabPage(page) {
                this.showDefault = false
                this.showParticipants = false
                this.showActivity = false
                this.showAttendance = false
                this.showAttachements = false

                switch (page) {
                    case 1:
                        this.showParticipants = true
                        break
                    case 2:
                        this.showActivity = true
                        break
                    case 3:
                        this.showMessage = true
                        this.generateAttendanceColumns()
                        this.generateAttendance()
                        this.showAttendance = true
                        this.showMessage = false
                        break
                    case 4:
                        this.showAttachements = true
                        break
                    default:
                        this.showDefault = true
                        break
                }
            },
            addParticipant() {
                this.attendance.data.dochazka.push({ ucastnik: { prijmeni: '', jmeno: '' }, ucast: [] })
                this.isDirty = true
            },
            removeParticipant(index) {
                this.removeIndex = index
                this.confirm(1, 'Chcete smazat účastníka?')
            },
            sortParticipants() {
                this.attendance.data.dochazka.sort((a, b) => (a.ucastnik.prijmeni + ' ' + a.ucastnik.jmeno).localeCompare(b.ucastnik.prijmeni + ' ' + b.ucastnik.jmeno, 'cz'))
            },
            addActivity(count, copyPrevious) {
                let p = []
                let f = ""
                let t = ""
                let index = this.attendance.data.aktivity.length - 1

                for (let c = 0; c < count; c++) {
                    if (copyPrevious && index >= 0) {
                        if (this.attendance.data.aktivity[index].pracovnici.length > 0)
                            p = this.attendance.data.aktivity[index].pracovnici.slice()
                        f = this.attendance.data.aktivity[index].forma
                        t = this.attendance.data.aktivity[index].tema
                    }

                    this.attendance.data.aktivity.push({ datum: null, cas11: null, cas12: null, cas21: null, cas22: null, cas31: null, cas32: null, forma: f, tema: t,
                        pracovnici: p, pracovniciJmena: [], odbornici: [] })
                }
                this.isDirty = true
            },
            removeActivity(index) {
                this.removeIndex = index
                this.confirm(2, 'Chcete smazat aktivitu?')
            },
            sortActivity() {
                for (let a = 0; a < this.attendance.data.aktivity.length; a++) {
                    let switchValues = false
                    if (this.attendance.data.aktivity[a].cas11 != null && this.attendance.data.aktivity[a].cas12 != null) {
                        if (this.attendance.data.aktivity[a].cas11.hours > this.attendance.data.aktivity[a].cas12.hours)
                            switchValues = true
                        else if (this.attendance.data.aktivity[a].cas11.hours == this.attendance.data.aktivity[a].cas12.hours
                            && this.attendance.data.aktivity[a].cas11.minutes > this.attendance.data.aktivity[a].cas12.minutes)
                            switchValues = true
                        if (switchValues) {
                            let temp = this.attendance.data.aktivity[a].cas11
                            this.attendance.data.aktivity[a].cas11 = this.attendance.data.aktivity[a].cas12
                            this.attendance.data.aktivity[a].cas12 = temp
                        }
                    }
                    
                    switchValues = false
                    if (this.attendance.data.aktivity[a].cas21 != null && this.attendance.data.aktivity[a].cas22 != null) {
                        if (this.attendance.data.aktivity[a].cas21.hours > this.attendance.data.aktivity[a].cas22.hours)
                            switchValues = true
                        else if (this.attendance.data.aktivity[a].cas21.hours == this.attendance.data.aktivity[a].cas22.hours
                            && this.attendance.data.aktivity[a].cas21.minutes > this.attendance.data.aktivity[a].cas22.minutes)
                            switchValues = true
                        if (switchValues) {
                            let temp = this.attendance.data.aktivity[a].cas21
                            this.attendance.data.aktivity[a].cas21 = this.attendance.data.aktivity[a].cas22
                            this.attendance.data.aktivity[a].cas22 = temp
                        }
                    }
                    
                    switchValues = false
                    if (this.attendance.data.aktivity[a].cas31 != null && this.attendance.data.aktivity[a].cas32 != null) {
                        if (this.attendance.data.aktivity[a].cas31.hours > this.attendance.data.aktivity[a].cas32.hours)
                            switchValues = true
                        else if (this.attendance.data.aktivity[a].cas31.hours == this.attendance.data.aktivity[a].cas32.hours
                            && this.attendance.data.aktivity[a].cas31.minutes > this.attendance.data.aktivity[a].cas32.minutes)
                            switchValues = true
                        if (switchValues) {
                            let temp = this.attendance.data.aktivity[a].cas31
                            this.attendance.data.aktivity[a].cas31 = this.attendance.data.aktivity[a].cas32
                            this.attendance.data.aktivity[a].cas32 = temp
                        }
                    }
                }

                this.attendance.data.aktivity.sort((a, b) => dayjs(a.datum).toDate() - dayjs(b.datum).toDate())
            },
            addPracovnik(index) {
                this.attendance.data.aktivity[index].pracovnici.push(-1)
            },
            removePracovnik(indexA, indexP) {
                this.attendance.data.aktivity[indexA].pracovnici.splice(indexP, 1)
            },
            addOdbornik(index) {
                this.attendance.data.aktivity[index].odbornici.push("")
            },
            removeOdbornik(indexA, indexO) {
                this.attendance.data.aktivity[indexA].odbornici.splice(indexO, 1)
            },
            generateAttendanceColumns() {
                this.attendanceColumns = []
                for (let a = 0; a < this.attendance.data.aktivity.length; a++) {
                    let ak = this.attendance.data.aktivity[a]
                    if (ak.datum !== null) {
                        let cas1 = ''
                        let cas2 = ''

                        if (ak.cas11 != null)
                            cas1 = ak.cas11.hours + ':' + ('00' + ak.cas11.minutes).slice(-2)
                        else if (ak.cas21 != null)
                            cas1 = ak.cas21.hours + ':' + ('00' + ak.cas21.minutes).slice(-2)
                        else if (ak.cas31 != null)
                            cas1 = ak.cas31.hours + ':' + ('00' + ak.cas31.minutes).slice(-2)

                        if (ak.cas12 != null)
                            cas2 = ak.cas12.hours + ':' + ('00' + ak.cas12.minutes).slice(-2)
                        if (ak.cas22 != null)
                            cas2 = ak.cas22.hours + ':' + ('00' + ak.cas22.minutes).slice(-2)
                        if (ak.cas32 != null)
                            cas2 = ak.cas32.hours + ':' + ('00' + ak.cas32.minutes).slice(-2)
                        
                        this.attendanceColumns.push({
                            datum: this.formatDate(ak.datum),
                            cas1: cas1,
                            cas2: cas2,
                            fullColumn: false
                        })
                    }
                }
            },
            generateAttendance() {
                for (let d = 0; d < this.attendance.data.dochazka.length; d++) {
                    if (this.attendanceColumns.length != this.attendance.data.dochazka[d].ucast.length) {
                        let ac = []
                        for (let a = 0; a < this.attendanceColumns.length; a++) {
                            ac.push({
                                datumCasOdDo: { datum: this.attendanceColumns[a].datum, cas1: this.attendanceColumns[a].cas1, cas2: this.attendanceColumns[a].cas2 },
                                ucast: false
                            })
                        }
                        this.attendance.data.dochazka[d].ucast = ac
                    }
                    else {
                        for (let a = 0; a < this.attendanceColumns.length; a++) {
                            this.attendance.data.dochazka[d].ucast[a].datumCasOdDo = { datum: this.attendanceColumns[a].datum, cas1: this.attendanceColumns[a].cas1, cas2: this.attendanceColumns[a].cas2 }
                        }
                    }
                }
                
                this.isDirty = false
            },
            selectColumn(index) {
                for (let d = 0; d < this.attendance.data.dochazka.length; d++) {
                    this.attendance.data.dochazka[d].ucast[index].ucast = this.attendanceColumns[index].fullColumn
                }
            },
            message(message) {
                this.messageText = message
                this.showMessageBox = true
            },
            async closeMessageBox() {
                this.showMessageBox = false
            },
            confirm(type, message) {
                this.confirmType = type
                this.confirmMessage = message
                this.showConfirmBox = true
            },
            async closeConfirmBox(dialogResult, dialogType) {
                this.showConfirmBox = false
                this.confirmType = -1

                if (dialogResult == 1) {
                    switch (dialogType) {
                        case 1:
                            this.attendance.data.dochazka.splice(this.removeIndex, 1)
                            this.isDirty = true
                            this.removeIndex = -1
                            break
                            
                        case 2:
                            this.attendance.data.aktivity.splice(this.removeIndex, 1)
                            this.isDirty = true
                            this.removeIndex = -1
                            break

                        case 3:
                            await api.sendAttendanceToApproveByCoordinator(this.attendance.id)
                            this.close()
                            break

                        case 4:
                            await api.sendAttendanceToApproveByAdmin(this.attendance.id)
                            this.close()
                            break

                        case 5:
                            this.saveAndClose()
                            break
                    }
                }
                else {
                    switch (dialogType) {
                        case 5:
                            this.close()
                            break
                    }
                }
            }
        },
        async created() {
            await this.load()
        }
    }
</script>