<template>
    <div v-if="!showCard">
        <button class="button-small" @click="newTeacher()">Nový záznam</button>
        <select v-if="!isCoordinator" v-model="selectedSchool" style="width: 200px; margin-left: 20px;">
            <option :value="-1">&lt; vyberte školu &gt;</option>
            <option v-for="(t, i) in sortedSkola" :key="i" :value="t.id">{{ t.nazev }}</option>
        </select>
        <br />
        <table style="margin-top: 20px;">
            <thead>
                <tr>
                    <th v-for="(h, k) in headers" :key="k" @click="sort(k)">{{ h }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, i) in sortedRows" :key="i" >
                    <td v-for="(header, j) in headerKeys" :key="j" @click="openCard(row.id)">
                        <span v-if="header === 'celeJmeno'">{{ (row.titulPred.length > 0 ? row.titulPred + ' ' : '') + row.jmeno + ' ' + row.prijmeni 
                            + (row.titulZa.length > 0 ? ', ' + row.titulZa : '') }}</span>
                        <span v-else-if="header === 'datumNarozeni'">{{ formatDate(row.datumNarozeni) }}</span>
                        <span v-else-if="header === 'aktivni'">{{ row.aktivni ? 'ano' : 'ne' }}</span>
                        <span v-else>{{ row[header] }}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div v-if="showCard">
        <div class="cols" style="gap: 5px;">
            <button class="button-small" @click="closeCard(true)">Zavřít</button>
            <button class="button-small" @click="saveCard()">Uložit</button>
            <button class="button-small" @click="saveAndCloseCard()">Uložit a zavřít</button>
        </div>

        <table class="table-border-none" style="margin-top: 20px;">
            <tr>
                <td style="padding-left: 0;">Titul před:</td>
                <td><input v-model="selectedTeacher.titulPred" style="width: 200px;" /></td>
            </tr>
            <tr>
                <td style="padding-left: 0;">Jméno:</td>
                <td><input v-model="selectedTeacher.jmeno" style="width: 200px;" /><span class="required">*</span></td>
            </tr>
            <tr>
                <td style="padding-left: 0;">Příjmení:</td>
                <td><input v-model="selectedTeacher.prijmeni" style="width: 200px;" /><span class="required">*</span></td>
            </tr>
            <tr>
                <td style="padding-left: 0;">Titul za:</td>
                <td><input v-model="selectedTeacher.titulZa" style="width: 200px;" /></td>
            </tr>
            <tr>
                <td style="padding-left: 0;">Datum narození:</td>
                <td>
                    <VueDatePicker
                        v-model="selectedTeacher.datumNarozeni"
                        locale="cs"
                        :auto-apply="true"
                        :enable-time-picker="false"
                        :hide-input-icon="true"
                        :text-input="true"
                        format="d.M.yyyy"
                        timezone="UTC"
                        utc="preserve"
                        class="datepicker"
                        six-weeks="append"
                    />
                </td>
            </tr>
            <tr>
                <td style="padding-left: 0;">E-mail:</td>
                <td><input v-model="selectedTeacher.email" style="width: 200px;" /><span class="required">*</span></td>
            </tr>
            <tr>
                <td style="padding-left: 0;">Telefon:</td>
                <td><input v-model="selectedTeacher.telefon" style="width: 200px;" /></td>
            </tr>
            <tr v-if="!isCoordinator">
                <td style="padding-left: 0;">Škola:</td>
                <td>
                    <select v-model="selectedTeacher.idSkola" style="width: 200px;">
                        <option :value="-1">&lt; vyberte &gt;</option>
                        <option v-for="(t, i) in sortedSkola" :key="i" :value="t.id">{{ t.nazev }}</option>
                    </select>
                </td>
            </tr>
            <tr>
                <td style="padding-left: 0;">Začátek práce:</td>
                <td>
                    <VueDatePicker
                        v-model="selectedTeacher.zacatek"
                        locale="cs"
                        :auto-apply="true"
                        :enable-time-picker="false"
                        :hide-input-icon="true"
                        :text-input="true"
                        format="d.M.yyyy"
                        timezone="UTC"
                        utc="preserve"
                        class="datepicker"
                        six-weeks="append"
                    />
                </td>
            </tr>
            <tr>
                <td style="padding-left: 0;">Konec práce:</td>
                <td>
                    <VueDatePicker
                        v-model="selectedTeacher.konec"
                        locale="cs"
                        :auto-apply="true"
                        :enable-time-picker="false"
                        :hide-input-icon="true"
                        :text-input="true"
                        format="d.M.yyyy"
                        timezone="UTC"
                        utc="preserve"
                        class="datepicker"
                        six-weeks="append"
                    />
                </td>
            </tr>
            <tr>
                <td style="padding-left: 0; vertical-align: top;">Notifikace:</td>
                <td>
                    <input type="checkbox" v-model="selectedTeacher.notifikace" />
                </td>
            </tr>
            <tr>
                <td style="padding-left: 0; vertical-align: top;">Aktivní:</td>
                <td>
                    <input type="checkbox" v-model="selectedTeacher.aktivni" />
                </td>
            </tr>
        </table>
    </div>

    <ConfirmBoxComponent v-if="showConfirmBox" :message="confirmMessage" :dialogType="confirmType" @close-event="closeConfirmBox" />
    <MessageBoxComponent v-if="showMessageBox" :message="messageText" @close-event="closeMessageBox" />
</template>

<script>
    import api from '@/api/api.js'
    import dayjs from 'dayjs'
    import utc from 'dayjs/plugin/utc'
    dayjs.extend(utc)
    import VueDatePicker from '@vuepic/vue-datepicker'
    import '@vuepic/vue-datepicker/dist/main.css'

    import ConfirmBoxComponent from '../dialogs/ConfirmBoxComponent.vue'
    import MessageBoxComponent from '../dialogs/MessageBoxComponent.vue'

    export default {
        name: 'TeachersComponent',
        emits: ['show-saving-message'],
        props: ['isCoordinator', 'teacherSchool'],
        components: {
            VueDatePicker,
            ConfirmBoxComponent,
            MessageBoxComponent
        },
        data() {
            return {
                headers: {},
                rows: [],
                skola: [],
                currentSort: '',
                currentSortDir: 'asc',
                selectedTeacher: {},
                selectedSchool: -1,
                showCard: false,

                showConfirmBox: false,
                confirmMessage: "",
                confirmType: -1,
                showMessageBox: false,
                messageText: ""
            }
        },
        computed: {
            headerKeys() {
                return Object.keys(this.headers)
            },
            sortedRows() {
                if (this.currentSort === '')
                    return this.rows
                if (this.currentSort === 'celeJmeno') {
                    return this.rows.slice().sort(
                        (a, b) => this.currentSortDir === 'desc' 
                            ? (b["prijmeni"] + ' ' + b["jmeno"]).localeCompare(a["prijmeni"] + ' ' + a["jmeno"], 'cz') 
                            : (a["prijmeni"] + ' ' + a["jmeno"]).localeCompare(b["prijmeni"] + ' ' + b["jmeno"], 'cz')
                    )
                }
                return this.rows.slice().sort(
                    (a, b) => this.currentSortDir === 'desc' 
                        ? (b[this.currentSort] === null || b[this.currentSort] === undefined ? -1 : (a[this.currentSort] === null || a[this.currentSort] === undefined ? 1 : b[this.currentSort].localeCompare(a[this.currentSort], 'cz'))) 
                        : (a[this.currentSort] === null || a[this.currentSort] === undefined ? -1 : (b[this.currentSort] === null || b[this.currentSort] === undefined ? 1 : a[this.currentSort].localeCompare(b[this.currentSort], 'cz'))))
            },
            sortedSkola() {
                return this.skola
                    .slice()
                    .sort((a, b) => (a.nazev).localeCompare(b.nazev, 'cz'))
            }
        },
        watch: {
            selectedSchool(newVal, oldVal) {
                if (oldVal !== newVal)
                    this.updateTable()
            }
        },
        methods: {
            showSavingDataMessage(value) {
                this.$emit('show-saving-message', value)
            },
            async load() {
                try {
                    let response = await api.getSchool()
                    this.skola = response.data
                    if (this.isCoordinator)
                        this.selectedSchool = this.teacherSchool
                }
                catch (error) {
                    console.log('Error: ', error)
                }
            },
            formatDate(date) {
                return date !== null ? dayjs(date).utcOffset(0).format('D.M.YYYY') : ''
            },
            setNoonToDate(date) {
                return date !== null ? dayjs(date).utcOffset(0).format('YYYY-MM-DDT12:00:00') : null
            },
            async updateTable() {
                if (this.selectedSchool !== -1) {
                    this.headers = { "celeJmeno": "Jméno", "email": "E-mail", "telefon": "Telefon", "datumNarozeni": "Datum narození", "aktivni": "Aktivní" }

                    try {
                        let response = await api.getTeacher(this.selectedSchool)
                        this.rows = response.data
                    }
                    catch (error) {
                        console.log('Error: ', error)
                    }
                }
                else {
                    this.headers = {}
                    this.rows = []
                }
            },
            sort(s) {
                if (s === this.currentSort) {
                    this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc'
                }
                else {
                    this.currentSort = s
                    this.currentSortDir = 'asc'
                }
            },
            openCard(id) {
                this.selectedTeacher = this.rows.filter(a => a.id == id).slice()[0]
                this.showCard = true
            },
            closeCard(ask = false)
            {
                if (ask)
                    this.confirm(1, 'Uložit změny?')
                else {
                    this.showCard = false
                    this.selectedTeacher = {}
                    this.updateTable()
                }
            },
            async saveCard()
            {
                if (this.selectedTeacher.jmeno.length > 0 && this.selectedTeacher.prijmeni.length > 0 && this.selectedTeacher.email.length > 0) {
                    this.showSavingDataMessage(true)

                    this.selectedTeacher.datumNarozeni = this.setNoonToDate(this.selectedTeacher.datumNarozeni)
                    this.selectedTeacher.zacatek = this.setNoonToDate(this.selectedTeacher.zacatek)
                    this.selectedTeacher.konec = this.setNoonToDate(this.selectedTeacher.konec)

                    try {
                        if (this.selectedTeacher.id !== -1)
                            await api.putTeacher(this.selectedTeacher)
                        else {
                            let response = await api.postTeacher(this.selectedTeacher)
                            if (response.status == 200)
                                this.selectedTeacher.id = response.data
                        }
                        this.showSavingDataMessage(false)
                    }
                    catch (error) {
                        this.showSavingDataMessage(false)
                        console.log('Error: ', error)
                        this.message("Nastala chyba při ukládání dat")
                    }
                }
                else{
                    alert('Vyplňte všechny povinné položky!')
                    return false
                }
                return true
            },
            async saveAndCloseCard()
            {
                if (await this.saveCard())
                    this.closeCard()
            },
            newTeacher() {
                this.selectedTeacher = { id: -1, jmeno: '', prijmeni: '', titulPred: '', titulZa: '', email: '', telefon: '', idSkola: this.selectedSchool, skolaNazev: '', notifikace: true, aktivni: true }
                this.showCard = true
            },
            confirm(type, message) {                
                this.confirmType = type
                this.confirmMessage = message
                this.showConfirmBox = true
            },
            async closeConfirmBox(dialogResult, dialogType) {
                this.showConfirmBox = false
                this.confirmType = -1

                if (dialogResult == 1) {
                    switch (dialogType) {
                        case 1:
                            this.saveAndCloseCard()
                            break
                    }
                }
                else {
                    switch (dialogType) {
                        case 1:
                            this.closeCard()
                            break
                    }
                }
            },
            message(message) {
                this.messageText = message
                this.showMessageBox = true
            },
            async closeMessageBox() {
                this.showMessageBox = false
            }
        },
        async created() {
            await this.load()
            this.updateTable()
        }
    }
</script>