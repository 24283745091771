<template>
    <div :class="'dropzone-container' + (isDragging ? ' dropzone-container-active' : '')" 
        @drop="onDrop" @dragover="onDragOver" @dragleave="onDragLeave">
        <input type="file" class="hidden-input" multiple name="fileInput" id="fileInput" @change="onChange" ref="fileInput" accept=".doc,.docx,.pdf,.jpg,.jpeg,.png,.tif,.tiff,.xls,.xlsx" />
        <label for="fileInput">Přetáhněte sem soubory k nahrání na server nebo <u>klikněte sem</u></label>
    </div>
</template>

<script>
    import api from '@/api/api.js'

    export default {
        name: 'DropFileComponent',
        emits: ['upload-finished'],
        props: ['path', 'prefix'],
        data() {
            return {
                files: [],
                isDragging: false
            }
        },
        methods: {
            async onChange() {
                try {
                    this.files = []
                    this.files.push(...this.$refs.fileInput.files)
                    await this.uploadFiles()
                }
                catch (error) {
                    console.log("Error: ", error)
                }
            },
            onDragOver(e) {
                e.preventDefault()
                this.isDragging = true
            },
            onDragLeave() {
                this.isDragging = false
            },
            onDrop(e) {
                e.preventDefault()
                this.$refs.fileInput.files = e.dataTransfer.files
                this.onChange()
                this.isDragging = false
            },
            async uploadFiles() {
                const files = this.files
                const formData = new FormData()
                files.forEach((file) => {
                    formData.append("selectedFiles", file);
                })

                try {
                    await api.uploadFiles(formData, this.path, this.prefix)  
                }
                catch (error) {
                    console.log("Error: ", error)
                }
              
                this.$emit('upload-finished')

                this.files = []
            }
        }
    }
</script>