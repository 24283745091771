<template>
    <div class="rows" style="gap: 20px;">
        <div class="cols" style="gap: 5px;">
            <button class="button-small" @click="close()">Zavřít</button>

            <button v-if="!isAdmin && attendance.stav == 2" class="button-small" @click="sendToApproveAdmin()" style="margin-left: 20px;">Předat ke schválení</button>
            <button v-if="isAdmin && attendance.stav == 3" class="button-small" @click="approve()" style="margin-left: 20px;">Schválit</button>

            <button v-if="!isAdmin && attendance.stav == 2" class="button-small" @click="returnToTeacher()">Vrátit učiteli</button>
            <button v-if="isAdmin && attendance.stav == 3" class="button-small" @click="returnToCoordinator()">Vrátit koordinátorovi</button>
        </div>

        <div class="cols" style="gap: 5px;">
            <button :class="'button-small' + (!this.showDefault ? ' button-inverse' : '')" @click="changeTabPage(0)">Základní údaje</button>
            <button :class="'button-small' + (!this.showParticipants ? ' button-inverse' : '')" @click="changeTabPage(1)">Účastníci</button>
            <button :class="'button-small' + (!this.showActivity ? ' button-inverse' : '')" @click="changeTabPage(2)">Aktivity</button>
            <button :class="'button-small' + (!this.showAttendance ? ' button-inverse' : '')" @click="changeTabPage(3)">Docházka</button>
            <button :class="'button-small' + (!this.showAttachements ? ' button-inverse' : '')" @click="changeTabPage(4)">Přílohy (třídnice)</button>
            <button v-if="isAdmin" :class="'button-small' + (!this.showParticipantsList ? ' button-inverse' : '')" @click="changeTabPage(5)">Seznam účastníků (výkaz)</button>
            <button v-if="isAdmin" :class="'button-small' + (!this.showActivityList ? ' button-inverse' : '')" @click="changeTabPage(6)">Seznam aktivit (výkaz)</button>
            <button v-if="isAdmin" :class="'button-small' + (!this.showOverview ? ' button-inverse' : '')" @click="changeTabPage(7)">Přehled (výkaz)</button>
        </div>

        <div v-if="showDefault">
            <table class="table-border-none">
                <tr>
                    <td style="padding-left: 0;">Název akce:</td>
                    <td style="padding-right: 5px;">{{ this.attendance.nazev }}</td>
                </tr>
            </table>
        </div>

        <div v-if="showParticipants">
            <table>
                <tr><th>&nbsp;</th><th>Příjmení a jméno</th></tr>
                <tr v-for="(p, i) in this.attendance.data.dochazka" :key="i">
                    <td>{{ i + 1 }}</td>
                    <td>{{ p.ucastnik.prijmeni + ' ' + p.ucastnik.jmeno }}</td>
                </tr>
            </table>
        </div>

        <div v-if="showActivity">
            <table>
                <tr>
                    <th>Datum</th>
                    <th>Od - Do</th>
                    <th>Od - Do</th>
                    <th>Od - Do</th>
                    <th><div style="text-align: center;">Počet hodin<br />{{ getTotalSchoolHours() }}</div></th>
                    <th>Forma vzdělávání</th>
                    <th>Téma vzdělávání</th>
                    <th>Jména pracovníků<br />zapojených do aktivity</th>
                    <th>Jména odborníků<br />zapojených do aktivity</th>
                </tr>
                <tr v-for="(a, i) in this.attendance.data.aktivity" :key="i">
                    <td style="vertical-align: top;">{{ formatDate(a.datum) }}</td>
                    <td style="vertical-align: top;">{{ formatTime(a.cas11) + ' - ' + formatTime(a.cas12) }}</td>
                    <td style="vertical-align: top;">{{ formatTime(a.cas21) + ' - ' + formatTime(a.cas22) }}</td>
                    <td style="vertical-align: top;">{{ formatTime(a.cas31) + ' - ' + formatTime(a.cas32) }}</td>
                    <td style="vertical-align: top;"><span :class="this.getSchoolHours(a) > 9 ? 'color-red font-bold' : ''">{{ getSchoolHours(a) }}</span></td>
                    <td style="vertical-align: top;">{{ a.forma }}</td>
                    <td style="vertical-align: top;">{{ a.tema }}</td>
                    <td style="vertical-align: top;">
                        <span v-for="(p, j) in a.pracovnici" :key="j">
                            {{ this.formatText(this.getPracovnik(p), (j < a.pracovnici.length - 1 ? ', ' : '')) }}
                        </span> 
                    </td>
                    <td style="vertical-align: top;">
                        <span v-for="(o, k) in a.odbornici" :key="k">
                            {{ this.formatText(o, (k < a.odbornici.length - 1 ? ', ' : '')) }}
                        </span>
                    </td>
                </tr>
            </table>
        </div>

        <div v-if="showAttendance">
            <table>
                <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <th v-for="(a, i) in this.attendanceColumns" :key="i" style="text-align: center;">{{ a.datum }}<br />{{ a.cas1 + ' - ' + a.cas2 }}</th>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
                <tr v-for="(d, i) in this.attendance.data.dochazka" :key="i" :class="i % 2 !== 0 ? 'background-silver' : ''">
                    <td>{{ i + 1 }}</td>
                    <td>{{ d.ucastnik.prijmeni + ' ' + d.ucastnik.jmeno }}</td>
                    <td v-for="(s, j) in d.ucast" :key="j" style="text-align: center;">
                        {{ s.ucast ? 'x' : '' }}
                    </td>
                    <td>{{ d.ucastnik.prijmeni + ' ' + d.ucastnik.jmeno }}</td>
                    <td>{{ i + 1 }}</td>
                </tr>
            </table>
        </div>

        <div v-if="showAttachements">
            <FilesComponent :path="attendance.idSkola + '/sablony/3/' + selectedAttendance" :prefix="getFilePrefix" />
        </div>

        <div v-if="showParticipantsList">
            <table>
                <tr><th>Příjmení a jméno</th></tr>
                <tr v-for="(p, i) in this.attendance.data.dochazka" :key="i">
                    <td>{{ p.ucastnik.prijmeni + ' ' + p.ucastnik.jmeno }}</td>
                </tr>
            </table>
        </div>

        <div v-if="showActivityList">
            <table>
                <tr>
                    <th>Datum realizace</th>
                    <th>Počet hodin</th>
                    <th>Forma vzdělávání</th>
                    <th>Téma vzdělávání</th>
                    <th>Jména pracovníků/odborníků</th>
                </tr>
                <tr v-for="(a, i) in this.attendance.data.aktivity" :key="i">
                    <td style="vertical-align: top;">{{ formatDate(a.datum) }}</td>
                    <td style="vertical-align: top;"><span :class="this.getSchoolHours(a) > 9 ? 'color-red font-bold' : ''">{{ getSchoolHours(a) }}</span></td>
                    <td style="vertical-align: top;">{{ a.forma + (a.forma == 'Projektová výuka ve škole' || a.forma == 'Projektová výuka mimo školu' ? '&nbsp;' : '') }}</td>
                    <td style="vertical-align: top;">{{ a.tema }}</td>
                    <td style="vertical-align: top;">
                        <span v-for="(p, j) in a.pracovnici" :key="j">
                            {{ this.formatText(this.getPracovnik(p), (j < a.pracovnici.length - 1 || a.odbornici.length > 0 ? ', ' : '')) }}
                        </span>
                        <span v-for="(o, k) in a.odbornici" :key="k">
                            {{ this.formatText(o, (k < a.odbornici.length - 1 ? ', ' : '')) }}
                        </span> 
                    </td>
                </tr>
            </table>
        </div>

        <div v-if="showOverview">
            <table>
                <tr>
                    <th>Číslo aktivity</th>
                    <th>Účastník</th>
                </tr>
                <tr v-for="(u, i) in this.overviewArray" :key="i">
                    <td>{{ u.cislo }}</td>
                    <td>{{ u.ucastnik.prijmeni + ' ' + u.ucastnik.jmeno }}</td>
                </tr>
            </table>
        </div>
    </div>
                
    <ConfirmBoxComponent v-if="showConfirmBox" :message="confirmMessage" :dialogType="confirmType" @close-event="closeConfirmBox" />
    <ConfirmTextBoxComponent v-if="showConfirmTextBox" :message="confirmMessage" :dialogType="confirmType" :inputText="confirmText" @close-event="closeConfirmTextBox" />
</template>

<script>
    import api from '@/api/api.js'
    import dayjs from 'dayjs'
    import utc from 'dayjs/plugin/utc'
    dayjs.extend(utc)
    
    import ConfirmBoxComponent from '../dialogs/ConfirmBoxComponent.vue'
    import ConfirmTextBoxComponent from '../dialogs/ConfirmTextBoxComponent.vue'
    import FilesComponent from '../files/FilesComponent.vue'

    export default {
        name: 'AttendanceReadonlyComponent',
        emits: ['close-card'],
        props: ['selectedAttendance', 'isAdmin'],
        components: {
            ConfirmBoxComponent,
            ConfirmTextBoxComponent,
            FilesComponent
        },
        data() {
            return {
                showDefault: true,
                showParticipants: false,
                showActivity: false,
                showAttendance: false,
                showAttachements: false,
                showParticipantsList: false,
                showActivityList: false,
                showOverview: false,
                pracovnici: [],
                attendanceColumns: [],
                attendance: {
                    id: -1,
                    nazev: '',
                    idSkola: -1,
                    idUcitel: -1,
                    upravil: '',
                    stav: 0,
                    data: {
                        nazev: '',
                        ucastnici: [],
                        aktivity: [],
                        dochazka: []
                    }
                },
                showConfirmBox: false,
                showConfirmTextBox: false,
                confirmMessage: "",
                confirmText: "",
                confirmType: -1
            }
        },
        computed: {
            overviewArray() {
                let arr = []
                for (let p = 0; p < this.attendance.data.dochazka.length; p++) {
                    for (let u = 0; u < this.attendance.data.dochazka[p].ucast.length; u++) {
                        if (this.attendance.data.dochazka[p].ucast[u].ucast)
                            arr.push({ cislo: u + 1, ucastnik: this.attendance.data.dochazka[p].ucastnik })
                    }
                }
                return arr
            },
            getFilePrefix() {
                let nazev = this.attendance.nazev.replace(/ /g, '_')
                nazev = nazev.length > 15 ? nazev.slice(0, 12) + "…" : nazev

                return '3_TK_' + nazev + '_'
            }
        },
        methods: {
            async load() {
                this.attendance.id = this.selectedAttendance

                try {
                    let response = await api.getAttendance(this.selectedAttendance)
                    if (response.status == 200)
                        this.attendance = response.data

                    response = await api.getTeacher(this.attendance.idSkola)
                    if (response.status == 200)
                        this.pracovnici = response.data
                }
                catch (error) {
                    console.log("Error: ", error)
                }

                this.generateAttendanceColumns()
            },
            formatDate(date) {
                return date !== null ? dayjs(date).utcOffset(0).format('D.M.YYYY') : ''
            },
            formatTime(time) {
                return time !== null ? time.hours + ':' + ('00' + time.minutes).slice(-2) : ''
            },
            formatText(text, trail) {
                return text + trail
            },
            getSchoolHours(a) {
                let hours = 0.0
                if (a.cas11 != null && a.cas12 != null) {
                    let minutes = a.cas12.hours * 60 + a.cas12.minutes - a.cas11.hours * 60 - a.cas11.minutes
                    hours += minutes / 45
                }
                if (a.cas21 != null && a.cas22 != null) {
                    let minutes = a.cas22.hours * 60 + a.cas22.minutes - a.cas21.hours * 60 - a.cas21.minutes
                    hours += minutes / 45
                }
                if (a.cas31 != null && a.cas32 != null) {
                    let minutes = a.cas32.hours * 60 + a.cas32.minutes - a.cas31.hours * 60 - a.cas31.minutes
                    hours += minutes / 45
                }
                return hours % 1 != 0 ? hours.toFixed(2) : hours
            },
            getTotalSchoolHours() {
                let hours = 0.0
                for (let d = 0; d < this.attendance.data.aktivity.length; d++) {
                    let a = this.attendance.data.aktivity[d]
                    if (a.cas11 != null && a.cas12 != null) {
                        let minutes = a.cas12.hours * 60 + a.cas12.minutes - a.cas11.hours * 60 - a.cas11.minutes
                        hours += minutes / 45
                    }
                    if (a.cas21 != null && a.cas22 != null) {
                        let minutes = a.cas22.hours * 60 + a.cas22.minutes - a.cas21.hours * 60 - a.cas21.minutes
                        hours += minutes / 45
                    }
                    if (a.cas31 != null && a.cas32 != null) {
                        let minutes = a.cas32.hours * 60 + a.cas32.minutes - a.cas31.hours * 60 - a.cas31.minutes
                        hours += minutes / 45
                    }
                }
                return hours % 1 != 0 ? hours.toFixed(2) : hours
            },
            getPracovnik(id) {
                if (this.pracovnici.length === 0)
                    return ''
                let p = this.pracovnici.filter(a => a.id == id).slice()[0]
                return p != null ? p.jmeno + ' ' + p.prijmeni : ''
            },
            close() {
                this.$emit('close-card')
            },
            async sendToApproveAdmin() {
                this.confirm(1, 'Šablona bude odeslána ke schválení administrátorovi. Pokračovat?')
            },
            async approve() {
                this.confirm(2, 'Šablona bude schválena a uzamčena. Pokračovat?')
            },
            async returnToTeacher() {
                this.callConfirmText(3, 'Šablona bude vrácena učiteli k doplnění. Pokračovat?', this.attendance.duvodVraceni)
            },
            async returnToCoordinator() {
                this.callConfirmText(4, 'Šablona bude vrácena koordinátorovi k doplnění. Pokračovat?', this.attendance.duvodVraceni)
            },
            generateAttendanceColumns() {
                this.attendanceColumns = []
                for (let a = 0; a < this.attendance.data.aktivity.length; a++) {
                    let ak = this.attendance.data.aktivity[a]
                    if (ak.datum !== null) {
                        let cas1 = ''
                        let cas2 = ''

                        if (ak.cas11 != null)
                            cas1 = ak.cas11.hours + ':' + ('00' + ak.cas11.minutes).slice(-2)
                        else if (ak.cas21 != null)
                            cas1 = ak.cas21.hours + ':' + ('00' + ak.cas21.minutes).slice(-2)
                        else if (ak.cas31 != null)
                            cas1 = ak.cas31.hours + ':' + ('00' + ak.cas31.minutes).slice(-2)
                            
                        if (ak.cas12 != null)
                            cas2 = ak.cas12.hours + ':' + ('00' + ak.cas12.minutes).slice(-2)
                        if (ak.cas22 != null)
                            cas2 = ak.cas22.hours + ':' + ('00' + ak.cas22.minutes).slice(-2)
                        if (ak.cas32 != null)
                            cas2 = ak.cas32.hours + ':' + ('00' + ak.cas32.minutes).slice(-2)
                        
                        this.attendanceColumns.push({
                            datum: this.formatDate(ak.datum),
                            cas1: cas1,
                            cas2: cas2
                        })
                    }
                }
            },
            changeTabPage(page) {
                this.showDefault = false
                this.showParticipants = false
                this.showActivity = false
                this.showAttendance = false
                this.showAttachements = false
                this.showParticipantsList = false
                this.showActivityList = false
                this.showOverview = false

                switch (page) {
                    case 1:
                        this.showParticipants = true
                        break
                    case 2:
                        this.showActivity = true
                        break
                    case 3:
                        this.showAttendance = true
                        break
                    case 4:
                        this.showAttachements = true
                        break
                    case 5:
                        this.showParticipantsList = true
                        break
                    case 6:
                        this.showActivityList = true
                        break
                    case 7:
                        this.showOverview = true
                        break
                    default:
                        this.showDefault = true
                        break
                }
            },
            confirm(type, message) {                
                this.confirmType = type
                this.confirmMessage = message
                this.showConfirmBox = true
            },
            callConfirmText(type, message, text) {                
                this.confirmType = type
                this.confirmMessage = message
                this.confirmText = text
                this.showConfirmTextBox = true
            },
            async closeConfirmBox(dialogResult, dialogType) {
                this.showConfirmBox = false
                this.confirmType = -1
                this.confirmMessage = ''

                if (dialogResult == 1) {
                    switch (dialogType) {
                        case 1:
                            await api.sendAttendanceToApproveByAdmin(this.attendance.id)
                            this.close()
                            break
                            
                        case 2:
                            await api.approveAttendance(this.attendance.id)
                            this.close()
                            break
                    }
                }
            },
            async closeConfirmTextBox(dialogResult, dialogType, text) {
                this.showConfirmTextBox = false
                this.confirmType = -1
                this.confirmMessage = ''
                this.confirmText = ''

                if (dialogResult == 1) {
                    switch (dialogType) {
                        case 3:
                            await api.returnAttendanceToTeacher(this.attendance.id, text)
                            this.close()
                            break

                        case 4:
                            await api.returnAttendanceToCoordinator(this.attendance.id, text)
                            this.close()
                            break
                    }
                }
            }
        },
        async created() {
            await this.load()
        }
    }
</script>