import axios from 'axios'

const HTTP = axios.create({
    baseURL: process.env.VUE_APP_API_URL
})

export default {
    ares(value) {
        // return axios.get(process.env.VUE_APP_ARES_URL + value)
        return HTTP.get('/data/ares/' + value)
    },

    login(value) {
        return HTTP.post('/uzivatel/login', value)
    },
    getSchoolsForUser(value) {
        return HTTP.get('/uzivatel/skola/' + value)
    },
    resetPasswordRequiere(value) {
        return HTTP.post('/uzivatel/resetzadost', "\"" + value + "\"", { headers: {"Content-Type": "application/json"} })
    },
    resetPasswordCheck(value) {
        return HTTP.post('/uzivatel/resetcheck', "\"" + value + "\"", { headers: {"Content-Type": "application/json"} })
    },
    resetPassword(value) {
        return HTTP.post('/uzivatel/reset', value)
    },
    changePassword(value) {
        return HTTP.post('/uzivatel/zmenahesla', value)
    },

    getAdmin() {
        return HTTP.get('/admin')
    },
    postAdmin(value) {
        return HTTP.post('/admin', value)
    },
    putAdmin(value) {
        return HTTP.put('/admin', value)
    },
    deleteAdmin(id) {
        return HTTP.delete('/admin/' + id)
    },

    getSchool() {
        return HTTP.get('/skola')
    },
    postSchool(value) {
        return HTTP.post('/skola', value)
    },
    putSchool(value) {
        return HTTP.put('/skola', value)
    },
    getTyp() {
        return HTTP.get('/skola/typ')
    },

    getTeacher(value) {
        return HTTP.get('/ucitel/' + value)
    },
    getTeacherByUserId(iduser, idschool) {
        return HTTP.get('/ucitel/uzivatel?iduzivatel=' + iduser + '&idskola=' + idschool)
    },
    postTeacher(value) {
        return HTTP.post('/ucitel', value)
    },
    putTeacher(value) {
        return HTTP.put('/ucitel', value)
    },

    getAttendanceBySchool(value, idteacher) {
        return HTTP.get('/ucast/skola/' + value + '?iducitel=' + idteacher)
    },
    getAttendance(value) {
        return HTTP.get('/ucast?id=' + value)
    },
    postAttendance(value) {
        return HTTP.post('/ucast', value)
    },
    putAttendance(value) {
        return HTTP.put('/ucast', value)
    },
    deleteAttendance(value) {
        return HTTP.delete('/ucast/' + value)
    },
    sendAttendanceToApproveByCoordinator(value) {
        return HTTP.post('/ucast/schvalit/koordinator', value, { headers: {"Content-Type": "application/json"} })
    },
    sendAttendanceToApproveByAdmin(value) {
        return HTTP.post('/ucast/schvalit/admin', value, { headers: {"Content-Type": "application/json"} })
    },
    approveAttendance(value) {
        return HTTP.post('/ucast/schvalit', value, { headers: {"Content-Type": "application/json"} })
    },
    returnAttendanceToTeacher(id, reason) {
        return HTTP.post('/ucast/vratit/koordinator', '{ "id": ' + id + ', "duvodVraceni": "' + reason + '" }', { headers: {"Content-Type": "application/json"} })
    },
    returnAttendanceToCoordinator(id, reason) {
        return HTTP.post('/ucast/vratit/admin', '{ "id": ' + id + ', "duvodVraceni": "' + reason + '" }', { headers: {"Content-Type": "application/json"} })
    },
    returnAttendance(id, reason) {
        return HTTP.post('/ucast/vratit', '{ "id": ' + id + ', "duvodVraceni": "' + reason + '" }', { headers: {"Content-Type": "application/json"} })
    },

    getSupportBySchool(value, idteacher) {
        return HTTP.get('/podpora/skola/' + value + '?iducitel=' + idteacher)
    },
    getSupport(value) {
        return HTTP.get('/podpora?id=' + value)
    },
    postSupport(value) {
        return HTTP.post('/podpora', value)
    },
    putSupport(value) {
        return HTTP.put('/podpora', value)
    },
    deleteSupport(value) {
        return HTTP.delete('/podpora/' + value)
    },
    sendSupportToApproveByCoordinator(value) {
        return HTTP.post('/podpora/schvalit/koordinator', value, { headers: {"Content-Type": "application/json"} })
    },
    sendSupportToApproveByAdmin(value) {
        return HTTP.post('/podpora/schvalit/admin', value, { headers: {"Content-Type": "application/json"} })
    },
    approveSupport(value) {
        return HTTP.post('/podpora/schvalit', value, { headers: {"Content-Type": "application/json"} })
    },
    returnSupportToTeacher(id, reason) {
        return HTTP.post('/podpora/vratit/koordinator', '{ "id": ' + id + ', "duvodVraceni": "' + reason + '" }', { headers: {"Content-Type": "application/json"} })
    },
    returnSupportToCoordinator(id, reason) {
        return HTTP.post('/podpora/vratit/admin', '{ "id": ' + id + ', "duvodVraceni": "' + reason + '" }', { headers: {"Content-Type": "application/json"} })
    },
    returnSupport(id, reason) {
        return HTTP.post('/podpora/vratit', '{ "id": ' + id + ', "duvodVraceni": "' + reason + '" }', { headers: {"Content-Type": "application/json"} })
    },

    getCooperationBySchool(value, idteacher) {
        return HTTP.get('/spoluprace/skola/' + value + '?iducitel=' + idteacher)
    },
    getCooperation(value) {
        return HTTP.get('/spoluprace?id=' + value)
    },
    postCooperation(value) {
        return HTTP.post('/spoluprace', value)
    },
    putCooperation(value) {
        return HTTP.put('/spoluprace', value)
    },
    deleteCooperation(value) {
        return HTTP.delete('/spoluprace/' + value)
    },
    sendCooperationToApproveByCoordinator(value) {
        return HTTP.post('/spoluprace/schvalit/koordinator', value, { headers: {"Content-Type": "application/json"} })
    },
    sendCooperationToApproveByAdmin(value) {
        return HTTP.post('/spoluprace/schvalit/admin', value, { headers: {"Content-Type": "application/json"} })
    },
    approveCooperation(value) {
        return HTTP.post('/spoluprace/schvalit', value, { headers: {"Content-Type": "application/json"} })
    },
    returnCooperationToTeacher(id, reason) {
        return HTTP.post('/spoluprace/vratit/koordinator', '{ "id": ' + id + ', "duvodVraceni": "' + reason + '" }', { headers: {"Content-Type": "application/json"} })
    },
    returnCooperationToCoordinator(id, reason) {
        return HTTP.post('/spoluprace/vratit/admin', '{ "id": ' + id + ', "duvodVraceni": "' + reason + '" }', { headers: {"Content-Type": "application/json"} })
    },
    returnCooperation(id, reason) {
        return HTTP.post('/spoluprace/vratit', '{ "id": ' + id + ', "duvodVraceni": "' + reason + '" }', { headers: {"Content-Type": "application/json"} })
    },
    generateCooperationDocument(id, rid) {
        return HTTP.post('/spoluprace/generovat/dokument', '{ "id": ' + id + ', "rid": ' + rid + ' }', { headers: {"Content-Type": "application/json"} })
    },

    uploadFiles(value, path, prefix) {
        return HTTP.post('/soubory/upload?cesta=' + path + '&prefix=' + prefix, value, { headers: {"Content-Type": "multipart/form-data" }})
    },
    uploadSingleFile(value, path, filename) {
        return HTTP.post('/soubory/uploadsingle?cesta=' + path + '&filename=' + filename, value, { headers: {"Content-Type": "multipart/form-data" }})
    },
    getFiles(path) {
        return HTTP.get('/soubory?cesta=' + path)
    },
    deleteFile(path) {
        return HTTP.delete('/soubory?soubor=' + encodeURIComponent(path))
    },
    getFolders(path) {
        return HTTP.get('/soubory/slozky?cesta=' + path)
    },

    getParticipants(value) {
        return HTTP.get('/ucastnik/' + value)
    },
    postParticipants(school, cards) {
        return HTTP.post('/ucastnik/' + school, cards)
    },
    generateCards(school, cards) {
        return HTTP.post('/ucastnik/generovat', '{ "idSkola": ' + school + ', "karty": ' + JSON.stringify(cards) + ' }', { headers: {"Content-Type": "application/json"} })
    },

    getConflicts() {
        return HTTP.get('/kritfce/soubehy')
    }
}