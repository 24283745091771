<template>
    <DropFileComponent :path="path" @upload-finished="uploadFinished" :prefix="prefix" />

    <div class="file-list">
        <div v-for="(f, i) in this.files" :key="i">
            <span v-if="f.slozka" class="folder-name" @click="gotoFolder(f.cesta)">{{ f.nazev }}</span>
            <span v-else style="display: inline-flex; align-items: start;"><span @click="deleteFile(f.cesta, f.nazev)" class="select-list-button-inverse">-</span><span v-html="getFile(f.cesta, f.nazev)"></span></span>
        </div>
    </div>

    <ConfirmBoxComponent v-if="showConfirmBox" :message="confirmMessage" :dialogType="confirmType" @close-event="closeConfirmBox" />
</template>

<script>
    import api from '@/api/api.js'
    import ConfirmBoxComponent from '../dialogs/ConfirmBoxComponent.vue'
    import DropFileComponent from '../files/DropFileComponent.vue'

    export default {
        name: 'FilesComponent',
        emits: [],
        props: ['path', 'prefix', 'showFolders'],
        components: {
            ConfirmBoxComponent,
            DropFileComponent
        },
        data() {
            return {
                currentPath: "",
                files: [],
                showConfirmBox: false,
                confirmMessage: "",
                confirmType: -1,
                deletePath: "",
                deleteName: "",
                parentFolders: []
            }
        },
        methods: {
            async load() {
                this.files = []

                try {
                    if (this.showFolders != undefined) {
                        if (this.parentFolders.length > 0)
                            this.files.push({ nazev: '..', cesta: '..', slozka: true })

                        let response = await api.getFolders(this.currentPath)
                        if (response.status == 200)
                            this.files.push(...response.data)
                    }

                    let response = await api.getFiles(this.currentPath)
                    if (response.status == 200)
                        this.files.push(...response.data)
                }
                catch (error) {
                    console.log("Error: ", error)
                }
            },
            gotoFolder(path) {
                if (path == '..') {
                    this.currentPath = this.parentFolders.pop()
                }
                else {
                    let p = path.split('\\')
                    p.pop()
                    this.parentFolders.push(p.join('\\'))
                    this.currentPath = path
                }

                this.load()
            },
            getFile(path, name) {
                return '<a class="file-name" target="_blank" href="' + process.env.VUE_APP_API_URL + '/soubory/download?soubor=' + path + '/' + encodeURIComponent(name) + '">' + name + '</a>'
            },
            deleteFile(path, name) {
                this.deleteName = name
                this.deletePath = path
                this.confirm(1, 'Chcete smazat přílohu ' + name + '?')
            },
            confirm(type, message) {
                this.confirmType = type
                this.confirmMessage = message
                this.showConfirmBox = true
            },
            async closeConfirmBox(dialogResult, dialogType) {
                this.showConfirmBox = false
                this.confirmType = -1

                if (dialogResult == 1) {
                    switch (dialogType) {
                        case 1:
                            await api.deleteFile(this.deletePath + '/' + this.deleteName)
                            this.deleteName = ""
                            this.deletePath = ""
                            this.load()
                            break
                    }
                }
            },
            uploadFinished() {
                this.load()
            }
        },
        created() {
            this.currentPath = this.path ?? ''
            this.load()
        }
    }
</script>