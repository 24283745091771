<template>
    <div class="message-box-wrapper">
        <div class="cols justify-center align-center fullscreen">
            <div class="rows gap-10 message-box">
                <div>{{ this.message }}</div>
                <textarea v-model="text"  style="width: 500px; height: 200px; resize: none;" />
                <div class="cols justify-center gap-10">
                    <button :class="'button-small' + (this.text.length < 5 ? ' button-disabled' : '')" @click="close(1)">Ano</button>
                    <button class="button-small" @click="close(0)">Ne</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ConfirmTextBoxComponent',
        emits: ['close-event'],
        props: ['message', 'dialogType', 'inputText'],
        data() {
            return {
                text: ''
            }
        },
        methods: {
            close(dialogResult) {
                if (dialogResult == 0 || this.text.length >= 5)
                    this.$emit('close-event', dialogResult, this.dialogType, this.text)
            }
        },
        created() {
            this.text = this.inputText
        }
    }
</script>