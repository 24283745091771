<template>
    <div class="cols">
        <div class="rows gap-30">
            <div class="cols" style="gap: 5px;">
                <button :class="'button-small' + (!this.showDefaultSettings ? ' button-inverse' : '')" @click="changeTabPage(0)">Základní údaje</button>
                <button :class="'button-small' + (!this.showPasswordSettings ? ' button-inverse' : '')" @click="changeTabPage(1)">Změna hesla</button>
            </div>

            <div v-if="showDefaultSettings" class="rows">
                <div v-if="isAdmin">
                    <h3>Přihlášen Administrátor. Své uživatelské údaje můžete změnit v administrátorské sekci.</h3>
                </div>
                <div v-else>
                    <table class="table-border-none">
                        <tr>
                            <td style="padding-left: 0;">Titul před:</td>
                            <td style="padding-right: 5px;"><input v-model="teacher.titulPred" style="width: 200px;" /></td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td style="padding-left: 0;">Jméno:</td>
                            <td style="padding-right: 5px;"><input v-model="teacher.jmeno" style="width: 200px;" /><span class="required">*</span></td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td style="padding-left: 0;">Příjmení:</td>
                            <td style="padding-right: 5px;"><input v-model="teacher.prijmeni" style="width: 200px;" /><span class="required">*</span></td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td style="padding-left: 0;">Titul za:</td>
                            <td style="padding-right: 5px;"><input v-model="teacher.titulZa" style="width: 200px;" /></td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td style="padding-left: 0;">E-mail:</td>
                            <td style="padding-right: 5px;"><input v-model="teacher.email" style="width: 200px;" /><span class="required">*</span></td>
                            <td style="padding-left: 0px;"><span style="font-size: small;">Změna e-mailu změní uživatelské jméno</span></td>
                        </tr>
                        <tr>
                            <td style="padding-left: 0;">Telefon:</td>
                            <td style="padding-right: 5px;"><input v-model="teacher.telefon" style="width: 200px;" /></td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td style="padding-left: 0;">Notifikace:</td>
                            <td style="padding-right: 5px;"><input type="checkbox" v-model="teacher.notifikace" /></td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td style="text-align: right;"><button class="button-small" @click="saveDefaultSettings()">Uložit</button></td>
                            <td>&nbsp;</td>
                        </tr>
                    </table>
                </div>
            </div>

            <div v-if="showPasswordSettings" class="rows align-center gap-10">
                <input type="password" v-model="passwordOld" @keyup.enter="changePassword()" placeholder="Zadejte staré heslo" style="width: 300px;" />
                <input type="password" v-model="password" @keyup.enter="changePassword()" placeholder="Zadejte nové heslo" style="width: 300px;" />
                <input type="password" v-model="passwordRepeat" @keyup.enter="changePassword()" placeholder="Zadejte nové heslo pro kontrolu" style="width: 300px;" />
                <button @click="changePassword()">Změnit</button>
                <div v-if="password !== passwordRepeat" class="color-red">Hesla nejsou shodná</div>
                <div v-if="showPasswordError" class="color-red">Chyba ověření. Heslo nebylo změněno.</div>
                <div v-if="showPasswordChange" class="color-red">Heslo bylo změněno.</div>
            </div>
        </div>
    </div>

    <MessageBoxComponent v-if="showMessageBox" :message="messageText" @close-event="closeMessageBox" />
</template>

<script>
    import api from '@/api/api.js'

    import MessageBoxComponent from '../dialogs/MessageBoxComponent.vue'

    export default {
        name: 'SettingsComponent',
        emits: ['show-saving-message'],
        props: ['email', 'isAdmin', 'idUser', 'idSchool'],
        components: {
            MessageBoxComponent
        },
        data() {
            return {
                teacher: {},
                passwordOld: '',
                password: '',
                passwordRepeat: '',
                showPasswordError: false,
                showPasswordChange: false,
                showDefaultSettings: true,
                showPasswordSettings: false,
                showMessageBox: false,
                messageText: ""
            }
        },
        methods: {
            showSavingDataMessage(value) {
                this.$emit('show-saving-message', value)
            },
            async saveDefaultSettings() {
                if (this.teacher.jmeno.length > 0 && this.teacher.prijmeni.length > 0 && this.teacher.email.length > 0) {
                    try {
                        this.showSavingDataMessage(true)
                        await api.putTeacher(this.teacher)
                        this.showSavingDataMessage(false)
                    }
                    catch (error) {
                        this.showSavingDataMessage(false)
                        console.log('Error: ', error)
                        this.message("Nastala chyba při ukládání dat")
                    }
                }
                else{
                    alert('Vyplňte všechny povinné položky!')
                }
            },
            async changePassword() {
                if (this.password.length > 0 && this.password === this.passwordRepeat) {
                    try {
                        await api.changePassword({ email: this.email, stareheslo: this.passwordOld, heslo: this.password })
                        this.showPasswordError = false
                        this.showPasswordChange = true
                    }
                    catch (error) {
                        if (error.response.status != 401)
                            console.log('Error: ', error)
                        this.showPasswordChange = false
                        this.showPasswordError = true
                    }
                }
            },
            changeTabPage(page) {
                this.showDefaultSettings = false
                this.showPasswordSettings = false

                switch (page) {
                    case 1:
                        this.showPasswordSettings = true;
                        break;
                    default:
                        this.showDefaultSettings = true;
                        break;
                }
            },
            message(message) {
                this.messageText = message
                this.showMessageBox = true
            },
            async closeMessageBox() {
                this.showMessageBox = false
            }
        },
        async created() {
            try {
                let response = await api.getTeacherByUserId(this.idUser, this.idSchool)
                this.teacher = response.data
            }
            catch(error) {
                console.log('Error: ', error)
            }
        }
    }
</script>