<template>
    <div>
        <FilesComponent path="" prefix="" :show-folders="true" />
    </div>
</template>

<script>
    import FilesComponent from '../files/FilesComponent.vue'

    export default {
        name: 'DocumentsAdminComponent',
        props: [],
        components: {
            FilesComponent
        },
        data() {
            return {
            }
        },
        methods: {
        }
    }
</script>